import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TitleType } from '@/app/topcars-ui/title/types/title';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';

@Component({
  selector: 'app-tc-header-section',
  templateUrl: './tc-header.component.html',
  styleUrls: ['./tc-header.component.scss'],
})
export class TcHeaderComponent implements OnChanges {
  @Input() params = {};
  @Input() title = true;
  @Input() subtitle = true;
  @Input() ctaLabel = true;
  @Input() ctaAction?: () => void;

  TypesTitles = TitleType;
  data!: ScreenTitleConfig;
  isMobile = false;

  constructor(public screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params']) {
      this.data = changes['params'].currentValue;
    }
  }

  onClick() {
    if (this.ctaAction) {
      this.ctaAction();
    }
  }
}
