import { ModalService } from '@/app/libraries/main-layout/services/modal.service';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { INavigationService, TOOLBAR_HISTORY } from './toolbar.interface';

@Injectable({
  providedIn: 'root',
})
export class ToolbarNavigationService implements INavigationService {
  private history: string[] = [];
  private isNavigatingBack = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {
    const savedHistory = sessionStorage.getItem(TOOLBAR_HISTORY);
    if (savedHistory) {
      this.history = JSON.parse(savedHistory);
    }

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        if (!this.isNavigatingBack) {
          this.addToHistory(event.urlAfterRedirects);
        }
      });
  }

  public addToHistory(url: string): void {
    if (
      this.history.length === 0 ||
      this.history[this.history.length - 1] !== url
    ) {
      this.history.push(url);
      sessionStorage.setItem(TOOLBAR_HISTORY, JSON.stringify(this.history));
    }
  }

  public back(): void {
    if (this.history.length > 1) {
      this.isNavigatingBack = true;
      const previousUrl = this.history[this.history.length - 1];
      this.history.pop();

      if (previousUrl) {
        this.router.navigateByUrl(previousUrl);
      } else {
        window.history.back();
      }

      sessionStorage.setItem(TOOLBAR_HISTORY, JSON.stringify(this.history));
    } else {
      window.history.back();
    }
  }

  public getCurrentRoute(): string {
    return this.router.url;
  }

  public clearHistory(): void {
    this.history = [];
    sessionStorage.removeItem(TOOLBAR_HISTORY);
  }

  public navigateForward(
    route: string[],
    queryParams: any,
    relativeTo: ActivatedRoute | null = null,
    skipLocationChange = false,
  ) {
    this.router.navigate(route, {
      queryParams: queryParams,
      relativeTo: relativeTo,
      skipLocationChange: skipLocationChange,
    });
  }

  public navigateForwardModal(path: string, queryParams: any) {
    this.modalService.openModal(path, queryParams);
  }

  public navigateToNewTab(route: string[]) {
    const url = this.router.createUrlTree(route).toString();
    window.open(url, '_blank');
  }
}
