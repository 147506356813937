import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../libraries/core/navigation/modal.service';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { TranslateModule } from '@ngx-translate/core';
import { EditPictureComponent } from '../ui/modals/edit-picture/edit-picture.component';
import { PermissionsComponent } from '../ui/modals/permissions/permissions.component';
import { DialogModule } from 'primeng/dialog';
import { TitleComponent } from '@/app/topcars-ui/title/title.component';

@NgModule({
  declarations: [ProfileComponent, EditPictureComponent, PermissionsComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    DialogModule,
    TranslateModule,
    TitleComponent,
  ],
})
export class ProfileModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('profile', () =>
      import('./profile.component').then((m) => m.ProfileComponent),
    );
  }
}
