import { Component, OnDestroy, OnInit } from '@angular/core';
import { SduiSection } from '../events-all/pages/general-events/sdui/sdui-section.interface';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import {
  CITY_ID,
  CityDetailScreenName,
  CityDetailScreenTitleConfig,
} from './city-detail.config';
import { forkJoin, Subscription } from 'rxjs';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { PATH_CITIES, PATH_CITIES_DETAIL } from '../constants';
import { ActivatedRoute } from '@angular/router';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.scss'],
})
export class CityDetailComponent implements OnInit, OnDestroy {
  isLoading = true;
  isMobile = false;
  eventId!: string;
  data!: ScreenTitleConfig;
  sections: SduiSection[] = [];
  private languageSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
    public toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setCityDetailScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  ngOnInit() {
    this.getData();

    this.languageSubscription = this.authService.languageChanged$.subscribe(
      () => {
        this.getData();
      },
    );
  }

  getData() {
    this.eventId = this.route.snapshot.paramMap.get(CITY_ID) ?? 'null';
    const path = PATH_CITIES + `${this.eventId}` + PATH_CITIES_DETAIL;
    forkJoin({
      sdui: this.httpMethodsService.getData(path, {}),
    }).subscribe({
      next: (response: any) => {
        this.sections = response?.sdui?.body.sections;
        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  setCityDetailScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: CityDetailScreenName,
      dataText: CityDetailScreenTitleConfig,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.Narrow,
    });
  }
}
