<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="
    modalClosing && isMobile
      ? 'countries-selector-modal closing'
      : 'countries-selector-modal'
  "
>
  <div id="modal-content-countries" class="modal-content" pb-24>
    <div class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
      <h1 m-0 w-100 fz-16 fw-500 text-center TextStyle>
        {{ translate.countrySelector | translate }}
      </h1>
    </div>
    <app-country-selector
      [data]="params"
      (countrySelected)="countrySelected($event)"
    ></app-country-selector>
  </div>
</p-dialog>
