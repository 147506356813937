import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsComponent } from './events.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { CityDetailComponent } from './city-detail/city-detail.component';

export const EVENTS_ROUTES = {
  Events: 'events',
  EventsDetail: ':eventId',
  CitiesDetail: '/city/',
};

const routes: Routes = [
  {
    path: '',
    component: EventsComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./events-all/events-all.module').then(
            (m) => m.EventsAllModule,
          ),
      },
      {
        path: ':eventId',
        component: EventDetailComponent,
      },
      {
        path: 'city/:cityId',
        component: CityDetailComponent,
      },
      {
        path: '',
        redirectTo: 'all',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventsRoutingModule {}
