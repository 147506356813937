import { Injectable, Type } from '@angular/core';
import { EventsSectionComponent } from '@/app/features/events/sections/events-section/events-section.component';
import { CitiesSectionComponent } from '@/app/features/events/sections/cities-section/cities-section.component';

@Injectable({
  providedIn: 'root',
})
export class ComponentRegistryService {
  private registry = new Map<string, Type<any>>();

  constructor() {
    this.register('EVENTS_CITIES', CitiesSectionComponent);
    this.register('EVENTS_SECTION', EventsSectionComponent);
  }

  register(type: string, component: Type<any>): void {
    this.registry.set(type, component);
  }

  get(type: string): Type<any> | undefined {
    return this.registry.get(type);
  }
}
