import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { tcEventsUpcomingParams } from '@/app/libraries/interfaces/data.interface';
import { Component, OnInit } from '@angular/core';
import { EventsUpcomingScreenName } from './events-upcoming.config';

@Component({
  selector: 'app-events-upcoming',
  templateUrl: './events-upcoming.component.html',
  styleUrls: ['./events-upcoming.component.scss'],
})
export class EventsUpcomingComponent implements OnInit {
  isLoading = true;

  paramsEventsUpcoming: tcEventsUpcomingParams = {
    events: [
      {
        image: 'path/to/event1-image.jpg',
        dateTime: 'TODAY • 23:00',
        eventTitle: 'The Yorkville Exotic Car Show',
        eventLocation: 'Los Angeles, CA',
        eventInfo: 'The Car Culture',
      },
      {
        image: 'path/to/event2-image.jpg',
        dateTime: 'TODAY • 19:30',
        eventTitle: 'Monterey Car Week',
        eventLocation: 'Monterey, CA',
        eventInfo: 'Luxury Cars',
      },
    ],
    headerData: {
      feature: 'g-events',
      title: 'all.locationCity',
      subtitle: '',
      CTA: 'all.viewAll',
    },
  };

  isMobile = false;
  data!: ScreenTitleConfig;

  constructor(
    private screenSizeService: ScreenSizeService,
    private screenConfigService: ScreenConfigService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setUpcomingScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  ngOnInit(): void {
    // Simulate loading
    setTimeout(() => {
      this.isLoading = false;
    }, 1200);
  }

  setUpcomingScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: EventsUpcomingScreenName,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.Wrap,
    });
  }
}
