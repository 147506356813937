import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';

export const LOGIN_ROUTES = {
  LOGIN: 'auth/sign-in',
  VERIFICATION: 'verification',
};

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('../verification/verification.module').then(
        (m) => m.VerificationModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
