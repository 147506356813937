<!-- <app-toolbar
  w-100
  pt-12
  *ngIf="isMobile"
  [toolbarConfig]="{
    state: true,
    close: true,
  }"
></app-toolbar> -->
<div class="drop-user">
  <h1 m-0 fz-16 pb-28 tcMilk fw-700 text-center *ngIf="isMobile" class="title">
    {{ translate.settings | translate }}
  </h1>

  <div class="cont-user" *ngIf="userData?.profile_picture">
    <div class="user-image" pb-12>
      <app-tc-icon
        class="icon-profile"
        [iconId]="userData?.profile_picture ?? 'system_avatar_default'"
      ></app-tc-icon>
    </div>
    <h3 m-0 pb-4 tcMilk fz-16 fw-700 *ngIf="userData?.username">
      {{ userData?.username }}
    </h3>
    <p m-0 pb-16 tcGhost fz-12 fw-500 *ngIf="userData?.full_name">
      {{ userData?.full_name }}
    </p>
  </div>

  <div w-100 d-flex flex-column pb-28 *ngIf="isMobile">
    <h1 m-0 tcMilk fz-12 fw-700 pb-12 style="text-transform: uppercase">
      {{ translate.general | translate }}
    </h1>
    <button (click)="langModal()">
      <app-tc-icon
        [iconId]="'system_globe_translate'"
        style="padding-right: var(--size-16)"
      ></app-tc-icon>
      <p w-100 m-0>{{ translate.language | translate }}</p>
      <app-tc-icon [iconId]="'system_chevron_right'"></app-tc-icon>
    </button>
  </div>

  <div
    w-100
    d-flex
    flex-column
    [ngStyle]="{
      'padding-bottom': isMobile ? 'var(--size-28)' : 'var(--size-16)',
    }"
  >
    <h1
      m-0
      tcMilk
      fz-12
      fw-700
      pb-12
      *ngIf="isMobile"
      style="text-transform: uppercase"
    >
      {{ translate.legal | translate }}
    </h1>
    <button
      mb-8
      (click)="navigationService.navigateToUrl(LinkRedirects.TERMS_CONDITIONS)"
    >
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_terms'"
        style="padding-right: var(--size-16)"
      ></app-tc-icon>
      <p w-100 m-0>{{ translate.termsConditions | translate }}</p>
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_chevron_right'"
      ></app-tc-icon>
    </button>
    <button
      (click)="navigationService.navigateToUrl(LinkRedirects.PRIVACY_POLICY)"
    >
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_terms'"
        style="padding-right: var(--size-16)"
      ></app-tc-icon>
      <p w-100 m-0>{{ translate.privacyPolicy | translate }}</p>
      <app-tc-icon
        *ngIf="isMobile"
        [iconId]="'system_chevron_right'"
      ></app-tc-icon>
    </button>
  </div>

  <ng-container>
    <button *ngIf="!isMobile" (click)="logOut()">
      <p w-100 m-0 tcError fw-500>{{ translate.logout | translate }}</p>
    </button>
    <app-tcbutton
      *ngIf="isMobile"
      [type]="TypesButton.TERTIARY"
      (click)="logOut()"
    >
      <p m-0 fz-14 fw-500>{{ translate.logout | translate }}</p>
    </app-tcbutton>
  </ng-container>
</div>
