import { ComponentRef, Injectable } from '@angular/core';
import {
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
} from '@angular/core';
import { ErrorModalComponent } from '../error-modal.component';
import { textI18n } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ErrorModalService {
  private modalRef!: ComponentRef<ErrorModalComponent> | null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) {}

  showError(message: textI18n): Promise<boolean> {
    if (this.modalRef) {
      this.modalRef.instance.errorMessage = message;
      this.modalRef.instance.display = true;
      return Promise.resolve(true);
    }

    return new Promise<boolean>((resolve) => {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ErrorModalComponent,
        );
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.errorMessage = message;
      componentRef.instance.display = true;

      componentRef.instance.Close.subscribe((result: boolean) => {
        resolve(result);
        this.cleanup(componentRef);
      });

      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      this.modalRef = componentRef;
    });
  }

  private cleanup(componentRef: ComponentRef<ErrorModalComponent>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
    this.modalRef = null;
  }
}
