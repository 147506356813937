import { ICON_CATALOG } from '@/app/libraries/icon/icon-catalog';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-events-section',
  templateUrl: './events-section.component.html',
  styleUrls: ['./events-section.component.scss'],
})
export class EventsSectionComponent implements OnChanges {
  @Input() params = {};
  system_cover = '';
  data: any;

  constructor() {
    this.system_cover = ICON_CATALOG['system_cover'] || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params']) {
      this.data = changes['params'].currentValue;
    }
  }

  onCtaClick() {
    console.log('CTA clicked from parent!');
  }
}
