<div class="header">
  <app-tc-icon
    cursor-pointer
    class="header-logo"
    [iconId]="'system_TopCarsLogo'"
    (click)="goHome()"
  ></app-tc-icon>

  <div class="content-header">
    <ng-container>
      <app-tctext
        tcMilk
        TextStyle-B
        feature="main-layout"
        key="header.redirectEvents"
        *ngIf="!isMobile"
        (click)="goHome()"
      ></app-tctext>
    </ng-container>

    <div class="language-selector" (click)="toggleDropdown()">
      <div>
        <app-tc-icon [iconId]="'system_globe_translate'"></app-tc-icon>
      </div>
      <h1 tcMilk fz-16 fw-700 m-0 *ngIf="!isMobile">{{ language }}</h1>
      <app-language-dropdown
        *ngIf="isDropdownOpen"
        class="dropdown"
        [lang]="language"
      ></app-language-dropdown>
    </div>

    <ng-container>
      <app-tcbutton
        *ngIf="!isLoggedIn"
        [type]="TypesButton.small"
        [disabled]="false"
        (click)="signIn()"
      >
        <app-tctext feature="main-layout" key="header.signIn"></app-tctext>
      </app-tcbutton>
    </ng-container>

    <ng-container *ngIf="isLoggedIn">
      <app-tc-identity
        [userData]="dataUser"
        [isMobile]="isMobile"
      ></app-tc-identity>
    </ng-container>
  </div>
</div>
