import { inject, Injectable } from '@angular/core';
import {
  CreateHotToastRef,
  HotToastService,
  ToastOptions,
} from '@ngxpert/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ToastComponent } from '@/app/topcars-ui/toasts/toast/toast.component';
import { TranslationKeys } from '@/app/libraries/core/translations/types/translations';

const enum ToastType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  SHOW = 'show',
}

interface ToastOptionsCustom<T> extends ToastOptions<T> {
  interpolateParams?: Record<string, unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private hotToastService = inject(HotToastService);
  private translateService = inject(TranslateService);

  private translateMessage(
    message: string | undefined,
    interpolateParams?: Record<string, unknown> | undefined,
  ): Observable<string> {
    return this.translateService.get(message as string, interpolateParams);
  }

  private createToast<T>(
    message: string,
    options?: ToastOptionsCustom<T>,
    type: (typeof ToastType)[keyof typeof ToastType] = ToastType.SHOW,
  ) {
    const { interpolateParams, ...restOptions } = options || {};
    this.translateMessage(message, interpolateParams).subscribe(
      (translatedMessage) => {
        (
          this.hotToastService[type] as (
            content: typeof ToastComponent,
            options: ToastOptions<unknown>,
          ) => CreateHotToastRef<unknown>
        )(ToastComponent, {
          data: {
            message: translatedMessage,
            type,
          },
          ...restOptions,
        });
      },
    );
  }

  show<T>(message: TranslationKeys, options?: ToastOptionsCustom<T>) {
    this.createToast(message, options, ToastType.SHOW);
  }

  success<T>(message: TranslationKeys, options?: ToastOptionsCustom<T>) {
    this.createToast(message, options, ToastType.SUCCESS);
  }

  error<T>(message: TranslationKeys, options?: ToastOptionsCustom<T>) {
    this.createToast(message, options, ToastType.ERROR);
  }

  warning<T>(message: TranslationKeys, options?: ToastOptionsCustom<T>) {
    this.createToast(message, options, ToastType.WARNING);
  }
}
