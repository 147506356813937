<p
  *ngIf="titleInput.length > 0"
  tcConcrete
  fz-12
  fw-500
  m-0
  mb-4
  TextStyle
  [ngStyle]="{ color: !isValid ? 'var(--error)' : '' }"
>
  {{ titleInput }}
</p>
<div
  class="input-container"
  [ngClass]="{
    disabled: disabled,
    'is-invalid': !isValid,
    'is-valid': isComplete && styleClass === 'outline',
    outline: styleClass === 'outline',
  }"
>
  <ng-container *ngIf="icon">
    <div
      class="icon-cont"
      [appSvgIcon]="iconPath"
      [strokeColor]="
        isValid && !isComplete
          ? 'var(--ghost)'
          : isComplete
            ? 'var(--milk)'
            : 'var(--error)'
      "
      [fillColor]="
        icon.includes('user') && isValid && !isComplete
          ? 'var(--ghost)'
          : icon.includes('user') && isComplete && value.length > 0
            ? 'var(--milk)'
            : !icon.includes('user')
              ? 'none'
              : 'var(--error)'
      "
    ></div>
  </ng-container>

  <input
    [ngClass]="{
      tcGhost: value === '',
      tcMilk: value !== '' && isValid,
      err: !isValid,
    }"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [type]="type"
    [(ngModel)]="value"
    [mask]="mask"
    [prefix]="prefix"
    [maxLength]="maxlength"
    (input)="onInputChange($event)"
    [attr.autocomplete]="autocomplete ? autocomplete : null"
  />

  <ng-container *ngIf="styleClass !== 'outline' && value?.length">
    <app-tc-icon
      class="system_cross"
      [iconId]="'system_cross'"
      (click)="cleanControl()"
    ></app-tc-icon>
  </ng-container>

  <app-tc-icon
    class="icon-validate"
    [iconId]="'system_times_error'"
    *ngIf="styleClass === 'outline' && !isValid"
  ></app-tc-icon>

  <app-tc-icon
    class="icon-validate"
    [iconId]="'system_check_complete'"
    *ngIf="styleClass === 'outline' && isComplete"
  ></app-tc-icon>
</div>

<div [ngClass]="{ view: !isValid }" class="error-message">
  <p m-0 TextStyle [innerHTML]="errorMessage"></p>
</div>
