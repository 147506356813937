import { Component, Input, OnInit } from '@angular/core';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';

@Component({
  selector: 'app-tc-event-hero-card',
  templateUrl: './tc-event-hero-card.component.html',
  styleUrls: ['./tc-event-hero-card.component.scss'],
})
export class TcEventHeroCardComponent implements OnInit {
  @Input() dateTime!: string;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;
  @Input() eventInfo!: string;
  @Input() image!: string;
  @Input() id!: string;
  @Input() upcoming = false;

  day = '';
  month = '';

  constructor(private toolbarNavigationService: ToolbarNavigationService) {}

  ngOnInit(): void {
    const parts = this.dateTime.split(' ');

    this.month = parts[0].replace('.', '');
    this.day = parts[1].replace(',', '');
  }

  eventDetail() {
    this.toolbarNavigationService.navigateToNewTab([
      `${EVENTS_ROUTES.EVENTS}/${this.id}`,
    ]);
  }
}
