import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const EventsDetailScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-events',
  title: 'events.title',
  CTA: 'events.CTA',
  details: 'events.details',
  peopleGoing: 'events.peopleGoing',
  location: 'events.location',
  message: 'events.message',
  continue: 'events.confirmInterest',
  responseError: 'events.responseError',
};

export const EventsDetailScreenName = 'eventsdetail';

export interface EventResponse {
  id: string;
  organizer: Organizer;
  address: Address;
  interested: boolean;
  interested_count: number;
  faces: string[];
  date_created: string;
  date_modified: string;
  external_organizer: string;
  name: string;
  description: string;
  url: string;
  initial_date: string;
  final_date: string;
  thumbnail: string;
  is_active: boolean;
  polygon_city: string;
}

export interface Organizer {
  id: string;
  username: string;
  profile_picture: string;
  full_name: string;
  is_verified: boolean;
}

export interface Address {
  id: string;
  latitude: number;
  longitude: number;
  country: string;
  date_created: string;
  date_modified: string;
  formatted_address: string;
  street: string | null;
  number: string | null;
  place_id: string | null;
  apartment: string | null;
  neighborhood: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  building: string | null;
  instructions: string;
  point: string;
  static_image: string;
  dirty_address: string | null;
}
