import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]',
})
export class NumbersOnlyDirective {
  private regex = new RegExp(/^[0-9]+$/);

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    // Prevent leading spaces
    if (
      event.target &&
      (event.target as HTMLInputElement).value.length === 0 &&
      inputChar === ' '
    ) {
      event.preventDefault();
    }

    // Prevent any non-number character
    if (!this.regex.test(inputChar)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text') || '';

    // Extract only numbers from pasted text
    const numbersOnly = pastedInput.replace(/\D/g, '');

    if (numbersOnly) {
      event.preventDefault();
      const inputElement = event.target as HTMLInputElement;

      // Insert the filtered numbers into the input
      const currentValue = inputElement.value;
      const cursorPos = inputElement.selectionStart || 0;

      inputElement.value =
        currentValue.substring(0, cursorPos) +
        numbersOnly +
        currentValue.substring(cursorPos);

      // Move the cursor after pasted letters
      inputElement.setSelectionRange(
        cursorPos + numbersOnly.length,
        cursorPos + numbersOnly.length,
      );
    } else {
      event.preventDefault();
    }
  }
}
