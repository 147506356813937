import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { STORAGE_KEY } from '@/app/libraries/core/http-methods/constants';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  public defaultLang = '';

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    this.detectLanguage();
  }

  setLanguage(language: string) {
    this.defaultLang = language;
    this.translate.use(language);
  }

  detectLanguage(): void {
    this.defaultLang = this.getPreferredLanguage();
    this.translate.setDefaultLang(this.defaultLang);
    this.translate.use(this.defaultLang);
    this.authService.setLanguage(this.defaultLang);
  }

  private getPreferredLanguage(): string {
    const lang = this.authService.getItemStorage(STORAGE_KEY);
    if (lang) return lang;

    const browserLang = this.translate?.getBrowserLang();
    if (browserLang?.match(/en|es/)) return browserLang;

    return 'en';
  }

  highlightLinks(text: string): string {
    const linkPattern = /(https?:\/\/[^\s]+)/g;
    let highlightedText = text.replace(
      linkPattern,
      '<a href="$1" target="_blank">$1</a>',
    );
    highlightedText = highlightedText.replace(/\n/g, '<br>');
    return highlightedText;
  }
}
