<div class="card-container">
  <div class="image-section">
    <img class="event-image" [src]="image" alt="Exotic Car Show" />
  </div>
  <div class="details-section">
    <div class="date-time" fz-12 fw-700 [ngClass]="{ concrete: upcoming }">
      <span>{{ dateTime }}</span>
    </div>
    <h2 class="event-title" fz-14 fw-700 m-0>{{ eventTitle }}</h2>
    <div class="event-location" pb-4 fz-12>
      <p m-0>{{ eventLocation }}</p>
    </div>
    <div class="event-info" m-0 py-4 fz-10>
      <p m-0>{{ eventInfo }}</p>
    </div>
  </div>

  <div *ngIf="upcoming" class="date">
    <h4 fz-22 m-0>{{ day }}</h4>
    <h4 fz-18 m-0>{{ month }}</h4>
  </div>
  <app-tc-icon
    *ngIf="upcoming"
    class="icon"
    [iconId]="'system_redirect_detail'"
    (click)="eventDetail()"
  ></app-tc-icon>
</div>
