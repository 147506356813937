export const PATH_SIGN_EMAIL = '/users/login/email/';
export const PATH_SIGN_PHONE = '/users/login/phone/';
export const PATH_SIGN_EMAIL_VERIFICATION = '/users/login/email/verify/';
export const PATH_SIGN_PHONE_VERIFICATION = '/users/login/phone/verify/';

export interface responseData {
  created: boolean;
  token: string;
  user: userData;
}

export interface userData {
  id: string;
  email?: string;
  username?: string;
  profile_picture?: string;
  full_name?: string;
  birthdate?: string;
  is_registered?: boolean;
  is_verified?: boolean;
}
