import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  QueryParam,
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import {
  TcCitiesParams,
  TcEventsParams,
} from '@/app/libraries/events/data.interface';
import {
  PATH_EVENTS_SDUI,
  PATH_EVENTS_UPCOMING,
} from '@/app/libraries/events/constants';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import { SelectLocationComponent } from '../../../ui/modals/select-location/select-location.component';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { UserData } from '@/app/features/authentication/types';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import {
  GeneralEventsScreenName,
  GeneralEventsScreenTitleConfig,
} from './general-events.config';
import { GeneralEventsResponse } from './types';
import { AddEmailScreenName } from '@/app/features/user-profile/add-email/add-email.config';
import { ProfileScreenName } from '@/app/features/user-profile/profile/profile.config';
import { UsernameScreenName } from '@/app/features/user-profile/username/username.config';
import { SduiSection } from '@/app/libraries/events/sdui/sdui-section.interface';
import { LinkRedirects } from '@/app/features/layout/ui/footer/constants';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-general-events',
  templateUrl: './general-events.component.html',
  styleUrls: ['./general-events.component.scss'],
})
export class GeneralEventsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  paramsCities!: TcCitiesParams;
  paramsEvents!: TcEventsParams;
  isLoading = true;
  @ViewChild(SelectLocationComponent) selectLocation!: SelectLocationComponent;
  isMobile = false;
  data!: ScreenTitleConfig;
  flow = '';
  dataUser!: UserData;
  sections: SduiSection[] = [];
  upcoming: SduiSection[] = [];
  isLoggedIn = false;
  private languageSubscription!: Subscription;
  LinkRedirects!: LinkRedirects;
  firstRequest = true;
  translate = TRANSLATIONS.events;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setEventsScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.flow = params['flow'] ? window.atob(params['flow']) : '';
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });

    const dataUser = this.authService.getItemStorage('dataUser');
    this.dataUser = dataUser ? JSON.parse(dataUser) : {};
  }

  ngOnInit() {
    setTimeout(() => {
      this.getData();
      this.toolbarNavigationService.addToHistory(this.router.url, true);
      this.languageSubscription = this.authService.languageChanged$.subscribe(
        () => {
          if (!this.firstRequest) {
            this.getData();
          }
        },
      );
    });
  }

  getData() {
    let requests: {
      sdui: Observable<unknown>;
      upcoming?: Observable<unknown>;
    } = {
      sdui: this.httpMethodsService.getData(PATH_EVENTS_SDUI, {}),
    };

    if (this.isLoggedIn) {
      requests = {
        ...requests,
        upcoming: this.httpMethodsService.getData(PATH_EVENTS_UPCOMING, {}),
      };
    }

    forkJoin(requests).subscribe({
      next: (response) => {
        const Response: GeneralEventsResponse =
          response as GeneralEventsResponse;
        this.sections = Response?.sdui?.body.sections;
        this.upcoming = Response?.upcoming?.body.sections;
        this.firstRequest = false;
        this.isLoading = false;
      },
      error: async () => {
        const isResult = await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });

        if (isResult) {
          window.open(LinkRedirects.TOP_CARS, '_self');
        }
      },
    });
  }

  ngAfterViewInit(): void {
    if (!this.dataUser.is_registered) {
      const queryParams = {
        flow: window.btoa(this.flow),
      };

      const hasNonNumericCharacters = /\D/.test(this.dataUser.username ?? '');
      const needsUsername =
        this.dataUser.username?.length === 0 || !hasNonNumericCharacters;
      const needsProfile =
        (!this.dataUser.full_name && this.dataUser.full_name?.length === 0) ||
        !this.dataUser.birthdate ||
        this.dataUser.birthdate?.length === 0;
      const needsEmail =
        !this.dataUser.email || this.dataUser.email?.length === 0;

      if (this.flow === QueryParam.FLOW_EMAIL) {
        if (needsUsername) {
          this.toolbarNavigationService.navigateForwardModal(
            UsernameScreenName,
            queryParams,
          );
        } else if (needsProfile) {
          this.toolbarNavigationService.navigateForwardModal(
            ProfileScreenName,
            queryParams,
          );
        }
      } else if (this.flow === QueryParam.FLOW_PHONE) {
        if (needsEmail) {
          this.toolbarNavigationService.navigateForwardModal(
            AddEmailScreenName,
            queryParams,
          );
        } else if (needsUsername) {
          this.toolbarNavigationService.navigateForwardModal(
            UsernameScreenName,
            queryParams,
          );
        } else if (needsProfile) {
          this.toolbarNavigationService.navigateForwardModal(
            ProfileScreenName,
            queryParams,
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  openLocationModal() {
    this.selectLocation.openModal();
  }

  handleconfirmlocationModal(resp: boolean) {
    console.log(resp);
  }

  setEventsScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: GeneralEventsScreenName,
      dataText: GeneralEventsScreenTitleConfig,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.FULLSCREEN,
    });
  }
}
