export const ICON_CATALOG: { [key: string]: string } = {
  system_globe_translate: 'assets/icons/ic_system_globe_translate.svg',
  system_x_social_media: 'assets/icons/ic_system_x_social_media.svg',
  system_youtube: 'assets/icons/ic_system_youtube.svg',
  system_facebook: 'assets/icons/ic_system_facebook.svg',
  system_instagram: 'assets/icons/ic_system_instagram.svg',
  system_googleplay: 'assets/icons/ic_system_googleplay.svg',
  system_settings: 'assets/icons/ic_system_settings.svg',
  system_avatar_default: 'assets/icons/ic_system_avatar_default.svg',

  system_email: 'assets/icons/ic_system_email.svg',
  system_apple: 'assets/icons/ic_system_apple.svg',
  system_google: 'assets/icons/ic_system_google.svg',
  system_cross: 'assets/icons/ic_system_cross_circle.svg',

  system_edit: 'assets/icons/ic_system_edit.svg',
  system_user: 'assets/icons/ic_system_user.svg',
  system_phone: 'assets/icons/ic_system_phone.svg',
  system_image_default: 'assets/icons/ic_system_image_default.svg',

  system_terms: 'assets/icons/ic_system_terms.svg',
  system_plus: 'assets/icons/ic_system_plus.svg',
  system_avatar: 'assets/icons/ic_system_avatar.svg',
  system_verified: 'assets/icons/ic_system_verified.svg',
  system_ubication: 'assets/icons/ic_system_ubication.svg',
  system_pin_events: 'assets/icons/ic_system_pin_events.svg',
  system_pin_default: 'assets/icons/ic_system_pin_default.svg',
  system_top_cars_logo: 'assets/icons/ic_system_TopCarsLogo.svg',
  system_share_light: 'assets/icons/ic_system_share_light.svg',
  system_plus_circle: 'assets/icons/ic_system_plus_circle.svg',
  system_cross_image: 'assets/icons/ic_system_cross_image.svg',
  system_chevron_right: 'assets/icons/ic_system_chevron_right.svg',
  system_calendar_dates: 'assets/icons/ic_system_calendar_dates.svg',
  system_cross_image_alt: 'assets/icons/ic_system_cross_image_alt.svg',
  system_calendar_events: 'assets/icons/ic_system_calendar_events.svg',
  system_check_subscribe: 'assets/icons/ic_system_check_subscribe.svg',
  system_subscribe_moweb: 'assets/icons/ic_system_subscribe_moweb.svg',
  system_subscribed_moweb: 'assets/icons/ic_system_subscribed_moweb.svg',

  system_camera: 'assets/icons/ic_system_camera.svg',
  system_trash: 'assets/icons/ic_system_trash_alt.svg',
  system_close_modal: 'assets/icons/ic_system_close_modal.svg',
  system_upload_image: 'assets/icons/ic_system_upload_image.svg',

  system_pin: 'assets/icons/ic_system_pin.svg',
  system_link: 'assets/icons/ic_system_link.svg',
  system_times: 'assets/icons/ic_system_times.svg',
  system_users: 'assets/icons/ic_system_users.svg',
  system_search: 'assets/icons/ic_system_search.svg',
  check_selected: 'assets/icons/ic_check_selected.svg',
  system_compass: 'assets/icons/ic_system_compass.svg',
  system_arrow_back: 'assets/icons/ic_system_arrowBack.svg',
  system_grid_stack: 'assets/icons/ic_system_grid_stack.svg',
  system_link_ghost: 'assets/icons/ic_system_link_ghost.svg',
  system_times_error: 'assets/icons/ic_system_times_error.svg',
  system_alert_circle: 'assets/icons/ic_system_alert_circle.svg',
  system_chevron_down: 'assets/icons/ic_system_chevron_down.svg',
  system_calendar_event: 'assets/icons/ic_system_calendar_event.svg',
  system_check_complete: 'assets/icons/ic_system_check_complete.svg',
  system_redirect_detail: 'assets/icons/ic_system_redirect_detail.svg',
  system_chevron_down_alt: 'assets/icons/ic_system_chevron_down_alt.svg',
  system_country_selector: 'assets/icons/ic_system_country_selector.svg',
  system_notification: 'assets/icons/ic_system_notification_indicator.svg',

  // images
  system_cover: 'assets/images/im_system_Cover.svg',
  system_map_image: 'assets/images/im_system_map_image.svg',
  system_location: 'assets/images/im_system_location.svg',
  system_no_location: 'assets/images/im_system_noLocation.svg',
  system_event_upcoming: 'assets/images/im_system_event_upcoming.svg',
  system_explore_events: 'assets/images/im_system_explore_events.svg',
};
