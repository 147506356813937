import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CountriesSelector } from './types';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';
import { DataCountries, Country } from '../../../login/types';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent implements OnChanges {
  isMobile = true;
  params!: Country[];
  searchQuery = '';
  placeholder = '';
  countrySelect!: CountriesSelector;
  @Input() data!: DataCountries;
  @Output() countrySelected = new EventEmitter<CountriesSelector>();
  translate = TRANSLATIONS.authentication.phone;

  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.params = this.data?.countries;
      this.placeholder = this.data?.placeholder;
      this.params = this.params?.map((country: Country) => ({
        ...country,
        flagUrl: `https://flagcdn.com/${country.code.toLowerCase()}.svg`,
      }));
    }
  }

  get filteredParams() {
    return this.params
      ? this.params.filter((country: Country) =>
          country.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
      : [];
  }

  onCountryClick(country: CountriesSelector) {
    this.countrySelect = country;
    this.countrySelected.emit(country);
    setTimeout(() => {
      this.searchQuery = '';
    }, 1000);
  }

  selectFirstOption(): void {
    if (this.filteredParams.length > 0) {
      const firstCountry = this.filteredParams[0];
      this.onCountryClick(firstCountry);
    }
  }
}
