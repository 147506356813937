import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AUTH_TOKEN_KEY,
  DATA_USER_KEY,
  REFRESH_TOKEN_KEY,
  STORAGE_KEY,
} from '../constants';
import { userData } from '@/app/features/authentication/constants';
import { AppConstants } from '@/app/libraries/constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = AppConstants.API_URL;
  private readonly storageKey = STORAGE_KEY;

  private isLoggedInSubject = new BehaviorSubject<boolean>(
    !!this.getItemStorage(AUTH_TOKEN_KEY),
  );
  isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();

  private dataUserSubject = new BehaviorSubject<userData | null>(
    this.getDataUser(),
  );
  dataUser$: Observable<userData | null> = this.dataUserSubject.asObservable();

  private languageChangeSubject = new Subject<string>();
  languageChanged$ = this.languageChangeSubject.asObservable();

  constructor(private http: HttpClient) {}

  getItemStorage(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  setItemStorage(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  clearStorage(): void {
    sessionStorage.clear();
  }

  updateAuthStatus(isLoggedIn: boolean): void {
    this.isLoggedInSubject.next(isLoggedIn);
  }

  login(token: string): void {
    sessionStorage.setItem(AUTH_TOKEN_KEY, token);
    this.updateAuthStatus(true);
  }

  setDataUser(data: userData): void {
    this.setItemStorage(DATA_USER_KEY, JSON.stringify(data));
    this.dataUserSubject.next(data);
  }

  getDataUser(): userData | null {
    const data = sessionStorage.getItem(DATA_USER_KEY);
    return data ? JSON.parse(data) : null;
  }

  updateDataUser(partialData: Partial<userData>): void {
    const currentData = this.getDataUser();
    const updatedData = { ...currentData, ...partialData };
    this.setDataUser(updatedData as userData);
  }

  getAuthToken(): string | null {
    return this.getItemStorage(AUTH_TOKEN_KEY);
  }

  setAuthToken(token: string): void {
    this.setItemStorage(AUTH_TOKEN_KEY, token);
  }

  setLanguage(language: string): void {
    sessionStorage.setItem(this.storageKey, language);
    this.languageChangeSubject.next(language);
  }

  getLanguage(): string {
    return sessionStorage.getItem(this.storageKey) || 'en';
  }

  getRefreshToken(): string | null {
    return this.getItemStorage(REFRESH_TOKEN_KEY);
  }

  refreshToken(): Observable<string> {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      return throwError(() => new Error('No refresh token available'));
    }

    return this.http
      .post<{ accessToken: string }>(`${this.apiUrl}/auth/refresh-token`, {
        token: refreshToken,
      })
      .pipe(
        map((response) => response.accessToken),
        catchError((error: HttpErrorResponse) =>
          throwError(() => new Error(error.message)),
        ),
      );
  }
}
