<div class="city-card">
  <div class="city-image">
    <!-- <app-tc-icon [iconId]="imageUrl"></app-tc-icon> -->
    <img [src]="imageUrl" alt="city-image" />
  </div>
  <div class="city-name">
    {{ cityName }}
  </div>
  <div *ngIf="!isMobile" fz-14 tcConcrete TextStyle-L>
    {{ events + " events" }}
  </div>
</div>
