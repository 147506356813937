import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { userData } from '@/app/features/authentication/constants';

@Component({
  selector: 'app-tc-identity',
  templateUrl: './tc-identity.component.html',
  styleUrls: ['./tc-identity.component.scss'],
})
export class TcIdentityComponent implements OnChanges {
  @Input() userData: userData | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userData']) {
      this.userData = changes['userData'].currentValue;
    }
  }
}
