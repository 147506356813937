import { Component, ViewChild } from '@angular/core';
import { ModalExternalUrlComponent } from '../../modals/modal-external-url/modal-external-url.component';
import {
  LinkNames,
  LinkRedirects,
  LinkTexts,
  SocialNetworkIcons,
  SocialNetworkNames,
  SocialNetworkRedirect,
} from './constants';
import { NavigationService } from '@/app/libraries/main-layout/services/navigation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @ViewChild(ModalExternalUrlComponent)
  modalExternalUrl!: ModalExternalUrlComponent;
  links = [
    {
      name: LinkNames.TopCars,
      text: LinkTexts.TopCars,
      link: LinkRedirects.TopCars,
    },
    {
      name: LinkNames.TermsConditions,
      text: LinkTexts.TermsConditions,
      link: LinkRedirects.TermsConditions,
    },
    {
      name: LinkNames.PrivacyPolicy,
      text: LinkTexts.PrivacyPolicy,
      link: LinkRedirects.PrivacyPolicy,
    },
    /* {
      name: LinkNames,
      text: LinkTexts,
    }, */
  ];
  socialNetworks = [
    {
      name: SocialNetworkNames.X,
      icon: SocialNetworkIcons.X,
      link: SocialNetworkRedirect.X,
    },
    /* {
      name: SocialNetworkNames.YouTube,
      icon: SocialNetworkIcons.YouTube,
      link: SocialNetworkRedirect.YouTube
    }, */
    {
      name: SocialNetworkNames.Facebook,
      icon: SocialNetworkIcons.Facebook,
      link: SocialNetworkRedirect.Facebook,
    },
    {
      name: SocialNetworkNames.Instagram,
      icon: SocialNetworkIcons.Instagram,
      link: SocialNetworkRedirect.Instagram,
    },
    {
      name: SocialNetworkNames.GooglePlay,
      icon: SocialNetworkIcons.GooglePlay,
      link: SocialNetworkRedirect.GooglePlay,
    },
  ];

  constructor(public navigationService: NavigationService) {}

  /*  openUrlExternalModal() {
    this.modalExternalUrl.openModal();
  } */
}
