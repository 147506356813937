<div class="footer">
  <div class="footer-links">
    <ng-container class="options" *ngFor="let link of links">
      <app-tctext
        fz-14
        fw-400
        tcConcrete
        TextStyle-M
        feature="main-layout"
        [key]="link.text"
        (click)="navigationService.navigateToUrl(link.link)"
      ></app-tctext>
    </ng-container>
  </div>
  <div class="social-networks">
    <ng-container class="options" *ngFor="let network of socialNetworks">
      <app-tc-icon
        [iconId]="network.icon"
        (click)="navigationService.navigateToUrl(network.link)"
      ></app-tc-icon>
    </ng-container>
  </div>
</div>

<!-- <app-modal-external-url #modalExternalUrl> </app-modal-external-url> -->
