import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TRANSLATION_PLUGIN } from '@/app/libraries/core/translations/translation-config';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ModalExternalUrlComponent } from './modal-external-url/modal-external-url.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';

@NgModule({
  declarations: [ErrorModalComponent, ModalExternalUrlComponent],
  imports: [
    CommonModule,
    LibrariesModule,
    DesignSystemModule,
    DialogModule,
    TranslateModule,
  ],
  exports: [ErrorModalComponent, ModalExternalUrlComponent],
  providers: [
    {
      provide: TRANSLATION_PLUGIN,
      useValue: [
        {
          module: 'modals',
        },
      ],
      multi: true,
    },
  ],
})
export class ModalsModule {}
