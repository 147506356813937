import { Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentRegistryService {
  private registry = new Map<string, Type<any>>();

  register(type: string, component: Type<any>): void {
    this.registry.set(type, component);
  }

  get(type: string): Type<any> | undefined {
    return this.registry.get(type);
  }
}
