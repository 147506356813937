import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddEmailRoutingModule } from './add-email-routing.module';
import { AddEmailComponent } from './add-email.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../libraries/main-layout/services/modal.service';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';

@NgModule({
  declarations: [AddEmailComponent],
  imports: [
    CommonModule,
    AddEmailRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
  ],
})
export class AddEmailModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('addemail', () =>
      import('./add-email.component').then((m) => m.AddEmailComponent),
    );
  }
}
