import {
  Directive,
  ViewContainerRef,
  Input,
  ComponentFactoryResolver,
  OnInit,
  Type,
} from '@angular/core';

@Directive({
  selector: '[appDynamicComponent]',
})
export class DynamicComponentDirective implements OnInit {
  @Input() componentType!: Type<unknown>;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    if (!this.componentType) {
      console.error('No component type provided for DynamicComponentDirective');
      return;
    }
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(this.componentType);
    this.viewContainerRef.createComponent(componentFactory);
  }
}
