import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ALL_EVENTS_ROUTES } from './events-all-routing.module';
import { EVENTS_UPCOMING } from '../constants';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';

@Component({
  selector: 'app-events-all',
  templateUrl: './events-all.component.html',
  styleUrls: ['./events-all.component.scss'],
})
export class EventsAllComponent implements OnInit {
  routeOption = false;
  isMobile = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      if (!isMobile) {
        this.routeGeneral();
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      const currentRoute = this.route.snapshot.firstChild?.routeConfig?.path;
      this.routeOption = currentRoute === EVENTS_UPCOMING;
    });
  }

  routeGeneral() {
    this.routeOption = false;
    this.router.navigate([ALL_EVENTS_ROUTES.GENERAL_EVENTS]);
  }
  routeUpcoming() {
    this.routeOption = true;
    this.router.navigate([ALL_EVENTS_ROUTES.EVENTS_UPCOMING]);
  }
}
