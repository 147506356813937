<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="
    modalClosing ? 'image-upload-modal closing' : 'image-upload-modal'
  "
>
  <div class="modal-content" pb-24>
    <div px-24 pt-24 pb-24 class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
    </div>
    <button class="modal-option" (click)="onTakePhoto()">
      <app-tc-icon [iconId]="'system_camera'"></app-tc-icon>
      <app-tctext feature="g-user" key="profile.takePhoto"></app-tctext>
    </button>
    <button class="modal-option" (click)="onImportFromGallery()">
      <app-tc-icon [iconId]="'system_upload_image'"></app-tc-icon>
      <app-tctext feature="g-user" key="profile.import"></app-tctext>
    </button>
    <button
      class="modal-option remove"
      (click)="onRemoveCurrentPicture()"
      *ngIf="imageUploaded"
    >
      <app-tc-icon [iconId]="'system_trash'"></app-tc-icon>
      <app-tctext feature="g-user" key="profile.remove"></app-tctext>
    </button>
  </div>
</p-dialog>
