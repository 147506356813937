import { APP_INITIALIZER } from '@angular/core';
import { Observable } from 'rxjs';
import { IconRegistryService } from './icon-registry.service';

export function preloadIcons(
  iconRegistry: IconRegistryService,
): () => Observable<void[]> {
  return () =>
    iconRegistry.preloadIcons([
      {
        name: 'check-circle-2',
        content: /*html*/ `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.45833 14C1.45833 7.07344 7.07343 1.45834 14 1.45834C20.9266 1.45834 26.5417 7.07344 26.5417 14C26.5417 20.9266 20.9266 26.5417 14 26.5417C7.07343 26.5417 1.45833 20.9266 1.45833 14ZM19.197 11.0303C19.4899 10.7374 19.4899 10.2626 19.197 9.96968C18.9041 9.67679 18.4292 9.67679 18.1363 9.96968L12.8333 15.2727L11.0303 13.4697C10.7374 13.1768 10.2626 13.1768 9.96967 13.4697C9.67678 13.7626 9.67678 14.2374 9.96967 14.5303L12.303 16.8637C12.4437 17.0043 12.6344 17.0833 12.8333 17.0833C13.0322 17.0833 13.223 17.0043 13.3637 16.8637L19.197 11.0303Z" fill="currentColor"/>
        </svg>`,
      },
      {
        name: 'cross-circle',
        content: /*html*/ `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.45833 14C1.45833 7.07344 7.07343 1.45834 14 1.45834C20.9266 1.45834 26.5417 7.07344 26.5417 14C26.5417 20.9266 20.9266 26.5417 14 26.5417C7.07343 26.5417 1.45833 20.9266 1.45833 14ZM19.197 11.0303C19.4899 10.7374 19.4899 10.2626 19.197 9.96968C18.9041 9.67679 18.4292 9.67679 18.1363 9.96968L12.8333 15.2727L11.0303 13.4697C10.7374 13.1768 10.2626 13.1768 9.96967 13.4697C9.67678 13.7626 9.67678 14.2374 9.96967 14.5303L12.303 16.8637C12.4437 17.0043 12.6344 17.0833 12.8333 17.0833C13.0322 17.0833 13.223 17.0043 13.3637 16.8637L19.197 11.0303Z" fill="currentColor"/>
        </svg>
        `,
      },
      {
        name: 'alert-triangle',
        content: /*html*/ `<svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0574 1.38532C18.5579 2.06284 19.6985 4.09971 21.9798 8.17345L26.157 15.6327C28.3499 19.5486 29.4464 21.5065 29.263 23.1098C29.1029 24.5086 28.3606 25.7751 27.2183 26.5982C25.9091 27.5416 23.665 27.5416 19.177 27.5416H10.8226C6.33451 27.5416 4.09047 27.5416 2.78121 26.5982C1.63894 25.7751 0.896613 24.5086 0.736603 23.1098C0.5532 21.5065 1.64964 19.5486 3.84253 15.6327L8.01973 8.17345C10.301 4.09971 11.4417 2.06284 12.9422 1.38532C14.2503 0.794655 15.7492 0.794655 17.0574 1.38532ZM15.7497 11.4999C15.7497 11.0857 15.414 10.7499 14.9997 10.7499C14.5855 10.7499 14.2497 11.0857 14.2497 11.4999V16.1666C14.2497 16.5808 14.5855 16.9166 14.9997 16.9166C15.414 16.9166 15.7497 16.5808 15.7497 16.1666V11.4999ZM14.9997 18.9166C14.2634 18.9166 13.6664 19.5135 13.6664 20.2499C13.6664 20.9863 14.2634 21.5832 14.9997 21.5832C15.7361 21.5832 16.3331 20.9863 16.3331 20.2499C16.3331 19.5135 15.7361 18.9166 14.9997 18.9166Z" fill="currentColor"/>
        </svg>
        `,
      },
    ]);
}

export const provideIcons = () => ({
  provide: APP_INITIALIZER,
  useFactory: preloadIcons,
  deps: [IconRegistryService],
  multi: true,
});
