import { ActivatedRoute } from '@angular/router';

export interface Navigation {
  back(): void;
  clearHistory(): void;
  getCurrentRoute(): void;
  navigateForward(
    route: string[],
    queryParams: object,
    relativeTo: ActivatedRoute | null,
  ): void;
  navigateForwardModal(path: string, queryParams: object): void;
}

export const HISTORY_NAME_KEY = 'toolbarHistory';
export const EN = 'en';
export const ES = 'es';
export const US = 'us';
export const CO = 'co';
