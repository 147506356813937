import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  private basePath = 'assets/i18n/';
  private defaultLang = '';

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    this.detectLanguage();
  }

  setLanguage(language: string) {
    this.defaultLang = language;
    this.translate.use(language);
    this.loadTranslations('');
  }

  loadTranslations(feature: string): Observable<object> {
    const filePath = `${this.basePath}${feature}/${this.defaultLang}.json`;
    return this.http.get(filePath).pipe(
      map((translations) => {
        this.translate.setTranslation(this.defaultLang, translations, true);
        return translations;
      }),
    );
  }

  getPlaceholder(_feature: string, _key: string): Promise<string> {
    const feature = _feature;
    const key = _key;

    return new Promise((resolve, reject) => {
      this.loadTranslations(feature).subscribe({
        next: () => {
          this.translate.get(key).subscribe({
            next: (rawText: string) => {
              resolve(rawText);
            },
          });
        },
        error: (err) => reject(err),
      });
    });
  }

  detectLanguage() {
    // Detect the browser language
    const browserLang = this.translate?.getBrowserLang();

    // Set default language if supported, otherwise use 'en'
    this.defaultLang = browserLang?.match(/en|es/) ? browserLang : 'en';
    this.translate.setDefaultLang(this.defaultLang);
    this.translate.use(this.defaultLang);
  }

  highlightLinks(text: string): string {
    const linkPattern = /(https?:\/\/[^\s]+)/g;
    let highlightedText = text.replace(
      linkPattern,
      '<a href="$1" target="_blank">$1</a>',
    );
    highlightedText = highlightedText.replace(/\n/g, '<br>');
    return highlightedText;
  }
}
