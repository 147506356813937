import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-tc-background',
  templateUrl: './tc-background.component.html',
  styleUrls: ['./tc-background.component.scss'],
})
export class TcBackgroundComponent {
  data: any;
  TypesButton = TypesButton;
  isMobile = false;

  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }
}
