import { Component, Inject, Optional } from '@angular/core';
import { HotToastRef } from '@ngxpert/hot-toast';
import { IconsModule } from '../../icons/icons.module';

@Component({
  selector: 'tc-toast',
  standalone: true,
  imports: [IconsModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<{
      message: string;
      type: 'show' | 'success' | 'error' | 'warning';
    }>,
  ) {}
}
