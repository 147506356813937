import {
  PATH_SIGN_EMAIL,
  PATH_SIGN_PHONE,
} from '@/app/features/authentication/constants';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription, take } from 'rxjs';
import { ErrorModalService } from '../../modals/error-modal/services/error-modal.service';
import { dataOTP } from './constants';
import { QueryParam } from '@/app/libraries/main-layout/constants/screen-configuration';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent implements OnInit, OnChanges, OnDestroy {
  otpForm: FormGroup;
  countdownTime: Date = new Date(0, 0, 0, 0, 1, 0); // 1 minute
  countdownInterval = 1000;
  countdownCounter = 60;
  @Input() data!: dataOTP;
  @Input() isDisabled = false;
  @Input() cleanOTP = false;
  @Output() otpComplete: EventEmitter<string> = new EventEmitter<string>();
  private countdownSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
  ) {
    this.otpForm = this.fb.group({
      digit0: ['', [Validators.required, Validators.maxLength(1)]],
      digit1: ['', [Validators.required, Validators.maxLength(1)]],
      digit2: ['', [Validators.required, Validators.maxLength(1)]],
      digit3: ['', [Validators.required, Validators.maxLength(1)]],
      digit4: ['', [Validators.required, Validators.maxLength(1)]],
      digit5: ['', [Validators.required, Validators.maxLength(1)]],
    });
  }

  get otpControls() {
    return Object.values(this.otpForm.controls);
  }

  ngOnInit() {
    this.startCountdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled']) {
      const currentValue = changes['isDisabled'].currentValue;
      if (currentValue) {
        this.disableInputs();
      }
    }
    if (changes['cleanOTP']) {
      const currentValue = changes['cleanOTP'].currentValue;
      if (currentValue) {
        this.reSendCode();
      }
    }
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  onKeyUp(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;
    if (input.value && index < 5) {
      const nextInput = input.nextElementSibling as HTMLInputElement;
      nextInput?.focus();
    }
    this.checkOtpCompletion();
  }

  startCountdown() {
    this.countdownSubscription = interval(this.countdownInterval)
      .pipe(take(this.countdownCounter))
      .subscribe(() => {
        this.countdownTime = new Date(
          this.countdownTime.getTime() - this.countdownInterval,
        );
      });
  }

  reSendCode() {
    this.countdownSubscription.unsubscribe();
    this.otpForm.patchValue({
      digit0: '',
      digit1: '',
      digit2: '',
      digit3: '',
      digit4: '',
      digit5: '',
    });
    this.otpForm.markAsPristine();
    this.otpForm.markAsUntouched();
    this.otpForm.updateValueAndValidity();
    this.countdownTime = new Date(0, 0, 0, 0, 1, 0);
    this.startCountdown();

    let params;
    if (this.data.flow === QueryParam.flowEmail) {
      params = {
        email: this.data.email,
      };
    } else {
      params = {
        phone: this.data.phone,
      };
    }
    this.httpServicesPost(params);
  }
  httpServicesPost(params: object) {
    const path =
      this.data.flow === QueryParam.flowEmail
        ? PATH_SIGN_EMAIL
        : PATH_SIGN_PHONE;
    this.httpMethodsService.postData(path, params).subscribe({
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.data?.feature,
          key: this.data.data?.responseError,
        });
      },
    });
  }

  disableInputs() {
    this.otpForm.patchValue({
      digit0: '-',
      digit1: '-',
      digit2: '-',
      digit3: '-',
      digit4: '-',
      digit5: '-',
    });
    this.otpForm.disable();
  }

  checkOtpCompletion() {
    const otpValue = Object.values(this.otpForm.value).join('');
    this.otpComplete.emit(otpValue);
  }
}
