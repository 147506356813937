import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TextI18n } from './interfaces';
import { TypesButton } from '../../../design-system/atoms/button/constants';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Input() display = false;
  @Input() errorMessage!: TextI18n;
  @Input() title = '';
  TypesButton = TypesButton;
  @Output() Close: EventEmitter<boolean> = new EventEmitter<boolean>();
  translate = TRANSLATIONS.modals.modalError;

  constructor(private cdr: ChangeDetectorRef) {}

  getTranslationKey(key: string): string {
    return this.translate[key as keyof typeof this.translate];
  }

  hide() {
    this.display = false;
    this.Close.emit(false);
  }

  confirm() {
    this.display = false;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.Close.emit(true);
    }, 300);
  }
}
