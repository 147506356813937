<div style="display: flex; flex-direction: column; padding: 0 var(--size-24)">
  <app-loading-skeleton
    [type]="'form'"
    [count]="1"
    style="width: 10%; padding: var(--size-20) 0"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    [type]="'label'"
    [count]="2"
    style="padding: var(--size-24) 0"
  ></app-loading-skeleton>

  <ng-container *ngIf="profile">
    <app-loading-skeleton
      w-100
      pb-28
      [type]="'circle'"
      [count]="1"
      style="display: flex; justify-content: center; align-items: center"
    ></app-loading-skeleton>
    <app-loading-skeleton
      w-100
      [type]="'form-label'"
      [count]="2"
      style="padding: var(--size-24) 0 var(--size-104) 0"
    ></app-loading-skeleton>
  </ng-container>

  <ng-container *ngIf="!profile">
    <app-loading-skeleton
      w-100
      [type]="'form'"
      [count]="1"
      style="padding: var(--size-24) 0 var(--size-104) 0"
    ></app-loading-skeleton>
  </ng-container>

  <app-loading-skeleton
    [type]="'form'"
    [count]="1"
    style="
      width: calc(100% - var(--size-48));
      padding: var(--size-24) 0;
      position: absolute;
      bottom: 0;
    "
  ></app-loading-skeleton>
</div>
