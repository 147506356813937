<ng-container>
  <!-- <app-tc-header-section
    w-100
    [title]="true"
    [subtitle]="false"
    [ctaLabel]="true"
    [ctaAction]="onCtaClick"
    [params]="data.headerData"
  >
  </app-tc-header-section> -->

  <div pb-28 w-100>
    <app-tc-event-card
      *ngFor="let event of data.events"
      w-100
      [imageUrl]="event.imageUrl"
      [eventTime]="event.caption"
      [eventTitle]="event.title"
      [eventLocation]="event.subtitle"
      [eventCategory]="event.eventCategory"
      [participants]="event.participants"
    ></app-tc-event-card>
  </div>
</ng-container>
