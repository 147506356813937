<div class="content-pages">
  <app-loading-skeleton
    w-100
    [type]="'card-v'"
    [count]="3"
    *ngIf="isLoading"
    style="padding: var(--size-24) 0"
  ></app-loading-skeleton>
  <div class="content-cards" *ngIf="!isLoading">
    <app-events-hero-carousel-section
      w-100
      [params]="paramsEventsUpcoming"
    ></app-events-hero-carousel-section>

    <app-tc-event-hero-card
      dateTime="TODAY • 23:00"
      eventTitle="The Yorkville Exotic Car Show"
      eventLocation="Los Angeles, CA"
      eventInfo="The Car Culture • 1,768 participants"
    ></app-tc-event-hero-card>

    <app-tc-card-explore-events
      participants="1,200"
      dateTime="MAY 5TH • 15:00"
      eventTitle="The Yorkville Exotic Car Show"
      eventLocation="Los Angeles, CA"
    ></app-tc-card-explore-events>
  </div>
</div>
