import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TypesTitles } from './constants';

@Component({
  selector: 'app-tc-title',
  templateUrl: './tc-title.component.html',
  styleUrls: ['./tc-title.component.scss'],
})
export class TcTitleComponent implements OnInit, OnChanges {
  @Input() type = TypesTitles.general;
  @Input() desktopMode = false;
  @Input() feature = '';
  @Input() key = '';
  @Input() title = '';
  typeClass = '';

  ngOnInit(): void {
    this.setTypeClass(this.type, this.desktopMode);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['desktopMode']) {
      this.desktopMode = changes['desktopMode'].currentValue;
      this.setTypeClass(this.type, this.desktopMode);
    }
  }

  setTypeClass(type: TypesTitles, desktopMode: boolean) {
    this.typeClass = type;
    if (desktopMode) {
      if (type === TypesTitles.section) {
        this.typeClass += ' ' + TypesTitles.desktopMode;
      } else if (type === TypesTitles.subtitleSection) {
        this.typeClass += ' ' + TypesTitles.desktopModeSub;
      }
    }
  }
}
