export enum TypesButton {
  SMALL = 'small',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TERTIARY_WHITE = 'tertiaryWhite',
  TERTIARY_ASH = 'tertiaryAsh',
  ROUND = 'round',
  ROUND_SMOKE = 'roundSmoke',
}
