import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ScreenConfiguration,
  ScreenPresentation,
} from '../constants/screen-configuration';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreenConfigService {
  private configSubject = new BehaviorSubject<ScreenConfiguration | null>(null);
  config$: Observable<ScreenConfiguration | null>;
  private baseConfig!: ScreenConfiguration;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.config$ = this.configSubject
      .asObservable()
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      );

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result) => {
        if (this.baseConfig) {
          this.applyResponsiveConfig(result.matches);
        }
      });
  }

  setScreenConfiguration(config: ScreenConfiguration) {
    setTimeout(() => {
      if (config.presentation === ScreenPresentation.Modal) {
        config.presentation =
          this.configSubject.value?.presentation ||
          this.baseConfig?.presentation;
      }
      this.baseConfig = config;
      this.configSubject.next(this.baseConfig);

      this.breakpointObserver
        .observe([Breakpoints.XSmall])
        .subscribe((result) => {
          this.applyResponsiveConfig(result.matches);
        });
    }, 0);
  }

  private applyResponsiveConfig(isSmallScreen: boolean) {
    setTimeout(() => {
      const adjustedConfig = { ...this.baseConfig };

      if (isSmallScreen) {
        adjustedConfig.useToolbar.closeAll = true;
        adjustedConfig.useToolbar.close = false;
        adjustedConfig.useToolbar.pop = false;
        adjustedConfig.useFooter = false;
      }
      this.configSubject.next(adjustedConfig);
    }, 0);
  }
}
