<app-tctext
  class="redirect"
  TextStyle-B
  fz-12
  fw-400
  m-0
  [feature]="feature"
  [key]="key"
  [ngClass]="{ desktopMode: desktopMode }"
></app-tctext>
