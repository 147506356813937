import { ValidatorService } from '@/app/libraries/interfaces/validator-interface/services/validator.service';
import { TextService } from '@/app/design-system/atoms/text/services/text.service';
import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenConfigService } from '../../../libraries/main-layout/services/screen-config.service';
import { USER_ROUTES } from '@/app/features/user-profile/user-profile-routing.module';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { ModalService } from '../../../libraries/main-layout/services/modal.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  ScreenPresentation,
  ScreenTitleConfig,
  toolbarOptions,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { userData } from '@/app/features/authentication/constants';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';
import { TypesTitles } from '@/app/design-system/atoms/title/constants';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import {
  AddEmailScreenName,
  AddEmailScreenTitleConfig,
} from './add-email.config';
import { UsernameScreenName } from '../username/username.config';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';
import { PATH_USER } from '../constants';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss'],
})
export class AddEmailComponent implements OnInit {
  placeHolder = '';
  form!: FormGroup;
  isEmailInvalid = true;
  flow = '';
  toolbarActions!: toolbarOptions;
  isMobile = false;
  userConflict = false;
  isLoading = true;
  params: any;
  TypesTitles = TypesTitles;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  dataUser: userData | null = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private textService: TextService,
    private authService: AuthService,
    private modalService: ModalService,
    private validatorService: ValidatorService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.setAddEmailScreenConfig();
    setTimeout(() => {
      this.screenConfigService.config$.subscribe((config) => {
        if (config) {
          this.data = { ...config?.dataText };
          this.toolbarActions = { ...config.useToolbar };
          this.cdr.detectChanges();
        }
      });
    }, 0);
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.form = this.fb.group({
      inputEmail: [null, [Validators.required, this.getValidator('email')]],
    });
    this.form.get('inputEmail')?.statusChanges.subscribe((status) => {
      this.isEmailInvalid = status === 'INVALID';
    });
    this.dataUser = this.authService.getDataUser();
  }

  get inputEmail(): AbstractControl | null {
    return this.form.get('inputEmail');
  }

  ngOnInit(): void {
    if (this.isMobile) {
      this.route.queryParams.subscribe((params) => {
        this.flow = params['flow'] ? window.atob(params['flow']) : '';
      });
    } else {
      this.params = this.config?.data?.params;
      this.flow = this.params['flow'] ? window.atob(this.params['flow']) : '';
    }
    setTimeout(() => {
      this.getPlaceholder();
      this.isLoading = false;
    }, 500);
  }

  private getValidator(validatorKey: string): ValidatorFn {
    const validator = this.validatorService.getValidator(validatorKey);
    return (control: AbstractControl) => {
      const isValid = validator?.validate(control.value);
      return isValid ? null : { invalid: true };
    };
  }

  async getPlaceholder() {
    this.placeHolder = await this.textService.getPlaceholder(
      this.data.feature ?? '',
      this.data.placeholder?.key ?? '',
    );
  }

  actionToDo(action: string) {
    if (action == 'clear') {
      this.inputEmail?.setValue('');
      this.inputEmail?.updateValueAndValidity();
      this.userConflict = false;
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const params = {
        email: this.inputEmail?.value,
      };
      const queryParams = {
        flow: window.btoa(this.flow),
      };
      this.httpServicesPost(params, queryParams);
    }
  }
  httpServicesPost(params: object, queryParams: object) {
    this.httpMethodsService
      .putData(PATH_USER + `${this.dataUser?.id}/`, params)
      .subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this.authService.updateDataUser({ email: response.body.email });
            if (this.isMobile) {
              this.toolbarNavigationService.navigateForward(
                [USER_ROUTES.USERNAME],
                queryParams,
                null,
              );
            } else {
              this.toolbarNavigationService.navigateForwardModal(
                UsernameScreenName,
                queryParams,
              );
            }
          }
        },
        error: async (error) => {
          this.userConflict = error.status === 409;
          await this.errorModalService.showError(
            error.status === 409
              ? { feature: this.data.feature, key: this.data.userConflict }
              : { feature: this.data.feature, key: this.data.responseError },
          );
        },
      });
  }

  setAddEmailScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: AddEmailScreenName,
      dataText: AddEmailScreenTitleConfig,
      useToolbar: {
        state: true,
        closeAll: true,
      },
      useHeader: true,
      useFooter: true,
      presentation: ScreenPresentation.Modal,
    });
  }
}
