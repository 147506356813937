<div class="event-card">
  <div class="event-image">
    <img [src]="imageUrl" alt="Event image" />
  </div>
  <div class="event-details">
    <p class="event-time" TextStyle-L fz-12 fw-700 m-0>{{ eventTime }}</p>
    <h3 class="event-title" fz-14 fw-700 m-0>{{ eventTitle }}</h3>
    <p class="event-location" TextStyle-L fz-12 fw-700 m-0>
      {{ eventLocation }}
    </p>
    <p class="event-participants" fz-10 fw-700 m-0>
      {{ eventCategory }} • {{ participants }} participants
    </p>
  </div>
</div>
