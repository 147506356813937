import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

declare global {
  interface Window {
    dataLayer: Array<unknown>;
    gtag: (...args: unknown[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    @Inject('GTAG_ID') private gTagId: string,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (!this.gTagId) {
      console.error('gTagId is not provided');
    }
  }

  public init(): void {
    const trackingID = this.gTagId;
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingID}`,
    );

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);};gtag('js', new Date());gtag('config', '${trackingID}');gtag('send', {hitType: 'pageview',page: window.location.pathname});`;

    this.document.documentElement.firstChild?.appendChild(gaScript);
    this.document.documentElement.firstChild?.appendChild(gaScript2);
  }
}
