import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextService } from '../../atoms/text/services/text.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { EN } from '../toolbar/toolbar.interface';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  @Output() language = new EventEmitter<string>();
  @Input() lang!: string;
  isMobile = true;

  constructor(
    private textService: TextService,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      this.lang = EN;
    });
  }

  changeLanguage(language: string) {
    this.textService.setLanguage(language);
    this.authService.setLanguage(language);
    if (this.isMobile) {
      this.lang = language;
    } else {
      this.language.emit(language);
    }
  }
}
