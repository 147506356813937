import { Component, ViewChild } from '@angular/core';
import { ModalExternalUrlComponent } from '../../../../topcars-ui/modals/modal-external-url/modal-external-url.component';
import {
  LinkNames,
  LinkRedirects,
  LinkTexts,
  SocialNetworkIcons,
  SocialNetworkNames,
  SocialNetworkRedirect,
} from './constants';
import { NavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @ViewChild(ModalExternalUrlComponent)
  modalExternalUrl!: ModalExternalUrlComponent;
  translate = TRANSLATIONS.layout.footer;
  links = [
    {
      name: LinkNames.TOP_CARS,
      text: LinkTexts.TOP_CARS,
      link: LinkRedirects.TOP_CARS,
    },
    {
      name: LinkNames.TERMS_CONDITIONS,
      text: LinkTexts.TERMS_CONDITIONS,
      link: LinkRedirects.TERMS_CONDITIONS,
    },
    {
      name: LinkNames.PRIVACY_POLICY,
      text: LinkTexts.PRIVACY_POLICY,
      link: LinkRedirects.PRIVACY_POLICY,
    },
    /* {
      name: LinkNames,
      text: LinkTexts,
    }, */
  ];
  socialNetworks = [
    {
      name: SocialNetworkNames.X,
      icon: SocialNetworkIcons.X,
      link: SocialNetworkRedirect.X,
    },
    {
      name: SocialNetworkNames.YOUTUBE,
      icon: SocialNetworkIcons.YOUTUBE,
      link: SocialNetworkRedirect.YOUTUBE,
    },
    {
      name: SocialNetworkNames.FACEBOOK,
      icon: SocialNetworkIcons.FACEBOOK,
      link: SocialNetworkRedirect.FACEBOOK,
    },
    {
      name: SocialNetworkNames.INSTAGRAM,
      icon: SocialNetworkIcons.INSTAGRAM,
      link: SocialNetworkRedirect.INSTAGRAM,
    },
    {
      name: SocialNetworkNames.GOOGLE_PLAY,
      icon: SocialNetworkIcons.GOOGLE_PLAY,
      link: SocialNetworkRedirect.GOOGLE_PLAY,
    },
  ];

  constructor(public navigationService: NavigationService) {}

  /*  openUrlExternalModal() {
    this.modalExternalUrl.openModal();
  } */
}
