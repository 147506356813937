<p-dialog
  [(visible)]="display"
  [modal]="true"
  [closable]="true"
  [style]="{ width: '400px' }"
  [baseZIndex]="10000"
  [closable]="true"
>
  <ng-template pTemplate="header">
    <div class="modal-header">
      <app-tctext feature="modal-error" key="modal.errorModal"></app-tctext>
    </div>
  </ng-template>
  <p style="padding: var(--size-12) var(--size-28)">
    <app-tctext
      [feature]="errorMessage.feature ?? ''"
      [key]="errorMessage.key ?? ''"
    ></app-tctext>
  </p>
  <app-tcbutton pb-24 [type]="TypesButton.primary" (click)="confirm()">
    <app-tctext feature="modal-error" key="modal.confirmModal"></app-tctext>
  </app-tcbutton>
</p-dialog>
