<div class="container-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-flow-user></app-auth-flow-user>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>

    <div class="content-pages">
      <h1
        m-0
        w-100
        pt-24
        pb-12
        tc-title
        [desktopMode]="!isMobile"
        [tcTitle]="TypesTitles.GENERAL"
        [innerHTML]="translate.addEmail.title | translate"
      ></h1>

      <h3
        m-0
        w-100
        pb-24
        tc-title
        [desktopMode]="!isMobile"
        [tcTitle]="TypesTitles.SUBTITLE_GENERAL"
        [innerHTML]="translate.addEmail.subtitle | translate"
      ></h3>

      <form [formGroup]="form">
        <app-tcinput
          pb-168
          icon="system_email"
          formControlName="inputEmail"
          [placeholder]="translate.addEmail.addPlaceH | translate"
          [errorMessage]="translate.addEmail.errorMessage | translate"
          validatorKey="email"
          [maxlength]="'50'"
          (action)="actionToDo($event)"
        ></app-tcinput>

        <app-tcbutton
          pb-24
          [type]="TypesButton.PRIMARY"
          [disabled]="!this.form.valid || isLoading"
          (click)="onSubmit()"
        >
          <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
          {{ translate.addEmail.continue | translate }}
        </app-tcbutton>
      </form>

      <div class="message-validation" *ngIf="userConflict">
        <app-tc-icon [iconId]="'system_alert_circle'"></app-tc-icon>
        <p class="text" fz-14 fw-400 TextStyle-L>
          {{ translate.addEmail.messageVal | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
