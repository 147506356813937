import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CarouselModule } from 'primeng/carousel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

import { SvgIconDirective } from './directives/svg-icon.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { LettersOnlyDirective } from './directives/letters-only.directive';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    SvgIconDirective,
    NumbersOnlyDirective,
    LettersOnlyDirective,
    AlphanumericDirective,
    DynamicComponentDirective,
  ],
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    CarouselModule,
    DialogModule,
    DynamicDialogModule,
  ],
  exports: [
    SvgIconDirective,
    NumbersOnlyDirective,
    LettersOnlyDirective,
    AlphanumericDirective,
    DynamicComponentDirective,
  ],
})
export class LibrariesModule {}
