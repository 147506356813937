import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ICON_CATALOG } from '@/app/libraries/core/icon/icon-catalog';
import { Dialog } from 'primeng/dialog';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent {
  @ViewChild(Dialog) dialog!: Dialog;
  @Input() type = 0;
  @Output() confirmModal = new EventEmitter<boolean>();
  displayModal = false;
  modalClosing = false;
  system_location = '';
  systemNoLocation = '';
  TypesButton = TypesButton;
  translate = TRANSLATIONS.events.all;

  constructor() {
    this.system_location = ICON_CATALOG['system_location'] || '';
    this.systemNoLocation = ICON_CATALOG['system_no_location'] || '';
  }

  openModal() {
    this.displayModal = true;
    this.modalClosing = false;
  }
  confirmPermissionsModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
    this.confirmModal.emit(true);
  }
  closeModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
  }
}
