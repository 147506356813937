import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VerificationRoutingModule } from './verification-routing.module';
import { VerificationComponent } from './verification.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../libraries/main-layout/services/modal.service';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';

@NgModule({
  declarations: [VerificationComponent],
  imports: [
    CommonModule,
    VerificationRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
  ],
})
export class VerificationModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('verification', () =>
      import('./verification.component').then((m) => m.VerificationComponent),
    );
  }
}
