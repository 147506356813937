export interface SduiSection {
  id: string;
  title: string | null;
  type: string;
  city?: City;
  subscribed?: boolean;
  events?: Event[];
}

export interface City {
  id: string;
  name: string;
  state: string;
  imageUrl: string;
  events: number;
  videoUrl: string;
  key: string;
  description: string;
}

export interface Event {
  id: string;
  title: string;
  subtitle: string;
  caption: string;
  label: string;
  imageUrl: string;
  initialDate: string;
  finalDate: string;
  latitude: number;
  longitude: number;
  isActive: boolean;
  shareUrl: string;
  isPublic: boolean;
  thumbnail: string;
  thumbnailWeb: string;
}

export const EVENTS_CITIES = 'EVENTS_CITIES';
export const EVENTS_SECTION = 'EVENTS_SECTION';
export const EVENTS_UPCOMING = 'EVENTS_UPCOMING';
export const CITY_BACK_SECTION = 'CITY_HEADER';

export interface EventTime {
  initial_date: string;
  final_date: string;
}
