<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '60vw', height: '60vh' }"
  [dismissableMask]="true"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="
    modalClosing ? 'url-external-modal closing' : 'url-external-modal'
  "
>
  <div class="modal-content">
    <div px-24 pt-24 pb-24 class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
    </div>
  </div>
  <ng-template pTemplate="header">
    {{ modalTitle }}
  </ng-template>
  <ng-template pTemplate="content">
    <iframe
      [src]="externalUrl"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </ng-template>
</p-dialog>
