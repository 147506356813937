<div class="skeleton-container" [ngClass]="type">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'card'">
      <div class="skeleton-item" *ngFor="let i of [].constructor(count)">
        <div class="skeleton-image"></div>
        <div class="skeleton-content">
          <div class="skeleton-line" style="width: 50%"></div>
          <div class="skeleton-line"></div>
          <div class="skeleton-line" style="width: 80%"></div>
          <div class="skeleton-line" style="width: 80%"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'card-slim'">
      <div
        class="skeleton-item"
        style="height: 5.5rem"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-image"></div>
        <div class="skeleton-content">
          <div class="skeleton-line" style="width: 50%"></div>
          <div class="skeleton-line"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'card-v'">
      <div
        class="skeleton-form"
        style="height: 23.75rem"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-image-v"></div>
        <div class="skeleton-content-v">
          <div class="skeleton-line" style="width: 50%"></div>
          <div class="skeleton-line" style="width: 80%"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'image'">
      <div
        class="skeleton-form"
        style="height: 23.75rem"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-image-v"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'image-alt'">
      <div
        class="skeleton-form"
        style="height: 12rem"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-image-v"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'form-label'">
      <div
        class="skeleton-form"
        style="height: 4.375rem"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-label"></div>
        <div class="skeleton-input"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'form'">
      <div
        class="skeleton-form"
        style="height: 3.125rem !important"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-input"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'input'">
      <div class="skeleton-item" *ngFor="let i of [].constructor(count)">
        <div class="skeleton-input"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'circle'">
      <div class="skeleton-form-c" *ngFor="let i of [].constructor(count)">
        <div class="skeleton-circle"></div>
        <div class="skeleton-label" style="width: 90%"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'avatar'">
      <div
        class="skeleton-form-c"
        style="
          width: 100%;
          height: 3rem;
          justify-content: flex-start;
          flex-direction: row;
        "
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-avatar"></div>
        <div class="skeleton-avatar"></div>
        <div class="skeleton-avatar"></div>
        <div class="skeleton-avatar"></div>
        <div class="skeleton-avatar"></div>
        <div class="skeleton-avatar"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'label'">
      <div
        class="skeleton-item"
        style="height: 2rem"
        *ngFor="let i of [].constructor(count)"
      >
        <div class="skeleton-label" style="width: 100%; margin: 0"></div>
      </div>
    </ng-container>
  </ng-container>
</div>
