import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenConfigService } from '../../../libraries/main-layout/services/screen-config.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { ModalService } from '../../../libraries/core/navigation/modal.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  PATH_SIGN_EMAIL_VERIFICATION,
  PATH_SIGN_PHONE_VERIFICATION,
} from '@/app/features/authentication/constants';
import { ResponseData, UserData } from '@/app/features/authentication/types';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { APP_ROUTES } from '../../layout/main-layout/main-layout-routing.module';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import {
  QueryParam,
  ScreenPresentation,
  ScreenTitleConfig,
  ToolbarOptions,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { TitleType } from '@/app/topcars-ui/title/types/title';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import {
  VerificationScreenName,
  VerificationScreenTitleConfig,
} from './verification.config';
import { DataOtp } from '@/app/design-system/atoms/otp-input/types';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';
import { ParamsFormat } from '../login/types';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  otpDisabled = false;
  cleanOTP = false;
  phone = false;
  otpValid = false;
  otpValue = '';
  currentRoute = '';
  flow = '';
  email = '';
  countryCode = '';
  phoneNumber = '';
  form!: FormGroup;
  toolbarActions!: ToolbarOptions;
  isMobile = false;
  isLoading = true;
  params!: ParamsFormat;
  QueryParam = QueryParam;
  TypesTitles = TitleType;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  dataOTP!: DataOtp;
  translate = TRANSLATIONS.authentication;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private modalService: ModalService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setVerificationScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
        this.toolbarActions = { ...config.useToolbar };
      }
    });
    this.form = this.fb.group({
      inputOTP: [null, [Validators.required]],
    });
    this.currentRoute = this.router.url;
  }

  get inputOTP(): AbstractControl | null {
    return this.form.get('inputOTP');
  }

  ngOnInit(): void {
    this.params = this.config?.data?.params;
    this.flow = this.params['flow'] ? window.atob(this.params['flow']) : '';
    this.email = this.params['email'] ? window.atob(this.params['email']) : '';
    this.phoneNumber = this.params['phone']
      ? window.atob(this.params['phone'])
      : '';
    this.countryCode = this.params['code']
      ? window.atob(this.params['code'])
      : '';

    this.modalService.onModalVisibilityChange().subscribe(() => {
      this.cleanOTP = true;
      this.otpValue = '';
      this.otpValid = false;
      this.inputOTP?.setValue(null);
      setTimeout(() => {
        this.cleanOTP = false;
      }, 100);
    });
    this.dataOTP = {
      flow: this.flow,
      email: this.email,
      code: this.countryCode,
      phone: this.phoneNumber,
      data: this.data,
    };
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  onOtpComplete(value: string) {
    this.otpValue = value;
    if (this.otpValue.length === 6) {
      this.otpValid = true;
      this.inputOTP?.setValue(this.otpValue);
      this.onSubmit();
    } else {
      this.otpValid = false;
    }
  }

  onSubmit() {
    if (this.form.valid) {
      let params;
      let queryParams;

      if (this.flow === QueryParam.FLOW_PHONE) {
        params = {
          area_code: this.countryCode,
          phone: this.phoneNumber,
          code: this.inputOTP?.value,
        };
        queryParams = {
          flow: window.btoa(this.flow),
        };
      } else {
        params = {
          email: this.email,
          code: this.inputOTP?.value,
        };
        queryParams = {
          flow: window.btoa(this.flow),
        };
      }
      this.httpServicesPost(params, queryParams);
    }
  }
  httpServicesPost(params: object, queryParams: object) {
    this.isLoading = true;
    const postPath =
      this.flow === QueryParam.FLOW_EMAIL
        ? PATH_SIGN_EMAIL_VERIFICATION
        : PATH_SIGN_PHONE_VERIFICATION;

    this.httpMethodsService.postData<ResponseData>(postPath, params).subscribe({
      next: (response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.body
        ) {
          const responseBody: ResponseData = response.body;
          this.authService.login(responseBody.token);
          const dataUser: UserData = {
            id: responseBody.user.id,
            email: responseBody.user.email,
            username: responseBody.user.username,
            full_name: responseBody.user.full_name,
            birthdate: responseBody.user.birthdate,
            is_verified: responseBody.user.is_verified,
            is_registered: responseBody.user.is_registered,
            profile_picture: responseBody.user.profile_picture,
          };
          this.authService.setDataUser(dataUser);
          this.isLoading = false;
          this.modalService.closeAllModals();
          if (this.currentRoute.includes(`${'/'}${APP_ROUTES.EVENTS}`)) {
            window.location.reload();
          } else {
            this.toolbarNavigationService.navigateForward(
              [APP_ROUTES.EVENTS],
              queryParams,
              null,
            );
          }
        }
      },
      error: async (error) => {
        let errorKey;
        if (error.status === 400) {
          errorKey = {
            feature: this.data.feature,
            key: this.data.userNotFound,
          };
        } else if (error.status === 401) {
          errorKey = { feature: this.data.feature, key: this.data.invalidCode };
        } else {
          errorKey = { feature: this.data.feature, key: this.data.codeExpired };
        }
        await this.errorModalService.showError(errorKey);
        this.isLoading = false;
      },
    });
  }

  setVerificationScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: VerificationScreenName,
      dataText: VerificationScreenTitleConfig,
      useToolbar: {
        state: true,
        close: true,
      },
      useHeader: true,
      useFooter: true,
      presentation: ScreenPresentation.MODAL,
    });
  }
}
