<div class="otp-container">
  <form [formGroup]="otpForm" class="otp-form">
    <div class="otp-input-wrapper">
      <ng-container *ngFor="let control of otpControls; let i = index">
        <input
          type="text"
          [formControlName]="'digit' + i"
          maxlength="1"
          class="otp-input"
          (keyup)="onKeyUp($event, i)"
          [ngClass]="{
            'otp-input-complete': control.value.length === 1 && control.valid,
            'otp-input-error':
              control.invalid && (control.dirty || control.touched),
            'otp-input-initial': control.value.length === 0,
            'otp-input-disabled': isDisabled,
          }"
        />
      </ng-container>
    </div>
  </form>

  <p
    *ngIf="
      !isDisabled &&
      (this.countdownTime.getMinutes() > 0 ||
        this.countdownTime.getSeconds() > 0)
    "
  >
    <app-tctext
      TextStyle
      fz-14
      fw-400
      pt-12
      pb-42
      m-0
      feature="g-auth"
      key="verification.requestCode"
    ></app-tctext>
    <strong>{{ countdownTime | date: "mm:ss" }}</strong>
  </p>

  <p
    *ngIf="
      !isDisabled &&
      this.countdownTime.getMinutes() === 0 &&
      this.countdownTime.getSeconds() === 0
    "
  >
    <app-tctext
      TextStyle
      tcConcrete
      fz-14
      fw-400
      pt-12
      pb-42
      m-0
      feature="g-auth"
      key="verification.messageReSend"
    ></app-tctext>
    <app-tctext
      TextStyle
      fz-14
      fw-400
      pt-12
      pb-42
      m-0
      feature="g-auth"
      key="verification.reSend"
      style="cursor: pointer; text-decoration: underline"
      (click)="reSendCode()"
    ></app-tctext>
  </p>

  <app-tctext
    TextStyle
    fz-14
    fw-400
    pt-12
    pb-42
    m-0
    *ngIf="isDisabled"
    style="color: var(--error)"
    feature="g-auth"
    key="verification.maximumAttempts"
  ></app-tctext>
</div>
