<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="modalClosing ? 'location-modal closing' : 'location-modal'"
>
  <div class="modal-content">
    <div pb-24 w-100 style="display: flex" class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>

      <app-tctext
        TextStyle
        w-100
        fz-14
        fw-700
        pb-24
        m-0
        style="color: var(--milk)"
        feature="g-events"
        key="all.selectLocation"
      ></app-tctext>
    </div>

    <img class="imgLocation" [src]="system_location" alt="" />

    <app-tctext
      TextStyle-B
      w-100
      fz-22
      fw-500
      pt-24
      pb-12
      m-0
      style="color: var(--milk)"
      feature="g-events"
      key="all.noLocation"
    ></app-tctext>

    <app-tctext
      TextStyle-L
      w-100
      fz-14
      fw-400
      pb-24
      m-0
      style="color: var(--milk)"
      feature="g-events"
      key="all.accessLocation"
    ></app-tctext>

    <app-tcbutton
      [type]="TypesButton.primary"
      [disabled]="false"
      (click)="confirmPermissionsModal()"
    >
      <app-tctext feature="g-events" key="all.giveLocation"></app-tctext>
    </app-tcbutton>
  </div>
</p-dialog>
