<app-toolbar
  w-100
  pt-12
  *ngIf="isMobile && !priority"
  [toolbarConfig]="{
    state: true,
    close: true,
  }"
></app-toolbar>
<div class="dropdown">
  <h1 m-0 fz-16 tcMilk text-center class="title" *ngIf="isMobile">
    {{ translate.titleLang | translate }}
  </h1>
  <button
    (click)="changeLanguage('en')"
    [ngClass]="{ 'lang-active': lang === 'en' }"
  >
    {{ translate.languageEn | translate }}
    <app-tc-icon
      *ngIf="lang === 'en'"
      [iconId]="'check_selected'"
    ></app-tc-icon>
  </button>
  <button
    (click)="changeLanguage('es')"
    [ngClass]="{ 'lang-active': lang === 'es' }"
  >
    {{ translate.languageEs | translate }}
    <app-tc-icon
      *ngIf="lang === 'es'"
      [iconId]="'check_selected'"
    ></app-tc-icon>
  </button>
</div>
