<div class="header">
  <app-tc-icon
    cursor-pointer
    class="header-logo"
    [iconId]="'system_top_cars_logo'"
    (click)="goHome()"
  ></app-tc-icon>

  <div class="content-header">
    <ng-container>
      <h1
        m-0
        fz-16
        tcMilk
        TextStyle-B
        cursor-pointer
        *ngIf="!isMobile"
        (click)="goHome()"
      >
        {{ translate.redirectEvents | translate }}
      </h1>
    </ng-container>

    <div
      class="language-selector"
      [ngClass]="{ active: isDropdownOpen }"
      (click)="toggleDropdown()"
    >
      <div>
        <app-tc-icon [iconId]="'system_globe_translate'"></app-tc-icon>
      </div>
      <h1 tcMilk fz-16 fw-700 m-0 *ngIf="!isMobile">{{ language }}</h1>
      <app-language-dropdown
        *ngIf="isDropdownOpen"
        class="dropdown"
        [lang]="language"
        [@fadeSlide]
      ></app-language-dropdown>
    </div>

    <div style="position: relative; cursor: pointer">
      <ng-container>
        <app-tcbutton
          *ngIf="!isLoggedIn"
          [type]="TypesButton.SMALL"
          [disabled]="false"
          (click)="signIn()"
        >
          {{ translate.signIn | translate }}
        </app-tcbutton>
      </ng-container>

      <ng-container *ngIf="isLoggedIn">
        <app-tc-identity
          [userData]="dataUser"
          [isMobile]="isMobile"
          [isDropUser]="isDropUser"
          (click)="toggleDropUser()"
        ></app-tc-identity>
        <app-user-profile *ngIf="isDropUser" class="drop-user" [@fadeSlide]>
        </app-user-profile>
      </ng-container>
    </div>
  </div>
</div>

<app-user-config #userConfig> </app-user-config>
<app-user-language #userLang> </app-user-language>
