import {
  Directive,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
  OnChanges,
} from '@angular/core';
import { SduiSection } from '../sdui-section.interface';
import { ComponentRegistryService } from '../services/component-registry.service';

@Directive({
  selector: '[appDynamicSection]',
})
export class DynamicSectionDirective implements OnInit, OnChanges {
  @Input() appDynamicSection!: SduiSection;

  constructor(
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private registry: ComponentRegistryService,
  ) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngOnChanges() {
    this.loadComponent();
  }

  private loadComponent() {
    this.viewContainer.clear();
    const componentType = this.registry.get(this.appDynamicSection.type);
    if (componentType) {
      const componentFactory =
        this.resolver.resolveComponentFactory(componentType);
      const component = this.viewContainer.createComponent(componentFactory);
      (component.instance as any).data = this.appDynamicSection;
    }
  }
}
