<div class="content-pages">
  <app-loading-skeleton
    w-100
    [type]="'card-v'"
    [count]="3"
    *ngIf="isLoading"
  ></app-loading-skeleton>
  <div class="content-cards" *ngIf="!isLoading" w-100>
    <ng-container *ngFor="let section of upcoming">
      <app-tc-event-hero-card
        w-100
        [id]="section.id"
        [image]="section.imageUrl"
        [dateTime]="section.caption"
        [eventTitle]="section.title"
        [eventLocation]="section.subtitle"
        [eventInfo]="section.label"
        [upcoming]="true"
      ></app-tc-event-hero-card>
    </ng-container>
  </div>
</div>
