import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const ProfileScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-user',
  title: 'profile.title',
  subtitle: 'profile.subtitle',
  placeholder: {
    key: 'profile.userPlaceHInput',
    keySecond: 'profile.userPlaceHBirthdate',
  },
  continue: 'profile.continue',
  errorFullname: 'profile.errorMessage',
  errorBirthdate: 'profile.errorMessageB',
  errorPost: 'profile.errorPost',
  responseError: 'responseError',
  userConflict: 'userConflict',
  responseErrorAvatar: 'responseErrorAvatar',
};

export const ProfileScreenName = 'profile';
