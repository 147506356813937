export class StorageHelper {
  static parse<T>(value: string | null): T | null {
    if (value === null) return null;
    try {
      return JSON.parse(value) as T;
    } catch (error) {
      console.error('Error parsing storage value:', error);
      return null;
    }
  }

  static stringify<T>(value: T): string {
    try {
      return JSON.stringify(value);
    } catch (error) {
      console.error('Error serializing storage value:', error);
      return '';
    }
  }
}
