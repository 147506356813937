import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { Component } from '@angular/core';
import { Section } from '../../../events-all/pages/general-events/types';

@Component({
  selector: 'app-events-section',
  templateUrl: './events-section.component.html',
  styleUrls: ['./events-section.component.scss'],
})
export class EventsSectionComponent {
  data!: Section;

  constructor(public toolbarNavigationService: NavigationService) {}

  getIsMobile() {
    return window.innerWidth < 768;
  }

  eventDetail(id: string) {
    if (this.getIsMobile()) {
      this.toolbarNavigationService.navigateForward(
        [`${EVENTS_ROUTES.Events}/${id}`],
        {},
      );
    } else {
      this.toolbarNavigationService.navigateToNewTab([
        `${EVENTS_ROUTES.Events}/${id}`,
      ]);
    }
  }

  onCtaClick() {
    console.log('CTA clicked from parent!');
  }
}
