import { Component, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { IconRegistryService } from '../icon-registry.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tc-icon',
  template: '',
})
export class IconComponent implements OnInit {
  @Input() name!: string;
  @Input() size?: string | number = '24';
  @Input() height!: string | number;
  @Input() width!: string | number;

  constructor(
    private iconRegistry: IconRegistryService,
    private el: ElementRef,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
  ) {}

  private renderIcon() {
    const icon = this.iconRegistry.getIcon(this.name);
    if (!icon) {
      throw new Error(`Icon "${this.name}" not found`);
    }

    const iconSanitized = this.domSanitizer.sanitize(1, icon);

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      iconSanitized,
    );

    const svg = this.el.nativeElement.querySelector('svg');

    this.renderer.setAttribute(
      svg,
      'width',
      this.getSize(this.width || this.size),
    );
    this.renderer.setAttribute(
      svg,
      'height',
      this.getSize(this.height || this.size),
    );
  }

  private getSize(value: string | number | undefined): string {
    if (!value) {
      return `${this.size}px`;
    }

    if (typeof value === 'number') {
      return `${value}px`;
    }

    const isUnitProvided = /\d+(px|em|rem|%|vh|vw|vmin|vmax)$/i.test(value);
    if (isUnitProvided) {
      return value;
    }

    const isNumeric = /^\d+$/i.test(value);
    if (isNumeric) {
      return `${value}px`;
    }

    throw new Error(
      'Invalid size value. It must be a number or a valid size string.',
    );
  }

  ngOnInit(): void {
    this.renderIcon();
  }
}
