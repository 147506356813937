import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const UsernameScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-user',
  title: 'username.title',
  subtitle: 'username.subtitle',
  placeholder: {
    key: 'username.userPlaceHInput',
  },
  continue: 'username.continue',
  errorUsername: 'username.errorMessage',
  errorPost: 'username.errorPost',
  responseError: 'username.responseError',
  userConflict: 'username.409_UserConflict',
};

export const UsernameScreenName = 'username';
