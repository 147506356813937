<div class="container-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-flow-auth></app-auth-flow-auth>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>

    <div class="content-pages">
      <h1
        m-0
        w-100
        pt-24
        pb-12
        tc-title
        [desktopMode]="!isMobile"
        [tcTitle]="TypesTitles.GENERAL"
        [innerHTML]="translate.verification.title | translate"
      ></h1>

      <p w-100 m-0 pb-24 [ngStyle]="{ display: 'inline' }">
        <ng-container *ngIf="flow === QueryParam.FLOW_EMAIL">
          <h3
            m-0
            tc-title
            [desktopMode]="!isMobile"
            [tcTitle]="TypesTitles.SUBTITLE_GENERAL"
            [innerHTML]="translate.verification.message | translate"
            [ngStyle]="{ display: 'inline-block' }"
          ></h3>
          <strong TextStyle-B fz-14 style="text-transform: lowercase">{{
            " " + email
          }}</strong>
        </ng-container>

        <ng-container *ngIf="flow === QueryParam.FLOW_PHONE">
          <h3
            m-0
            tc-title
            [desktopMode]="!isMobile"
            [tcTitle]="TypesTitles.SUBTITLE_GENERAL"
            [innerHTML]="translate.verification.messagePhone | translate"
            [ngStyle]="{ display: 'inline-block' }"
          ></h3>
          <strong TextStyle-B fz-14>{{
            " +" + countryCode + " " + phoneNumber
          }}</strong>
        </ng-container>
      </p>

      <form [formGroup]="form" (keyup.enter)="onSubmit()">
        <app-otp-input
          w-100
          pb-88
          [data]="dataOTP"
          [isDisabled]="otpDisabled"
          [cleanOTP]="cleanOTP"
          (otpComplete)="onOtpComplete($event)"
        ></app-otp-input>

        <app-tcbutton
          #submitBtn
          pb-24
          [type]="TypesButton.PRIMARY"
          [disabled]="!form.valid || !otpValid || isLoading"
          (click)="onSubmit(); $event.preventDefault()"
        >
          <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
          {{ translate.verification.continue | translate }}
        </app-tcbutton>
      </form>
    </div>
  </ng-container>
</div>
