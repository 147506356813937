<div class="container">
  <app-header w-100 *ngIf="config?.useHeader"></app-header>

  <main class="content">
    <div [ngClass]="presentationClass" [@presentationAnimations]>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </main>

  <app-footer w-100 *ngIf="config?.useFooter"></app-footer>
</div>
