<div class="event-card">
  <div class="event-image">
    <img [src]="imageUrl" alt="Event image" />
  </div>
  <div class="event-details">
    <p class="event-title" fz-14 fw-500 m-0>{{ eventOrganizer }}</p>
    <h3 class="event-title" fz-16 fw-700 m-0>{{ eventTitle }}</h3>
    <div d-flex style="justify-content: flex-start; align-items: center">
      <app-tc-icon [iconId]="'system_pin_events'" class="icon"></app-tc-icon>
      <p class="event-location" TextStyle fz-14 fw-500 m-0>
        {{ eventLocation }}
      </p>
    </div>
    <div d-flex style="justify-content: flex-start; align-items: center">
      <app-tc-icon
        [iconId]="'system_calendar_events'"
        class="icon"
      ></app-tc-icon>
      <p class="event-participants" fz-14 fw-500 m-0>
        {{
          initialDateValues.month +
            " " +
            initialDateValues.day +
            " • " +
            initialDateValues.localTime
        }}
      </p>
    </div>
  </div>
</div>
