import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const GeneralEventsScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-events',
  responseError: 'responseErrorAllEvents',
  events: {
    feature: 'g-events',
    title: 'all.locationCity',
    subtitle: '',
    CTA: 'all.viewAll',
  },
  cities: {
    feature: 'g-events',
    title: 'all.cities',
    subtitle: '',
    CTA: 'all.viewAll',
  },
};

export const GeneralEventsScreenName = 'events';
