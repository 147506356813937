import { ValidatorPlugin } from '../../core/validator-interface/validator-plugin.interface';

export class PasswordValidatorPlugin implements ValidatorPlugin {
  validate(value: string): boolean {
    const isMinLength = value.length >= 6;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    return isMinLength && hasUpperCase && hasNumber && hasSpecialChar;
  }
}
