<section>
  <app-tc-header-section
    w-100
    [title]="true"
    [subtitle]="false"
    [ctaLabel]="false"
    [ctaAction]="onCtaClick"
    [params]="data"
  >
  </app-tc-header-section>

  <div class="cities-list" pb-32>
    <app-tc-city-card
      *ngFor="let city of data.cities"
      [imageUrl]="city.imageUrl"
      [cityName]="city.name + ', ' + city.state"
      [events]="city.events.toString()"
      (click)="cityDetail(city.key)"
    ></app-tc-city-card>
  </div>
</section>
