import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphanumeric]',
})
export class AlphanumericDirective {
  private regex = new RegExp(/^[a-zA-Z0-9]+$/);

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    if (
      event.target &&
      (event.target as HTMLInputElement).value.length === 0 &&
      inputChar === ' '
    ) {
      event.preventDefault();
    }

    if (!this.regex.test(inputChar)) {
      event.preventDefault();
    }
  }
}
