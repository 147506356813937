import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  convertToLocalDate(dateUtc: string): {
    localDate: string;
    dayOfWeek: string;
    day: number;
    month: string;
    localTime: string;
  } {
    const dateObj = new Date(dateUtc);
    const localDate = dateObj.toLocaleString();
    const dayOfWeek = dateObj.toLocaleString('default', { weekday: 'long' });
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const localTime = dateObj.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return {
      localDate,
      dayOfWeek,
      day,
      month,
      localTime,
    };
  }
}
