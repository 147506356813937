<div class="info-logged-in">
  <img
    class="identity-avatar"
    [src]="userData?.profile_picture ?? 'system_avatar_default'"
  />
  <ng-container>
    <p fz-16 tcMilk TextStyle-B style="line-height: 1">
      {{ userData?.username ? "@" + userData?.username : "Menu" }}
    </p>
    <app-tc-icon
      *ngIf="userData?.is_verified"
      class="identity-verified"
      [iconId]="'system_verified'"
    ></app-tc-icon>
  </ng-container>
</div>
