import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from './analytics.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AnalyticsService],
})
export class AnalyticsModule {
  static forRoot({
    gtagId,
  }: {
    gtagId: string;
  }): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {
          provide: 'GTAG_ID',
          useValue: gtagId,
        },
      ],
    };
  }
}
