import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { ScreenConfigService } from '../../../libraries/main-layout/services/screen-config.service';
import {
  ScreenConfiguration,
  ScreenPresentation,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  config!: ScreenConfiguration;
  presentationClass = '';
  isVisibleModal = false;
  isMobile = false;
  private destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private screenSizeService: ScreenSizeService,
    @Optional() private screenConfigService: ScreenConfigService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.screenConfigService.config$
      .pipe(takeUntil(this.destroy$))
      .subscribe((config) => {
        if (config) {
          this.config = config;
          this.setPresentationClass(config.presentation);
          this.cd.detectChanges();
        }
      });
  }

  setPresentationClass(presentation: ScreenPresentation) {
    this.presentationClass = presentation;
  }
}
