import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private activeModals: DynamicDialogRef[] = [];
  private modalVisibilitySubject: Subject<string> = new Subject<string>();
  private modalMap: Record<string, () => Promise<any>> = {};

  constructor(
    private router: Router,
    private dialogService: DialogService,
  ) {}

  registerModal(modalName: string, componentLoader: () => Promise<unknown>) {
    this.modalMap[modalName] = componentLoader;
  }

  async openModal(modalName: string, params: unknown): Promise<any> {
    const componentLoader = this.modalMap[modalName];

    if (!componentLoader) {
      console.error(`Modal component not found for name: ${modalName}`);
      return null;
    }

    const dialogElements = document.querySelectorAll('.p-dialog');
    const activeDialogElement =
      dialogElements.length > 0
        ? dialogElements[dialogElements.length - 1]
        : undefined;
    if (activeDialogElement) {
      activeDialogElement?.classList.add('hide');
    }

    const component = await componentLoader();
    const modalRef = this.dialogService.open(component, {
      data: { params },
      modal: true,
      showHeader: false,
      dismissableMask: false,
      styleClass: 'dynamic-modal start',
      transitionOptions: '0ms',
    });
    this.activeModals.push(modalRef);

    return new Promise((resolve) => {
      modalRef.onClose.subscribe((result) => {
        this.modalVisibilitySubject.next(modalName);
        this.activeModals = this.activeModals.filter((m) => m !== modalRef);
        resolve(result);
      });
    });
  }

  closeActiveModal(clearQueryParams = false, result?: unknown) {
    if (this.activeModals.length > 0) {
      const lastModal = this.activeModals[this.activeModals.length - 1];

      const dialogElements = document.querySelectorAll('.p-dialog');
      const activeDialogElement =
        dialogElements.length > 1
          ? dialogElements[dialogElements.length - 2]
          : undefined;
      const lastDialogElement =
        dialogElements.length > 0
          ? dialogElements[dialogElements.length - 1]
          : undefined;

      if (lastDialogElement) {
        activeDialogElement?.classList.remove('start');
        activeDialogElement?.classList.remove('hide');
        activeDialogElement?.classList.add('show');
        lastDialogElement?.classList.add(
          dialogElements.length === 1 ? 'hide' : 'closing',
        );
      }
      setTimeout(() => {
        if (lastDialogElement) {
          activeDialogElement?.classList.remove('show');
          lastDialogElement?.classList.remove('closing');
        }
        lastModal.close(result);
        if (clearQueryParams) {
          this.clearQueryParams();
        }
      }, 400);
    } else {
      console.warn('No active modal to close.');
    }
  }

  closeAllModals(clearQueryParams = false) {
    this.activeModals.forEach((modal) => modal.close());
    this.activeModals = [];
    if (clearQueryParams) {
      this.clearQueryParams();
    }
  }

  private clearQueryParams() {
    this.router.navigate([], {
      queryParams: {},
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  onModalVisibilityChange() {
    return this.modalVisibilitySubject.asObservable();
  }
}
