import { APP_ROUTES } from '@/app/features/layout/main-layout/main-layout-routing.module';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private router: Router) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAccess();
  }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAccess();
  }

  private checkAccess(): boolean {
    const isAllowed = false;
    if (!isAllowed) {
      this.router.navigate([APP_ROUTES.EVENTS]);
      return false;
    }
    return true;
  }
}
