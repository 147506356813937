<div class="toolbar">
  <app-tc-icon
    *ngIf="toolbarConfig.pop"
    [iconId]="'system_arrowBack'"
    (click)="closeOrGoBack()"
  ></app-tc-icon>
  <app-tc-icon
    *ngIf="toolbarConfig.close || toolbarConfig.closeAll"
    [iconId]="'system_close_modal'"
    (click)="closeOrGoBack()"
  ></app-tc-icon>

  <div class="toolbar-username" *ngIf="toolbarConfig.username">
    {{ "mind1997" }}
    <app-tc-icon [iconId]="'system_verified'"></app-tc-icon>
  </div>

  <div *ngIf="toolbarConfig.actions" class="toolbar-options">
    <app-tc-icon [iconId]="'system_share_light'"></app-tc-icon>
    <app-tc-icon [iconId]="'system_settings'"></app-tc-icon>
  </div>
</div>
