<img *ngIf="iconSrc" [src]="iconSrc" [alt]="iconId" class="icon" />
<img
  *ngIf="!iconSrc && iconId"
  [src]="iconId"
  [alt]="iconId"
  class="icon"
  style="border-radius: 50%; object-fit: cover"
/>
<span *ngIf="!iconSrc && !iconId">Icon not found</span>

<!-- EXAMPLE: <app-tc-icon [iconId]="'system_pin'"></app-tc-icon> -->
