import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss'],
})
export class UserConfigComponent implements OnDestroy {
  displayModal = false;
  modalClosing = false;

  private handleDocumentClickBound: (event: MouseEvent) => void;
  private handleKeyDownBound: (event: KeyboardEvent) => void;

  constructor() {
    this.handleDocumentClickBound = this.handleDocumentClick.bind(this);
    this.handleKeyDownBound = this.handleKeyDown.bind(this);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.handleDocumentClickBound);
    document.removeEventListener('keydown', this.handleKeyDownBound);
  }

  openModal() {
    this.displayModal = true;
    this.modalClosing = false;
    setTimeout(() => {
      document.addEventListener('click', this.handleDocumentClickBound);
      document.addEventListener('keydown', this.handleKeyDownBound);
    });
  }

  closeModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
      document.removeEventListener('click', this.handleDocumentClickBound);
      document.removeEventListener('keydown', this.handleKeyDownBound);
    }, 400);
  }

  logOutEvent(event: boolean) {
    if (event) {
      this.closeModal();
    }
  }

  private handleDocumentClick(event: MouseEvent): void {
    const targetElement = document.getElementById('modal-content');
    const targetElementDynamic = document.querySelector('p-dynamicdialog');

    const isClickedInsideDropdown = targetElement?.contains(
      event.target as Node,
    );

    if (!isClickedInsideDropdown && !targetElementDynamic) {
      if (this.displayModal) {
        this.closeModal();
      }
    }
  }

  private handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }
}
