import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const AddEmailScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-user',
  title: 'addEmail.title',
  subtitle: 'addEmail.subtitle',
  placeholder: {
    key: 'addEmail.addPlaceH',
  },
  continue: 'addEmail.continue',
  errorAddEmail: 'addEmail.errorMessage',
  errorPost: 'addEmail.errorPost',
  responseError: 'responseError',
  userConflict: 'userConflict',
};

export const AddEmailScreenName = 'addemail';
