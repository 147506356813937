<div class="content-pages">
  <div class="text-title" w-100 *ngIf="isMobile">
    <h1
      class="text"
      fz-24
      fw-400
      m-0
      TextStyle-Secondary
      (click)="routeGeneral()"
      [ngClass]="{ routeSelected: !routeOption }"
    >
      {{ translate.all.title | translate }}
    </h1>
    <h1
      class="text"
      fz-24
      fw-400
      m-0
      TextStyle-Secondary
      (click)="routeUpcoming()"
      [ngClass]="{ routeSelected: routeOption }"
      *ngIf="isLoggedIn"
    >
      {{ translate.all.titleUp | translate }}
    </h1>
  </div>

  <main w-100>
    <router-outlet></router-outlet>
  </main>
</div>
