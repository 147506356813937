<div class="container-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-flow></app-auth-flow>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>

    <div class="content-pages">
      <app-tc-title
        w-100
        pt-24
        pb-12
        text-center
        [feature]="data.feature ?? ''"
        [key]="data.title ?? ''"
      ></app-tc-title>

      <app-tc-title
        w-100
        pb-24
        text-center
        [type]="TypesTitles.subtitleGeneral"
        [feature]="data.feature ?? ''"
        [key]="data.subtitle ?? ''"
      ></app-tc-title>

      <form [formGroup]="form">
        <app-tcinput
          formControlName="inputUsername"
          [placeholder]="placeHolder"
          [errorMessage]="{
            feature: data.feature ?? '',
            key: data.errorUsername ?? '',
          }"
          validatorKey="username"
          [styleClass]="'outline'"
          [mask]="'A*.A*'"
          [prefix]="'@'"
          [maxlength]="'20'"
        ></app-tcinput>

        <p fz-12 fw-400 m-0 pb-168 class="text-user" *ngIf="textUser">
          <app-tctext
            TextStyle
            fz-12
            fw-400
            m-0
            feature="g-user"
            key="username.textBase"
          ></app-tctext
          ><ng-container
            *ngIf="inputUsername?.value?.length > 0; else defaultText"
          >
            <strong>{{ usernameValue }}</strong>
          </ng-container>
          <ng-template #defaultText>
            <app-tctext
              TextStyle
              fz-12
              fw-400
              m-0
              feature="g-user"
              key="username.textBaseUser"
            ></app-tctext>
          </ng-template>
        </p>
        <p fz-12 fw-400 m-0 pb-168 class="text-user" *ngIf="!textUser"></p>

        <app-tcbutton
          pb-24
          [type]="TypesButton.primary"
          [disabled]="isUsernameInvalid || !inputUsername?.value || isLoading"
          (click)="onSubmit()"
        >
          <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
          <app-tctext
            [feature]="data.feature ?? ''"
            [key]="data.continue ?? ''"
          ></app-tctext>
        </app-tcbutton>
      </form>
    </div>
  </ng-container>
</div>
