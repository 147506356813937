import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { ModalService } from '@/app/libraries/core/navigation/modal.service';
import { AuthViewComponent } from '../ui/flows/auth-view/auth-view.component';
import { IconsModule } from '../../../topcars-ui/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { SelectComponent } from '../ui/components/select/select.component';
import { CountriesSelectorComponent } from '../ui/components/countries-selector/countries-selector.component';
import { CountrySelectorComponent } from '../ui/components/country-selector/country-selector.component';
import { TitleComponent } from '@/app/topcars-ui/title/title.component';

@NgModule({
  declarations: [
    LoginComponent,
    AuthViewComponent,
    SelectComponent,
    CountrySelectorComponent,
    CountriesSelectorComponent,
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    DialogModule,
    TranslateModule,
    TitleComponent,
  ],
})
export class LoginModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('signin', () =>
      import('./login.component').then((m) => m.LoginComponent),
    );
  }
}
