import { StorageHelper } from './storage-helper';
import { StorageStrategy } from './types/storage-strategy';

export class LocalStorageStrategy implements StorageStrategy {
  set<T>(key: string, value: T): void {
    localStorage.setItem(key, StorageHelper.stringify(value));
  }

  get<T>(key: string): T | null {
    return StorageHelper.parse<T>(localStorage.getItem(key));
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
