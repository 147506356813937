import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const CityDetailScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-events',
  title: 'city.title',
  responseError: 'responseErrorEventsDetail',
};

export const CityDetailScreenName = 'citydetail';
export const CITY_ID = 'cityId';
