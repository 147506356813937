import {
  Directive,
  ViewContainerRef,
  Input,
  ComponentFactoryResolver,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appDynamicComponent]',
})
export class DynamicComponentDirective implements OnInit {
  @Input() componentType: any;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(this.componentType);
    this.viewContainerRef.createComponent(componentFactory);
  }
}
