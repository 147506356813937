import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tc-city-card',
  templateUrl: './tc-city-card.component.html',
  styleUrls: ['./tc-city-card.component.scss'],
})
export class TcCityCardComponent {
  @Input() imageUrl!: string;
  @Input() cityName!: string;
  @Input() events!: string;
  isMobile = false;
  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }
}
