<app-tc-header-section
  w-100
  [title]="true"
  [subtitle]="false"
  [ctaLabel]="true"
  [ctaAction]="onCtaClick"
  [params]="data?.headerData"
>
</app-tc-header-section>

<div class="events-section">
  <p-carousel
    [value]="data.events"
    [numScroll]="1"
    [circular]="true"
    [autoplayInterval]="5000"
    [responsiveOptions]="responsiveOptions"
    styleClass="custom-carousel"
  >
    <ng-template pTemplate="item" let-event>
      <app-tc-event-hero-card
        [upcoming]="true"
        [dateTime]="event.dateTime"
        [eventTitle]="event.eventTitle"
        [eventLocation]="event.eventLocation"
        [eventInfo]="event.eventInfo"
      ></app-tc-event-hero-card>
    </ng-template>
  </p-carousel>
</div>
