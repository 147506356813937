import { Component, OnInit, Optional } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { User } from 'src/app/libraries/interfaces/http-methods/http-methods.interface';
import { HttpMethodsService } from 'src/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { ValidatorService } from 'src/app/libraries/interfaces/validator-interface/services/validator.service';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { TextService } from '@/app/design-system/atoms/text/services/text.service';
import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import {
  PATH_SIGN_EMAIL,
  PATH_SIGN_PHONE,
} from '@/app/features/authentication/constants';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';
import {
  QueryParam,
  ScreenNames,
  ScreenPresentation,
  ScreenTitleConfig,
  toolbarOptions,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';
import { TypesTitles } from '@/app/design-system/atoms/title/constants';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { SignInScreenName, SignInScreenTitleConfig } from './login.config';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(
        ':enter',
        [
          style({ transform: 'translateX({{ startPosition }})', opacity: 0 }),
          animate(
            '0.2s linear',
            style({ transform: 'translateX(0)', opacity: 1 }),
          ),
        ],
        { params: { startPosition: '100%' } },
      ),
      transition(
        ':leave',
        [
          animate(
            '0.2s linear',
            style({ transform: 'translateX({{ endPosition }})', opacity: 0 }),
          ),
        ],
        { params: { endPosition: '-100%' } },
      ),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  signInWith = 1;
  direction: 'left' | 'right' = 'left';
  placeHolder = '';
  placeHolderPhone = '';
  form!: FormGroup;
  isEmailInvalid = true;
  users: User[] = [];
  isMobile = false;
  isLoading = true;
  noWrap = false;
  params: any;
  modal = '';
  TypesTitles = TypesTitles;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  toolbarActions!: toolbarOptions;

  constructor(
    private fb: FormBuilder,
    private textService: TextService,
    private authService: AuthService,
    private validatorService: ValidatorService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.params = this.config?.data?.params;
    if (this.params) {
      this.modal = this.params['modal']
        ? window.atob(this.params['modal'])
        : '';
    }
    console.log(this.modal);

    this.setSignInScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
        this.toolbarActions = { ...config.useToolbar };
      }
    });
    this.form = this.fb.group({
      inputEmail: [null],
      phone: [null],
    });
    this.updateValidators();
    this.authService.clearStorage();
  }

  get inputEmail(): AbstractControl | null {
    return this.form.get('inputEmail');
  }
  get phone(): AbstractControl | null {
    return this.form.get('phone');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = false;
      const currentRoute = this.toolbarNavigationService.getCurrentRoute();
      this.toolbarNavigationService.addToHistory(currentRoute);
      this.getPlaceholder();
    }, 500);
  }

  async getPlaceholder() {
    this.placeHolder = await this.textService.getPlaceholder(
      this.data.feature ?? '',
      this.data.placeholder?.key ?? '',
    );
    this.placeHolderPhone = await this.textService.getPlaceholder(
      this.data.feature ?? '',
      this.data.placeholder?.keySecond ?? '',
    );
  }

  private getValidator(validatorKey: string): ValidatorFn {
    const validator = this.validatorService.getValidator(validatorKey);
    return (control: AbstractControl) => {
      const isValid = validator?.validate(control.value);
      return isValid ? null : { invalid: true };
    };
  }

  updateValidators() {
    if (this.signInWith === 1) {
      this.phone?.setValue(null);
      this.form
        .get('inputEmail')
        ?.setValidators([Validators.required, this.getValidator('email')]);
      this.form.get('phone')?.clearValidators();
    } else if (this.signInWith === 2) {
      this.inputEmail?.setValue(null);
      this.form.get('inputEmail')?.clearValidators();
      this.form
        .get('phone')
        ?.setValidators([Validators.required, this.getValidator('phone')]);
    }
    this.form.get('inputEmail')?.updateValueAndValidity();
    this.form.get('phone')?.updateValueAndValidity();
  }

  signInChange(id: number) {
    this.setSignInScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
    this.noWrap = true;
    this.direction = id > this.signInWith ? 'left' : 'right';
    this.signInWith = id;
    this.updateValidators();
    setTimeout(() => {
      this.noWrap = false;
    }, 300);
  }

  actionToDo(action: string) {
    if (action == 'clear') {
      this.inputEmail?.setValue(null);
      this.inputEmail?.updateValueAndValidity();
      this.phone?.setValue(null);
      this.phone?.updateValueAndValidity();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      let params;
      let queryParams;
      let countryCode;
      let phoneNumber;
      const regex = /^\+(\d{1,2})\s(.+)$/;

      const match = this.phone?.value
        ? this.phone?.value.match(regex)
        : undefined;

      if (match) {
        countryCode = match[1];
        phoneNumber = match[2].replace(/\s+/g, '');
        params = {
          area_code: countryCode,
          phone: phoneNumber,
        };
        queryParams = {
          flow: window.btoa(QueryParam.flowPhone),
          phone: window.btoa(this.phone?.value),
        };
      } else {
        params = {
          email: this.inputEmail?.value,
        };
        queryParams = {
          flow: window.btoa(QueryParam.flowEmail),
          email: window.btoa(this.inputEmail?.value),
        };
      }
      this.httpServicesPost(params, queryParams);
    }
  }
  httpServicesPost(params: object, queryParams: object) {
    this.isLoading = true;
    const path = this.inputEmail?.value ? PATH_SIGN_EMAIL : PATH_SIGN_PHONE;
    this.httpMethodsService.postData(path, params).subscribe({
      next: (response) => {
        if (response.status === 200 || response.status === 201) {
          this.toolbarNavigationService.navigateForwardModal(
            ScreenNames.verification,
            queryParams,
          );
          this.isLoading = false;
        }
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
        this.isLoading = false;
      },
    });
  }

  setSignInScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: SignInScreenName,
      dataText: SignInScreenTitleConfig,
      useToolbar: {
        state: true,
        close: true,
      },
      useFooter: true,
      useHeader: true,
      presentation: this.modal
        ? ScreenPresentation.Modal
        : ScreenPresentation.Wrap,
    });
  }
}
