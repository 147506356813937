<app-toolbar
  w-100
  pt-12
  *ngIf="isMobile"
  [toolbarConfig]="{
    state: true,
    close: true,
  }"
></app-toolbar>
<div class="dropdown">
  <app-tctext
    *ngIf="isMobile"
    text-center
    tcMilk
    fz-16
    feature="main-layout"
    key="header.titleLang"
    class="title"
  ></app-tctext>
  <button
    (click)="changeLanguage('en')"
    [ngClass]="{ 'lang-active': lang === 'en' }"
  >
    <app-tctext feature="main-layout" key="header.languageEn"></app-tctext>
    <app-tc-icon
      *ngIf="lang === 'en'"
      [iconId]="'check_selected'"
    ></app-tc-icon>
  </button>
  <button
    (click)="changeLanguage('es')"
    [ngClass]="{ 'lang-active': lang === 'es' }"
  >
    <app-tctext feature="main-layout" key="header.languageEs"></app-tctext>
    <app-tc-icon
      *ngIf="lang === 'es'"
      [iconId]="'check_selected'"
    ></app-tc-icon>
  </button>
</div>
