import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VerificationRoutingModule } from './verification-routing.module';
import { VerificationComponent } from './verification.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../libraries/core/navigation/modal.service';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { AuthFlowComponent } from '../ui/flows/auth-flow/auth-flow.component';
import { IconsModule } from '../../../topcars-ui/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { TitleComponent } from '@/app/topcars-ui/title/title.component';

@NgModule({
  declarations: [VerificationComponent, AuthFlowComponent],
  imports: [
    CommonModule,
    VerificationRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    IconsModule,
    TranslateModule,
    TitleComponent,
  ],
})
export class VerificationModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('verification', () =>
      import('./verification.component').then((m) => m.VerificationComponent),
    );
  }
}
