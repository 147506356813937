import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibrariesModule } from 'src/app/libraries/Libraries.module';
import { EventsAllModule } from './events-all/events-all.module';

import { EventsRoutingModule } from './events-routing.module';
import { EventsComponent } from './events.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { CityDetailComponent } from './city-detail/city-detail.component';
import { CityDetailSkeletonComponent } from './ui/flows/city-detail-skeleton/city-detail-skeleton.component';
import { EventsDetailSkeletonComponent } from './ui/flows/events-detail-skeleton/events-detail-skeleton.component';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATION_PLUGIN } from '@/app/libraries/core/translations/translation-config';
import { TitleComponent } from '@/app/topcars-ui/title/title.component';

@NgModule({
  declarations: [
    EventsComponent,
    EventDetailComponent,
    CityDetailComponent,
    CityDetailSkeletonComponent,
    EventsDetailSkeletonComponent,
  ],
  imports: [
    CommonModule,
    EventsRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    EventsAllModule,
    TranslateModule,
    TitleComponent,
  ],
  providers: [
    {
      provide: TRANSLATION_PLUGIN,
      useValue: [
        {
          module: 'events',
        },
      ],
      multi: true,
    },
  ],
})
export class EventsModule {}
