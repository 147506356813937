import { provideHotToastConfig } from '@ngxpert/hot-toast';

export const provideToast = () =>
  provideHotToastConfig({
    duration: 5000,
    position: 'top-right',
    style: {
      padding: '0',
      borderRadius: '0',
      marginTop: '5.7rem',
      marginRight: '3rem',
      backgroundColor: 'transparent',
      color: 'var(--tc-color-milk)',
    },
    icon: '',
  });
