import { Component } from '@angular/core';
import { TextService } from '../../atoms/text/services/text.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@/app/app-routing.module';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';
import { TypesButton } from '../../atoms/button/constants';
import { userData } from '@/app/features/authentication/constants';
import { NavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { LinkRedirects } from '../footer/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isDropdownOpen = false;
  isMobileMenuOpen = false;
  isMobile = false;
  isLoggedIn = false;
  dataUser: userData | null = null;
  TypesButton = TypesButton;
  LinkRedirects!: LinkRedirects;
  linkPage!: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private textService: TextService,
    public navigationService: NavigationService,
    private screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
    this.authService.dataUser$.subscribe((data) => {
      this.dataUser = data;
    });
    this.linkPage = LinkRedirects.TopCars;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  changeLanguage(language: string) {
    this.textService.setLanguage(language);
    this.isDropdownOpen = false;
  }

  goHome() {
    this.router.navigate([APP_ROUTES.EVENTS]);
  }

  signIn() {
    this.router.navigate([APP_ROUTES.AUTH]);
  }
}
