import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LibrariesModule } from 'src/app/libraries/Libraries.module';
import { CarouselModule } from 'primeng/carousel';

import { EventsAllRoutingModule } from './events-all-routing.module';
import { EventsAllComponent } from './events-all.component';
import { GeneralEventsComponent } from './pages/general-events/general-events.component';
import { EventsUpcomingComponent } from './pages/events-upcoming/events-upcoming.component';

import { TcEventCardComponent } from '../molecules/tc-event-card/tc-event-card.component';
import { TcCityCardComponent } from '../molecules/tc-city-card/tc-city-card.component';
import { TcEventHeroCardComponent } from '../molecules/tc-event-hero-card/tc-event-hero-card.component';
import { TcCardExploreEventsComponent } from '../molecules/tc-card-explore-events/tc-card-explore-events.component';

import { EventsSectionComponent } from '../sections/events-section/events-section.component';
import { CitiesSectionComponent } from '../sections/cities-section/cities-section.component';
import { EventsHeroCarouselSectionComponent } from '../sections/events-hero-carousel-section/events-hero-carousel-section.component';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { DynamicSectionDirective } from './pages/general-events/sdui/directives/dynamic-section.directive';

@NgModule({
  declarations: [
    EventsAllComponent,
    GeneralEventsComponent,
    EventsUpcomingComponent,

    TcEventCardComponent,
    TcCityCardComponent,
    TcEventHeroCardComponent,
    TcCardExploreEventsComponent,

    EventsSectionComponent,
    CitiesSectionComponent,
    EventsHeroCarouselSectionComponent,

    DynamicSectionDirective,
  ],
  imports: [
    CommonModule,
    EventsAllRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    CarouselModule,
  ],
  exports: [
    TcEventCardComponent,
    TcCityCardComponent,
    TcEventHeroCardComponent,
    TcCardExploreEventsComponent,

    EventsSectionComponent,
    CitiesSectionComponent,
    EventsHeroCarouselSectionComponent,

    DynamicSectionDirective,
  ],
})
export class EventsAllModule {}
