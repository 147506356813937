<div class="event-card">
  <div class="event-image">
    <img [src]="imageUrl" alt="Event image" />
  </div>
  <div class="event-details">
    <p class="event-org" TextStyle fz-14 fw-700 m-0>{{ eventOrganizer }}</p>
    <h3 class="event-title" fz-16 fw-700 m-0>{{ eventTitle }}</h3>
    <p class="event-location" TextStyle fz-14 fw-700 m-0>
      {{ eventLocation }}
    </p>
    <p class="event-participants" TextStyle fz-12 fw-700 m-0>
      {{
        initialDateValues.month +
          " " +
          initialDateValues.day +
          " • " +
          initialDateValues.localTime
      }}
    </p>
  </div>
</div>
