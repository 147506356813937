<div class="grid-container">
  <div class="content-pages">
    <!-- <div class="location-bar" w-100 pb-28>
      <div class="cont" (click)="openLocationModal()">
        <app-tc-icon [iconId]="'system_pin'"></app-tc-icon>
        <app-tctext
          class="text-location"
          TextStyle
          fz-12
          fw-400
          m-0
          feature="g-events"
          key="all.location"
          style="color: var(--racing)"
        ></app-tctext>
        <app-tc-icon
          class="chev-down"
          [iconId]="'system_chevron_down'"
        ></app-tc-icon>
      </div>
    </div> -->

    <ng-container *ngIf="isLoading">
      <app-general-events-skeleton w-100></app-general-events-skeleton>
    </ng-container>

    <div w-100 *ngIf="!isLoading">
      <div w-100 pt-24 pb-24 *ngIf="!isMobile">
        <app-tctext
          fz-22
          fw-500
          m-0
          TextStyle
          feature="g-events"
          key="all.title"
        ></app-tctext>
      </div>
      <div *ngFor="let section of sections">
        <ng-container [appDynamicSection]="section"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading && !isMobile">
    <div w-100 pt-24 pb-16>
      <app-tctext
        fz-22
        fw-500
        m-0
        TextStyle
        feature="g-events"
        key="all.titleUp"
      ></app-tctext>
    </div>
    <div
      class="upcoming-side"
      [ngClass]="{ backgroundEb: upcoming.length < 1 }"
    >
      <ng-container *ngIf="upcoming.length < 1">
        <app-tc-icon [iconId]="'system_grid_stack'"></app-tc-icon>
        <app-tctext
          fz-16
          fw-400
          m-0
          text-center
          TextStyle-L
          feature="g-events"
          key="all.textUpcoming"
        ></app-tctext>
      </ng-container>
      <div w-100 *ngFor="let section of upcoming">
        <ng-container [appDynamicSection]="section"></ng-container>
      </div>
    </div>
  </div>
</div>

<app-select-location
  #selectLocation
  [type]="1"
  (confirmModal)="handleconfirmlocationModal($event)"
>
</app-select-location>
