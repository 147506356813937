import { ALL_EVENTS_ROUTES } from '../events-all/events-all-routing.module';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import {
  AUTO,
  EVENT_ID,
  EventResponse,
  EventsDetailScreenName,
  EventsDetailScreenTitleConfig,
  HIDDEN,
} from './event-detail.config';
import { TypesTitles } from '@/app/design-system/atoms/title/constants';
import { forkJoin, Subscription } from 'rxjs';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { PATH_EVENTS, PATH_INTERESTED } from '../constants';
import { userData } from '../../authentication/constants';
import { TextService } from '@/app/design-system/atoms/text/services/text.service';
import { dateFormat } from '@/app/libraries/dates/date.interface';
import { DateService } from '@/app/libraries/dates/services/date.service';
import { NavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  isMobile = false;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  isExpanded = false;
  TypesTitles = TypesTitles;
  isLoading = true;
  eventId!: string;
  eventDetail!: EventResponse;
  dataUser: userData | null = null;
  initialDateValues!: dateFormat;
  finalDateValues!: dateFormat;
  private languageSubscription!: Subscription;
  @HostListener('document:keydown.escape')
  onEscape() {
    if (this.isExpanded) {
      this.toggleExpand();
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public textService: TextService,
    private dateService: DateService,
    private authService: AuthService,
    public navigationService: NavigationService,
    private errorModalService: ErrorModalService,
    private screenSizeService: ScreenSizeService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setEventsDetailScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  ngOnInit() {
    this.getData();

    this.languageSubscription = this.authService.languageChanged$.subscribe(
      () => {
        this.getData();
      },
    );
  }

  getData() {
    this.eventId = this.route.snapshot.paramMap.get(EVENT_ID) ?? 'null';
    const path = PATH_EVENTS + `${this.eventId}/`;
    forkJoin({
      eventDetail: this.httpMethodsService.getData(path, {}),
    }).subscribe({
      next: (response: any) => {
        this.eventDetail = response.eventDetail?.body;
        this.dataUser = {
          id: this.eventDetail.organizer?.id,
          username: this.eventDetail.organizer?.username,
          profile_picture: this.eventDetail.organizer?.profile_picture,
          is_verified: this.eventDetail.organizer?.is_verified,
        };
        this.initialDateValues = this.dateService.convertToLocalDate(
          this.eventDetail?.initial_date,
        );
        this.finalDateValues = this.dateService.convertToLocalDate(
          this.eventDetail?.final_date,
        );

        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
        //window.location.href = 'https://topcars.io';
      },
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      document.body.style.overflow = HIDDEN;
    } else {
      document.body.style.overflow = AUTO;
    }
  }

  httpServicesPost() {
    this.isLoading = true;
    const params = {
      interested: true,
    };
    const path = `${PATH_EVENTS}${this.eventId}${PATH_INTERESTED}`;
    this.httpMethodsService.postData(path, params).subscribe({
      next: (response) => {
        if (response.status === 200 || response.status === 201) {
          this.isLoading = false;
        }
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
        this.isLoading = false;
      },
    });
  }

  redirectToGoogleCalendar() {
    const eventTitle = 'Reunión importante';
    const startDate = '20240901T100000Z';
    const endDate = '20240901T110000Z';
    const details = 'Reunión para discutir el proyecto';
    const location = 'Oficina Central';

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventTitle)}
      &dates=${startDate}/${endDate}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(location)}&sf=true&output=xml`;

    window.open(googleCalendarUrl, '_blank');
  }

  openGoogleMaps(latitude: number, longitude: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  }

  return() {
    this.router.navigate([ALL_EVENTS_ROUTES.EVENTS_UPCOMING]);
  }

  setEventsDetailScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: EventsDetailScreenName,
      dataText: EventsDetailScreenTitleConfig,
      useToolbar: {
        state: false,
      },
      useFooter: this.isMobile ? false : true,
      useHeader: true,
      presentation: ScreenPresentation.Narrow,
    });
  }
}
