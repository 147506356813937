<div class="container-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-flow-user></app-auth-flow-user>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>

    <div class="content-pages">
      <h1
        m-0
        w-100
        pt-24
        pb-12
        text-center
        tc-title
        [desktopMode]="!isMobile"
        [tcTitle]="TypesTitles.GENERAL"
        [innerHTML]="translate.username.title | translate"
      ></h1>

      <h3
        m-0
        w-100
        pb-24
        text-center
        tc-title
        [desktopMode]="!isMobile"
        [tcTitle]="TypesTitles.SUBTITLE_GENERAL"
        [innerHTML]="translate.username.subtitle | translate"
      ></h3>

      <form [formGroup]="form">
        <app-tcinput
          formControlName="inputUsername"
          [placeholder]="translate.username.userPlaceHinput | translate"
          [errorMessage]="translate.username.errorMessage | translate"
          validatorKey="username"
          [styleClass]="'outline'"
          [mask]="'A*.A*'"
          [prefix]="'@'"
          [maxlength]="'20'"
        ></app-tcinput>

        <p d-flex tcGhost fz-12 fw-400 m-0 pb-168 *ngIf="textUser">
          <span>{{ translate.username.textBase | translate }}</span>
          <ng-container
            *ngIf="inputUsername?.value?.length > 0; else defaultText"
          >
            <strong>{{ usernameValue }}</strong>
          </ng-container>
          <ng-template #defaultText>
            {{ translate.username.textBaseUser | translate }}
          </ng-template>
        </p>
        <p fz-12 fw-400 m-0 pb-168 class="text-user" *ngIf="!textUser"></p>

        <app-tcbutton
          pb-24
          [type]="TypesButton.PRIMARY"
          [disabled]="isUsernameInvalid || !inputUsername?.value || isLoading"
          (click)="onSubmit()"
        >
          <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
          {{ translate.username.continue | translate }}
        </app-tcbutton>
      </form>
    </div>
  </ng-container>
</div>
