import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-edit-picture',
  templateUrl: './edit-picture.component.html',
  styleUrls: ['./edit-picture.component.scss'],
})
export class EditPictureComponent implements OnChanges {
  @ViewChild(Dialog) dialog!: Dialog;
  @Output() takePhoto = new EventEmitter<void>();
  @Output() closeModalPhoto = new EventEmitter<void>();
  @Output() importFromGallery = new EventEmitter<void>();
  @Output() removeCurrentPicture = new EventEmitter<void>();
  @Input() imageUploaded = false;

  displayModal = false;
  modalClosing = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageUploaded']) {
      const nuevoValor = changes['imageUploaded']?.currentValue;
      this.imageUploaded = nuevoValor;
    }
  }

  openModal() {
    this.displayModal = true;
    this.modalClosing = false;
  }
  closeModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
    this.closeModalPhoto.emit();
  }

  onTakePhoto() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
    this.takePhoto.emit();
  }

  onImportFromGallery() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
    this.importFromGallery.emit();
  }

  onRemoveCurrentPicture() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
    this.removeCurrentPicture.emit();
  }
}
