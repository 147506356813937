<div class="container-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-flow [profile]="true"></app-auth-flow>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>
    <div class="content-pages">
      <app-tc-title
        w-100
        pt-32
        pb-12
        text-center
        [feature]="data.feature ?? ''"
        [key]="data.title ?? ''"
      ></app-tc-title>

      <app-tc-title
        w-100
        pb-60
        text-center
        [type]="TypesTitles.subtitleGeneral"
        [feature]="data.feature ?? ''"
        [key]="data.subtitle ?? ''"
      ></app-tc-title>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="photo-upload-container">
          <div class="photo-preview">
            <img
              [src]="imagePreview ? imagePreview : system_image_default"
              alt="photo-preview"
            />
          </div>
          <div class="photo-upload">
            <button
              type="button"
              (click)="
                !inputImage?.value
                  ? openImageUploadModal()
                  : handleRemoveCurrentPicture()
              "
              [ngClass]="{ 'image-times': inputImage?.value }"
            >
              <app-tc-icon
                class="button"
                *ngIf="!inputImage?.value"
                [iconId]="'system_plus_circle'"
              ></app-tc-icon>
              <app-tc-icon
                class="button"
                *ngIf="inputImage?.value"
                [iconId]="'system_cross_image'"
              ></app-tc-icon>
            </button>
            <input
              #fileInput
              type="file"
              (change)="onFileChange($event)"
              accept="image/*"
              style="display: none"
            />
          </div>
        </div>
        <span class="username" fz-16 fw-700 pb-32
          >{{ username }}
          <app-tc-icon
            class="icon-edit"
            [iconId]="'system_edit'"
            (click)="return()"
          ></app-tc-icon>
        </span>

        <app-tcinput
          pb-16
          icon="system_user"
          appLettersOnly
          formControlName="inputFullname"
          [titleInput]="'Full name'"
          [placeholder]="placeHolder"
          [errorMessage]="{
            feature: data.feature ?? '',
            key: data.errorFullname ?? '',
          }"
          validatorKey="name"
          [maxlength]="'50'"
          (action)="actionToDo($event, 'name')"
        ></app-tcinput>

        <app-tcinput
          pb-120
          icon="system_calendar_dates"
          appNumbersOnly
          formControlName="inputBirthdate"
          [mask]="'00/00/0000'"
          [titleInput]="'Birthdate'"
          [placeholder]="placeHolderB"
          [errorMessage]="{
            feature: data.feature ?? '',
            key: data.errorBirthdate ?? '',
          }"
          validatorKey="birthdate"
          [maxlength]="'10'"
          (action)="actionToDo($event, 'birthdate')"
        ></app-tcinput>

        <app-tcbutton
          pb-24
          [type]="TypesButton.primary"
          [disabled]="!this.form.valid || isLoading"
          (click)="onSubmit(); $event.preventDefault()"
        >
          <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
          <app-tctext
            [feature]="data.feature ?? ''"
            [key]="data.continue ?? ''"
          ></app-tctext>
        </app-tcbutton>
      </form>
    </div>
  </ng-container>
</div>

<app-edit-picture
  #editPicture
  [imageUploaded]="imageUploaded"
  (takePhoto)="handleTakePhoto()"
  (closeModalPhoto)="handlecloseModal()"
  (importFromGallery)="handleImportFromGallery()"
  (removeCurrentPicture)="handleRemoveCurrentPicture()"
>
</app-edit-picture>

<app-permissions
  #permissions
  [type]="1"
  (confirmModal)="handleConfirmPermissionsModal($event)"
>
</app-permissions>
