import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CarouselModule } from 'primeng/carousel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { LibrariesModule } from '../libraries/Libraries.module';

import { TCButtonComponent } from './atoms/button/tcbutton.component';
import { TcCtaComponent } from './atoms/cta/tc-cta.component';
import { TcIconComponent } from './atoms/icon/tc-icon.component';
import { TCInputComponent } from './atoms/input/tcinput.component';
import { OtpInputComponent } from './atoms/otp-input/otp-input.component';

import { TcHeaderComponent } from './molecules/header-section/tc-header.component';
import { TcIdentityComponent } from './molecules/identity/tc-identity.component';
import { ToolbarComponent } from '../topcars-ui/toolbar/toolbar.component';
import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';

import { ModalService } from '../libraries/core/navigation/modal.service';
import { AuthFlowComponent } from '../features/user-profile/ui/flows/auth-flow/auth-flow.component';
import { TranslateModule } from '@ngx-translate/core';
import { TitleComponent } from '../topcars-ui/title/title.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    TCButtonComponent,
    TcCtaComponent,
    TcIconComponent,
    TCInputComponent,
    OtpInputComponent,

    ToolbarComponent,
    TcHeaderComponent,
    TcIdentityComponent,

    LoadingSkeletonComponent,
    AuthFlowComponent,
  ],
  imports: [
    CommonModule,
    LibrariesModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    CarouselModule,
    DynamicDialogModule,
    TranslateModule,
    TitleComponent,
  ],
  exports: [
    TCButtonComponent,
    TcCtaComponent,
    TcIconComponent,
    TCInputComponent,
    OtpInputComponent,

    ToolbarComponent,
    TcHeaderComponent,
    TcIdentityComponent,

    LoadingSkeletonComponent,
    AuthFlowComponent,
  ],
})
export class DesignSystemModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('countrySelector', () =>
      import(
        '../features/authentication/ui/components/country-selector/country-selector.component'
      ).then((m) => m.CountrySelectorComponent),
    );
  }
}
