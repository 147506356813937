<div>
  <ng-container *ngIf="isLoading">
    <app-events-detail-skeleton w-100></app-events-detail-skeleton>
  </ng-container>

  <!-- <app-toolbar
    w-100
    [toolbarConfig]="{
      state: true,
      close: false,
      actions: true
    }"
    *ngIf="!isLoading"
  ></app-toolbar> -->

  <div class="event-container" *ngIf="!isLoading">
    <!-- <div class="returnIcon">
      <app-tc-icon [iconId]="'system_arrow_back'" (click)="return()"></app-tc-icon>
    </div> -->
    <div class="event-layout">
      <!-- left column (40%) -->
      <div class="left-column">
        <div class="box1">
          <!-- Image content -->
          <div
            class="image-container"
            [class.expanded]="isExpanded"
            (click)="toggleExpand()"
          >
            <img [src]="eventDetail.image" alt="event-image" loading="lazy" />
            <div class="overlay">
              <span *ngIf="!isExpanded" class="expand-text"
                >Click to expand</span
              >
            </div>
            <button
              *ngIf="isExpanded"
              class="close-button"
              (click)="toggleExpand(); $event.stopPropagation()"
              aria-label="Close expanded image"
            >
              <app-tc-icon
                [iconId]="'system_times'"
                class="close-icon-expanded"
              ></app-tc-icon>
            </button>
          </div>
        </div>

        <div class="box4">
          <app-tcbutton
            [type]="TypesButton.PRIMARY"
            (click)="httpServicesPost()"
            [disabled]="eventDetail.interested"
          >
            <ng-container *ngIf="!eventDetail.interested; else default">
              {{ translate.events.RSVP | translate }}
            </ng-container>
            <ng-template #default>
              {{ translate.events.interested | translate }}
            </ng-template>
          </app-tcbutton>
        </div>

        <div class="box5" *ngIf="eventDetail.faces.length > 0">
          <!-- attendees content -->
          <ng-container>
            <p
              m-0
              fz-16
              tcMilk
              [ngStyle]="{
                'font-family': !isMobile ? 'Red-Hat-Bold' : 'Red-Hat-Regular',
                display: 'inline',
              }"
            >
              {{ eventDetail.interested_count + " " }}
            </p>
            <h3
              m-0
              tcMilk
              tc-title
              [desktopMode]="!isMobile"
              [tcTitle]="TypesTitles.SECTION"
              [innerHTML]="translate.events.peopleGoing | translate"
              [ngStyle]="{ display: 'inline-block' }"
            ></h3>
          </ng-container>
          <div class="attendees" w-100>
            <ng-container *ngFor="let attendee of attendees">
              <app-tc-icon
                *ngIf="attendee; else defaultIcon"
                [iconId]="attendee"
                class="attendee-avatar"
              ></app-tc-icon>
              <ng-template #defaultIcon>
                <app-tc-icon
                  [iconId]="'system_avatar'"
                  class="attendee-avatar"
                ></app-tc-icon>
              </ng-template>
            </ng-container>
          </div>
        </div>

        <div class="box6">
          <!-- location content -->
          <h3
            m-0
            w-100
            tcMilk
            tc-title
            [desktopMode]="!isMobile"
            [tcTitle]="TypesTitles.SECTION"
            [innerHTML]="translate.events.location | translate"
          ></h3>
          <div class="event-details">
            <img
              [src]="eventDetail.address.static_image"
              class="map-image"
              alt="map-image"
              loading="lazy"
              (click)="
                openGoogleMaps(
                  eventDetail.address.latitude,
                  eventDetail.address.longitude
                )
              "
            />
            <app-tc-icon
              class="icon-pin"
              [iconId]="'system_pin_default'"
              (click)="
                openGoogleMaps(
                  eventDetail.address.latitude,
                  eventDetail.address.longitude
                )
              "
            ></app-tc-icon>
          </div>
        </div>
      </div>

      <!-- right column (60%) -->
      <div class="right-column">
        <div class="box2">
          <!-- header content -->
          <div class="event-header">
            <app-tc-identity
              *ngIf="eventDetail.organizer"
              [userData]="dataUser"
            ></app-tc-identity>
            <h3
              *ngIf="!eventDetail.organizer && eventDetail.external_organizer"
              TextStyle
              w-100
              fz-16
              fw-400
              m-0
              tcAsh
              class="organizer-name"
            >
              {{ eventDetail.external_organizer }}
            </h3>
            <!-- <div d-flex style="gap: 20px">
              <app-tc-icon
                fz-24
                (click)="redirectToGoogleCalendar()"
                [iconId]="'system_calendar_dates'"
              ></app-tc-icon>
              <app-tc-icon
                fz-24
                [iconId]="'system_share_light'"
                ></app-tc-icon>
            </div> -->
          </div>
          <h3 w-100 m-0 tcMilk fw-500 fz-36 TextStyle class="event-name">
            {{ eventDetail.name }}
          </h3>
          <div
            *ngIf="eventDetail.url"
            class="url-container"
            style="margin-top: var(--size-8)"
          >
            <app-tcbutton
              [type]="TypesButton.ROUND_SMOKE"
              icon="system_link_ghost"
              [round]="true"
              tcGhost
              (click)="navigationService.navigateToUrl(eventDetail.url)"
            >
              <p class="url-text">
                {{ eventDetail.url }}
              </p>
            </app-tcbutton>
          </div>
        </div>

        <div class="box3">
          <!-- event info content -->
          <h3
            m-0
            w-100
            tcMilk
            tc-title
            [desktopMode]="!isMobile"
            [tcTitle]="TypesTitles.SECTION"
            [innerHTML]="translate.events.details | translate"
          ></h3>
          <app-info-card
            [iconId]="'system_calendar_dates'"
            [date]="
              initialDateValues.dayOfWeek +
              ', ' +
              initialDateValues.month +
              ' ' +
              initialDateValues.day +
              'th'
            "
            [time]="
              initialDateValues.localTime + ' - ' + finalDateValues.localTime
            "
          >
          </app-info-card>
          <app-info-card
            [iconId]="'system_ubication'"
            [date]="''"
            [time]="eventDetail.address.formatted_address"
          >
          </app-info-card>
        </div>

        <div class="box7">
          <!-- description component -->
          <h3
            m-0
            w-100
            tcMilk
            tc-title
            [desktopMode]="!isMobile"
            [tcTitle]="TypesTitles.SECTION"
            [innerHTML]="translate.events.message | translate"
          ></h3>
          <p
            w-100
            fz-14
            m-0
            tcMilk
            class="justified-text"
            class="description-text"
            [innerHTML]="textService.highlightLinks(eventDetail.description)"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>
