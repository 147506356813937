import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component } from '@angular/core';
import { DESKTOP_SKELETON_COUNT, MOBILE_SKELETON_COUNT } from './constants';

@Component({
  selector: 'app-general-events-skeleton',
  templateUrl: './general-events-skeleton.component.html',
  styleUrls: ['./general-events-skeleton.component.scss'],
})
export class GeneralEventsSkeletonComponent {
  isMobile = false;
  MOBILE_SKELETON_COUNT = MOBILE_SKELETON_COUNT;
  DESKTOP_SKELETON_COUNT = DESKTOP_SKELETON_COUNT;
  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }
}
