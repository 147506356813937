<div class="event-container">
  <div class="event-layout">
    <!-- left column (40%) -->
    <div class="left-column">
      <div class="box1">
        <!-- Image content -->
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'image'"
          [count]="1"
        ></app-loading-skeleton>
      </div>

      <!-- <div class="box4">       
      </div> -->

      <div class="box5">
        <!-- attendees content -->
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'label'"
          [count]="1"
        ></app-loading-skeleton>
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'avatar'"
          [count]="1"
        ></app-loading-skeleton>
      </div>

      <div class="box6">
        <!-- location content -->
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'label'"
          [count]="1"
        ></app-loading-skeleton>
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'image-alt'"
          [count]="1"
          style="height: 15rem"
        ></app-loading-skeleton>
      </div>
    </div>

    <!-- right column (60%) -->
    <div class="right-column">
      <div class="box2">
        <!-- header content -->
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'form-label'"
          [count]="1"
        ></app-loading-skeleton>
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'form'"
          [count]="1"
        ></app-loading-skeleton>
      </div>

      <div class="box3">
        <!-- event info content -->
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'card-slim'"
          [count]="2"
        ></app-loading-skeleton>
      </div>

      <div class="box7">
        <!-- description component -->
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'label'"
          [count]="1"
        ></app-loading-skeleton>
        <app-loading-skeleton
          w-100
          pb-28
          [type]="'image'"
          [count]="1"
          style="height: 15rem"
        ></app-loading-skeleton>
      </div>
    </div>
  </div>
</div>
