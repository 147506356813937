import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';
import { Component } from '@angular/core';
import { EVENTS_ROUTES } from '../../events-routing.module';

@Component({
  selector: 'app-upcoming-section',
  templateUrl: './upcoming-section.component.html',
  styleUrls: ['./upcoming-section.component.scss'],
})
export class UpcomingSectionComponent {
  data: any;

  constructor(public toolbarNavigationService: ToolbarNavigationService) {}

  eventDetail(id: string) {
    this.toolbarNavigationService.navigateToNewTab([
      `${EVENTS_ROUTES.EVENTS}/${id}`,
    ]);
  }

  onCtaClick() {
    console.log('CTA clicked from parent!');
  }
}
