<div #contentPages class="content-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-view-auth w-100></app-auth-view-auth>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state && !!modal"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>

    <div style="width: 100%; display: flex" [ngClass]="{ noWrap: noWrap }">
      <div
        class="signInwith"
        *ngIf="signInWith === 1"
        [@slideInOut]="{
          value: '',
          params: { startPosition: direction === 'left' ? '100%' : '-100%' },
        }"
      >
        <h1
          m-0
          w-100
          pt-24
          pb-12
          tc-title
          [desktopMode]="!isMobile"
          [tcTitle]="TypesTitles.GENERAL"
          [innerHTML]="translate.login.signEmail | translate"
        ></h1>
        <h3
          m-0
          w-100
          pb-24
          tc-title
          [desktopMode]="!isMobile"
          [tcTitle]="TypesTitles.SUBTITLE_GENERAL"
          [innerHTML]="translate.login.signEmailSecond | translate"
        ></h3>
      </div>

      <div
        class="signInwith"
        *ngIf="signInWith === 2"
        [@slideInOut]="{
          value: '',
          params: { endPosition: direction === 'right' ? '-100%' : '100%' },
        }"
      >
        <h1
          m-0
          w-100
          pt-24
          pb-12
          tc-title
          [desktopMode]="!isMobile"
          [tcTitle]="TypesTitles.GENERAL"
          [innerHTML]="translate.phone.title | translate"
        ></h1>
        <h3
          m-0
          w-100
          pb-24
          tc-title
          [desktopMode]="!isMobile"
          [tcTitle]="TypesTitles.SUBTITLE_GENERAL"
          [innerHTML]="translate.phone.subtitle | translate"
        ></h3>
      </div>
    </div>

    <form [formGroup]="form">
      <app-tcinput
        pb-24
        icon="system_email"
        formControlName="inputEmail"
        [placeholder]="translate.login.signPlaceholderInput | translate"
        [errorMessage]="translate.login.errorMessage | translate"
        type="email"
        validatorKey="email"
        [maxlength]="'50'"
        (action)="actionToDo($event)"
        *ngIf="signInWith === 1"
        autocomplete="username"
      ></app-tcinput>

      <app-select
        pb-24
        icon="system_country_selector"
        iconSecondary="system_chevron_down_alt"
        type="text"
        [valueIn]="country?.value"
        [placeholder]="translate.phone.countrySelectorPlaceholder | translate"
        [placeholderSelector]="translate.phone.countrySearch | translate"
        (value)="countryValue($event)"
        *ngIf="signInWith === 2"
      ></app-select>
      <app-tcinput
        pb-24
        [type]="'tel'"
        icon="system_phone"
        formControlName="phone"
        type="tel"
        [placeholder]="translate.phone.placeholder | translate"
        validatorKey="phone"
        (action)="actionToDo($event)"
        [mask]="'000 000 0000'"
        [maxlength]="'12'"
        [errorMessage]="translate.phone.errorMessage | translate"
        *ngIf="signInWith === 2"
        autocomplete="tel"
      ></app-tcinput>

      <app-tcbutton
        pb-24
        [type]="TypesButton.PRIMARY"
        [disabled]="!form.valid || isLoading"
        (click)="onSubmit()"
      >
        <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
        {{ translate.login.continue | translate }}
      </app-tcbutton>
    </form>

    <h2 fz-16 pb-24 m-0>
      {{ translate.login.options | translate }}
    </h2>

    <app-tcbutton
      *ngIf="signInWith === 2"
      pb-104
      [type]="TypesButton.SECONDARY"
      [disabled]="isLoading"
      (click)="signInChange(1)"
    >
      {{ translate.login.signEmail | translate }}
    </app-tcbutton>

    <app-tcbutton
      *ngIf="signInWith === 1"
      pb-104
      [type]="TypesButton.SECONDARY"
      [disabled]="isLoading"
      (click)="signInChange(2)"
    >
      {{ translate.login.signPhone | translate }}
    </app-tcbutton>

    <p
      w-100
      fz-12
      pb-24
      class="foot"
      [innerHTML]="translate.login.signFooter | translate"
    ></p>
  </ng-container>
</div>
