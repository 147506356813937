<div class="section-container">
  <div class="section-item">
    <div class="section-content">
      <app-tc-title
        *ngIf="title"
        [type]="TypesTitles.section"
        [desktopMode]="!isMobile"
        [feature]="data.feature ?? ''"
        [key]="data.title ?? ''"
      ></app-tc-title>
    </div>
    <app-tc-cta
      *ngIf="ctaLabel"
      [desktopMode]="!isMobile"
      [feature]="data.feature ?? ''"
      [key]="data.CTA ?? ''"
    ></app-tc-cta>
  </div>
  <app-tc-title
    *ngIf="subtitle"
    [type]="TypesTitles.section"
    [desktopMode]="!isMobile"
    [feature]="data.feature ?? ''"
    [key]="data.subtitle ?? ''"
  ></app-tc-title>
</div>
