import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';

export const USER_ROUTES = {
  AddEmail: 'user/add-email',
  Username: 'user/claim-username',
  Profile: 'user/profile',
};

const routes: Routes = [
  {
    path: '',
    component: UserProfileComponent,
    children: [
      {
        path: 'add-email',
        loadChildren: () =>
          import('./add-email/add-email.module').then((m) => m.AddEmailModule),
      },
      {
        path: 'claim-username',
        loadChildren: () =>
          import('./username/username.module').then((m) => m.UsernameModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: '',
        redirectTo: 'claim-username',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
