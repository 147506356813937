import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { textI18n } from './interfaces';
import { TypesButton } from '../../atoms/button/constants';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Input() display = false;
  @Input() errorMessage!: textI18n;
  @Input() title = '';
  TypesButton = TypesButton;

  @Output() Close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  hide() {
    this.display = false;
    this.Close.emit(false);
  }

  confirm() {
    this.display = false;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.Close.emit(true);
    }, 300);
  }
}
