import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { Component } from '@angular/core';
import { Section } from '../../../events-all/pages/general-events/types';

@Component({
  selector: 'app-cities-section',
  templateUrl: './cities-section.component.html',
  styleUrls: ['./cities-section.component.scss'],
})
export class CitiesSectionComponent {
  data!: Section;

  constructor(public toolbarNavigationService: NavigationService) {}

  cityDetail(id: string) {
    this.toolbarNavigationService.navigateForward(
      [`${EVENTS_ROUTES.Events}${EVENTS_ROUTES.CitiesDetail}${id}`],
      {},
    );
  }
  onCtaClick() {
    console.log('CTA clicked from parent!');
  }
}
