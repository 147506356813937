import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const SignInScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-auth',
  titleEmail: 'login.signEmail',
  titlePhone: 'phone.title',
  subtitleEmail: 'login.signEmailSecond',
  subtitlePhone: 'phone.subtitle',
  placeholder: {
    key: 'login.signPlaceHInput',
    keySecond: 'phone.placeholder',
    keyTertiary: 'phone.countrySearch',
    keyQuaternary: 'phone.countrySelector',
  },
  continue: 'login.continue',
  errorEmail: 'login.errorMessage',
  errorPhone: 'phone.errorMessage',
  errorPost: 'login.errorPost',
  responseError: 'responseError',
};

export const SignInScreenName = 'signin';
export const EMAIL = 'email';
export const PHONE = 'phone';
