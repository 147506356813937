<div style="display: flex; flex-direction: column">
  <app-loading-skeleton
    w-100
    [type]="'label'"
    [count]="1"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    pb-28
    [type]="'circle'"
    [count]="4"
  ></app-loading-skeleton>

  <app-loading-skeleton
    w-100
    [type]="'label'"
    [count]="1"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    pb-28
    [type]="'card'"
    [count]="3"
    style="padding: 0 0 var(--size-24) 0"
  ></app-loading-skeleton>
</div>
