import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LibrariesModule } from 'src/app/libraries/Libraries.module';
import { CarouselModule } from 'primeng/carousel';

import { EventsAllRoutingModule } from './events-all-routing.module';
import { EventsAllComponent } from './events-all.component';
import { GeneralEventsComponent } from './pages/general-events/general-events.component';
import { EventsUpcomingComponent } from './pages/events-upcoming/events-upcoming.component';

import { TcEventCardComponent } from '../ui/molecules/tc-event-card/tc-event-card.component';
import { TcCityCardComponent } from '../ui/molecules/tc-city-card/tc-city-card.component';
import { TcEventHeroCardComponent } from '../ui/molecules/tc-event-hero-card/tc-event-hero-card.component';
import { TcCardExploreEventsComponent } from '../ui/molecules/tc-card-explore-events/tc-card-explore-events.component';
import { TcBackgroundComponent } from '../ui/molecules/tc-background/tc-background.component';
import { TcUpcomingCardComponent } from '../ui/molecules/tc-upcoming-card/tc-upcoming-card.component';
import { InfoCardComponent } from '../ui/molecules/info-card/info-card.component';

import { EventsSectionComponent } from '../ui/sections/events-section/events-section.component';
import { CitiesSectionComponent } from '../ui/sections/cities-section/cities-section.component';
import { UpcomingSectionComponent } from '../ui/sections/upcoming-section/upcoming-section.component';
import { EventsHeroCarouselSectionComponent } from '../ui/sections/events-hero-carousel-section/events-hero-carousel-section.component';

import { SelectLocationComponent } from '../ui/modals/select-location/select-location.component';

import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { DynamicSectionDirective } from '@/app/libraries/events/sdui/directives/dynamic-section.directive';
import { ComponentRegistryService } from '@/app/libraries/events/sdui/services/component-registry.service';
import {
  CITY_BACK_SECTION,
  EVENTS_CITIES,
  EVENTS_SECTION,
  EVENTS_UPCOMING,
} from '@/app/libraries/events/sdui/sdui-section.interface';
import { GeneralEventsSkeletonComponent } from '../ui/flows/general-events-skeleton/general-events-skeleton.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    EventsAllComponent,
    GeneralEventsComponent,
    EventsUpcomingComponent,

    TcEventCardComponent,
    TcCityCardComponent,
    TcEventHeroCardComponent,
    TcCardExploreEventsComponent,
    TcBackgroundComponent,
    TcUpcomingCardComponent,
    InfoCardComponent,

    EventsSectionComponent,
    CitiesSectionComponent,
    UpcomingSectionComponent,
    EventsHeroCarouselSectionComponent,

    SelectLocationComponent,

    DynamicSectionDirective,
    GeneralEventsSkeletonComponent,
  ],
  imports: [
    CommonModule,
    EventsAllRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    CarouselModule,
    DialogModule,
    TranslateModule,
  ],
  exports: [
    TcEventCardComponent,
    TcCityCardComponent,
    TcEventHeroCardComponent,
    TcCardExploreEventsComponent,
    TcBackgroundComponent,
    TcUpcomingCardComponent,
    InfoCardComponent,

    EventsSectionComponent,
    CitiesSectionComponent,
    UpcomingSectionComponent,
    EventsHeroCarouselSectionComponent,

    DynamicSectionDirective,
  ],
})
export class EventsAllModule {
  constructor(private componentRegistryService: ComponentRegistryService) {
    this.componentRegistryService.register(
      EVENTS_CITIES,
      CitiesSectionComponent,
    );
    this.componentRegistryService.register(
      EVENTS_SECTION,
      EventsSectionComponent,
    );
    this.componentRegistryService.register(
      EVENTS_UPCOMING,
      UpcomingSectionComponent,
    );
    this.componentRegistryService.register(
      CITY_BACK_SECTION,
      TcBackgroundComponent,
    );
  }
}
