<ng-container>
  <div pb-28 class="grid-2-columns">
    <app-tc-upcoming-card
      *ngFor="let event of data.events"
      w-100
      [imageUrl]="event.imageUrl"
      [eventOrganizer]="event.label"
      [eventTitle]="event.title"
      [eventLocation]="event.subtitle"
      [eventTime]="{
        initial_date: event.initialDate,
        final_date: event.finalDate,
      }"
      (click)="eventDetail(event.id)"
    ></app-tc-upcoming-card>
  </div>
</ng-container>
