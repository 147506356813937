import { ModalService } from '@/app/libraries/core/navigation/modal.service';
import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Navigation, HISTORY_NAME_KEY } from './toolbar.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements Navigation {
  private history: string[] = [];
  private isNavigatingBack = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
  ) {
    const savedHistory = sessionStorage.getItem(HISTORY_NAME_KEY);
    this.history = savedHistory ? JSON.parse(savedHistory) : [];

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        if (
          event instanceof NavigationStart ||
          event instanceof NavigationEnd
        ) {
          if (!this.isNavigatingBack) {
            this.addToHistory(event.url);
          }
        }
      });
  }

  public addToHistory(url: string, reset?: boolean): void {
    if (reset) {
      this.history = [];
      sessionStorage.removeItem(HISTORY_NAME_KEY);
    }

    if (
      this.history.length === 0 ||
      this.history[this.history.length - 1] !== url
    ) {
      this.history.push(url);
      sessionStorage.setItem(HISTORY_NAME_KEY, JSON.stringify(this.history));
    }
  }

  public back(): void {
    if (this.history.length > 1) {
      this.isNavigatingBack = true;
      this.history.pop();
      const previousUrl = this.history[this.history.length - 1];

      if (previousUrl) {
        this.router.navigateByUrl(previousUrl);
        this.isNavigatingBack = false;
      } else {
        window.history.back();
        this.isNavigatingBack = false;
      }

      sessionStorage.setItem(HISTORY_NAME_KEY, JSON.stringify(this.history));
    } else {
      window.history.back();
    }
  }

  public getCurrentRoute(): string {
    return this.router.url;
  }

  public clearHistory(): void {
    this.history = [];
    sessionStorage.removeItem(HISTORY_NAME_KEY);
  }

  public navigateForward(
    route: string[],
    queryParams: object,
    relativeTo: ActivatedRoute | null = null,
    skipLocationChange = false,
  ) {
    this.router.navigate(route, {
      queryParams: queryParams,
      relativeTo: relativeTo,
      skipLocationChange: skipLocationChange,
    });
  }

  public async navigateForwardModal(path: string, queryParams: object) {
    const result = await this.modalService.openModal(path, queryParams);

    if (result) {
      return result;
    }
  }

  public navigateToNewTab(route: string[]) {
    const url = this.router.createUrlTree(route).toString();
    window.open(url, '_blank');
  }
}
