import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-external-url',
  templateUrl: './modal-external-url.component.html',
  styleUrls: ['./modal-external-url.component.scss'],
})
export class ModalExternalUrlComponent {
  displayModal = false;
  modalClosing = false;
  modalTitle = null;
  url = null;
  externalUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.externalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.url ? this.url : '',
    );
  }

  openModal() {
    this.displayModal = true;
    this.modalClosing = false;
  }
  closeModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
  }
}
