import { Component, Input, Optional } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { ModalService } from '@/app/libraries/main-layout/services/modal.service';
import { ToolbarNavigationService } from './toolbar-navigation.service';
import { APP_ROUTES } from '@/app/app-routing.module';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() toolbarConfig!: any;
  isMobile = false;

  constructor(
    private modalService: ModalService,
    private screenSizeService: ScreenSizeService,
    @Optional() private dialogRef: DynamicDialogRef,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  public closeOrGoBack() {
    if (this.toolbarConfig.close) {
      this.closeModal();
    } else if (this.toolbarConfig.closeAll) {
      this.closeAllModals();
    } else if (this.toolbarConfig.pop) {
      if (this.isMobile) {
        this.goBack();
      } else {
        this.closeModal();
      }
    }
  }

  private closeModal() {
    if (this.dialogRef) {
      this.modalService.closeActiveModal(true);
    }
  }

  private closeAllModals() {
    if (this.isMobile) {
      this.toolbarNavigationService.navigateForward(
        [APP_ROUTES.EVENTS],
        {},
        null,
      );
    } else if (this.dialogRef) {
      this.modalService.closeAllModals(true);
    }
  }

  private goBack() {
    this.toolbarNavigationService.back();
  }
}
