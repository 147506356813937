import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConstants {
  static readonly ApiUrl = import.meta.env.NG_APP_API_URL;
  static readonly GtagId = import.meta.env.NG_APP_GTAG_ID;
  static readonly PublicKey = import.meta.env.NG_APP_PUBLIC_KEY;
  static readonly PublicUrls = [
    'api/v1/events/sdui/',
    'api/v1/cities/',
    'api/v1/events/',
    // 'api/v1/featureflags/',
  ];
  static readonly Platform = {
    MoWeb: 'moweb',
    Desktop: 'web',
  };
  static readonly DefaultLanguage = 'en';
  static readonly PrefixAuth = 'Token';
}
