<div class="card-container">
  <div class="top-icons">
    <div class="participants-date">
      <div class="participants">
        <app-tc-icon [iconId]="'system_users'"></app-tc-icon>
        <span>{{ participants }}</span>
      </div>
      <div class="date-time">
        <app-tc-icon [iconId]="'system_calendar_event'"></app-tc-icon>
        <span>{{ dateTime }}</span>
      </div>
    </div>
    <div class="close-button">
      <app-tc-icon [iconId]="'system_times'"></app-tc-icon>
    </div>
  </div>

  <div class="image-section">
    <img class="event-image" [src]="system_explore_events" alt="Car Event" />
  </div>

  <div class="details-section">
    <h2 class="event-title" m-0 fz-14 fw-700>{{ eventTitle }}</h2>
    <div class="event-location">
      <p m-0 fz-12>{{ eventLocation }}</p>
    </div>
    <app-tc-icon class="icon" [iconId]="'system_redirect_detail'"></app-tc-icon>
  </div>
</div>
