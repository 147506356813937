import { Injectable } from '@angular/core';
import { ValidatorPlugin } from '../validator-plugin.interface';
import { EmailValidatorPlugin } from '../validators/email-validator.plugin';
import { PasswordValidatorPlugin } from '../validators/password-validator.plugin';
import { UsernameValidatorPlugin } from '../validators/username-validator.plugin';
import { BirthdateValidatorPlugin } from '../validators/birthdate-validator.plugin';
import { PhoneValidatorPlugin } from '../validators/phone-validator.plugin';
import { NameValidatorPlugin } from '../validators/name-validator.plugin';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  private validators: { [key: string]: ValidatorPlugin } = {};

  constructor() {
    this.validators['email'] = new EmailValidatorPlugin();
    this.validators['password'] = new PasswordValidatorPlugin();
    this.validators['username'] = new UsernameValidatorPlugin();
    this.validators['name'] = new NameValidatorPlugin();
    this.validators['birthdate'] = new BirthdateValidatorPlugin();
    this.validators['phone'] = new PhoneValidatorPlugin();
  }

  getValidator(key: string): ValidatorPlugin | undefined {
    return this.validators[key];
  }
}
