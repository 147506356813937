<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="modalClosing ? 'permission-modal closing' : 'permission-modal'"
>
  <div class="modal-content">
    <div pb-24 w-100 class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
    </div>

    <img [src]="type === 2 ? iconPath : iconPathNotification" alt="" />

    <h1 m-0 fz-22 pb-16 pt-36 w-100 fw-500 tcMilk text-center TextStyle-B>
      {{
        type === 1
          ? (translate.titlePermissionsCamera | translate)
          : type === 2
            ? (translate.titlePermissionsLocation | translate)
            : (translate.titlePermissionsNotification | translate)
      }}
    </h1>

    <h1 m-0 fz-14 pb-64 w-100 fw-400 tcMilk text-center TextStyle-L>
      {{
        type === 1
          ? (translate.subtitlePermissionsCamera | translate)
          : type === 2
            ? (translate.subtitlePermissionsLocation | translate)
            : (translate.subtitlePermissionsNotification | translate)
      }}
    </h1>

    <p m-0 fz-12 pb-24 w-100 fw-400 tcGhost text-center TextStyle-L>
      {{ translate.adds | translate }}
    </p>

    <app-tcbutton
      [type]="TypesButton.PRIMARY"
      [disabled]="false"
      (click)="confirmPermissionsModal()"
    >
      {{ translate.allow | translate }}
    </app-tcbutton>
  </div>
</p-dialog>
