import { Component, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_ROUTES } from '@/app/features/user-profile/user-profile-routing.module';
import { ScreenConfigService } from '../../../libraries/main-layout/services/screen-config.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { ModalService } from '../../../libraries/main-layout/services/modal.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  PATH_SIGN_EMAIL_VERIFICATION,
  PATH_SIGN_PHONE_VERIFICATION,
  responseData,
  userData,
} from '@/app/features/authentication/constants';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { APP_ROUTES } from '@/app/app-routing.module';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';
import {
  QueryParam,
  ScreenPresentation,
  ScreenTitleConfig,
  toolbarOptions,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';
import { TypesTitles } from '@/app/design-system/atoms/title/constants';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import {
  VerificationScreenName,
  VerificationScreenTitleConfig,
} from './verification.config';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  otpDisabled = false;
  cleanOTP = false;
  phone = false;
  otpValid = false;
  otpValue = '';
  currentRoute = '';
  flow = '';
  email = '';
  phoneNumber = '';
  form!: FormGroup;
  toolbarActions!: toolbarOptions;
  isMobile = false;
  isLoading = true;
  params: any;
  QueryParam = QueryParam;
  TypesTitles = TypesTitles;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: ModalService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setVerificationScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
        this.toolbarActions = { ...config.useToolbar };
      }
    });
    this.form = this.fb.group({
      inputOTP: [null, [Validators.required]],
    });
  }

  get inputOTP(): AbstractControl | null {
    return this.form.get('inputOTP');
  }

  ngOnInit(): void {
    if (this.isMobile) {
      this.route.queryParams.subscribe((params) => {
        this.flow = params['flow'] ? window.atob(params['flow']) : '';
        this.email = params['email'] ? window.atob(params['email']) : '';
        this.phoneNumber = params['phone'] ? window.atob(params['phone']) : '';
      });
    } else {
      this.params = this.config?.data?.params;
      this.flow = this.params['flow'] ? window.atob(this.params['flow']) : '';
      this.email = this.params['email']
        ? window.atob(this.params['email'])
        : '';
      this.phoneNumber = this.params['phone']
        ? window.atob(this.params['phone'])
        : '';
    }

    this.modalService.onModalVisibilityChange().subscribe(() => {
      this.cleanOTP = true;
      this.otpValue = '';
      this.otpValid = false;
      this.inputOTP?.setValue(null);
      setTimeout(() => {
        this.cleanOTP = false;
      }, 100);
    });
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  onOtpComplete(value: string) {
    this.otpValue = value;
    if (this.otpValue.length === 6) {
      this.otpValid = true;
      this.inputOTP?.setValue(this.otpValue);
    } else {
      this.otpValid = false;
    }
  }

  onSubmit() {
    if (this.form.valid) {
      let params;
      let queryParams;
      let countryCode;
      let phoneNumber;
      const regex = /^\+(\d{1,2})\s(.+)$/;

      if (this.flow === QueryParam.flowPhone) {
        const match = this.phoneNumber.match(regex);
        if (match) {
          countryCode = match[1];
          phoneNumber = match[2].replace(/\s+/g, '');
        }
        params = {
          area_code: countryCode,
          phone: phoneNumber,
          code: this.inputOTP?.value,
        };
        queryParams = {
          flow: window.btoa(this.flow),
        };
      } else {
        params = {
          email: this.email,
          code: this.inputOTP?.value,
        };
        queryParams = {
          flow: window.btoa(this.flow),
        };
      }
      this.httpServicesPost(params, queryParams);
    }
  }
  httpServicesPost(params: object, queryParams: object) {
    const postPath =
      this.flow === QueryParam.flowEmail
        ? PATH_SIGN_EMAIL_VERIFICATION
        : PATH_SIGN_PHONE_VERIFICATION;

    this.httpMethodsService.postData<responseData>(postPath, params).subscribe({
      next: (response) => {
        if (response.status === 200 && response.body) {
          const responseBody: responseData = response.body;
          this.authService.login(responseBody.token);
          const dataUser: userData = {
            id: responseBody.user.id,
            email: responseBody.user.email,
            profile_picture: responseBody.user.profile_picture,
            username: responseBody.user.username,
            birthdate: responseBody.user.birthdate,
            is_registered: responseBody.user.is_registered,
            is_verified: responseBody.user.is_verified,
          };
          this.authService.setDataUser(dataUser);
          if (this.isMobile) {
            this.toolbarNavigationService.navigateForward(
              [
                this.flow === QueryParam.flowEmail
                  ? USER_ROUTES.USERNAME
                  : USER_ROUTES.ADD_EMAIL,
              ],
              queryParams,
              null,
            );
          } else {
            this.modalService.closeActiveModal();
            this.toolbarNavigationService.navigateForward(
              [APP_ROUTES.EVENTS],
              queryParams,
              null,
            );
          }
        }
      },
      error: async (error) => {
        await this.errorModalService.showError(
          error.status === 400
            ? { feature: this.data.feature, key: this.data.userNotFound }
            : error.status === 401
              ? { feature: this.data.feature, key: this.data.invalidCode }
              : { feature: this.data.feature, key: this.data.codeExpired },
        );
      },
    });
  }

  setVerificationScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: VerificationScreenName,
      dataText: VerificationScreenTitleConfig,
      useToolbar: {
        state: true,
        pop: true,
      },
      useHeader: true,
      useFooter: true,
      presentation: ScreenPresentation.Modal,
    });
  }
}
