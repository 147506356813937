<p-dialog
  [(visible)]="display"
  [modal]="true"
  [closable]="true"
  [style]="{ width: '400px' }"
  [baseZIndex]="10000"
  [closable]="true"
>
  <ng-template pTemplate="header">
    <div class="modal-header">
      {{ translate.errorModal | translate }}
    </div>
  </ng-template>
  <p style="padding: var(--size-12) var(--size-28)">
    {{ getTranslationKey(errorMessage.key ?? "") | translate }}
  </p>
  <app-tcbutton pb-24 [type]="TypesButton.PRIMARY" (click)="confirm()">
    {{ translate.confirmModal | translate }}
  </app-tcbutton>
</p-dialog>
