<div>
  <ng-container *ngIf="isLoading">
    <app-events-detail-skeleton w-100></app-events-detail-skeleton>
  </ng-container>

  <div class="city-container" *ngIf="!isLoading">
    <div *ngFor="let section of sections">
      <ng-container [appDynamicSection]="section"></ng-container>
    </div>
  </div>
</div>
