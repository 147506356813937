<div class="grid-container">
  <div class="content-pages">
    <!-- <div class="location-bar" w-100 pb-28>
      <div class="cont" (click)="openLocationModal()">
        <app-tc-icon [iconId]="'system_pin'"></app-tc-icon>
        <p class="text-location"
          m-0
          fz-12
          fw-400
          TextStyle
          style="color: var(--racing)"
          >
          {{ translate.all.location | translate }}        
        </p>
        <app-tc-icon
          class="chev-down"
          [iconId]="'system_chevron_down'"
        ></app-tc-icon>
      </div>
    </div> -->

    <div w-100>
      <div w-100 pt-24 pb-24 *ngIf="!isMobile">
        <h1 fz-22 fw-500 m-0 TextStyle>
          {{ translate.all.title | translate }}
        </h1>
      </div>
      <ng-container *ngIf="isLoading">
        <app-general-events-skeleton w-100></app-general-events-skeleton>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <div *ngFor="let section of sections">
          <ng-container [appDynamicSection]="section"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!isMobile && isLoggedIn">
    <div w-100 pt-24 pb-16>
      <h1 fz-22 fw-500 m-0 TextStyle>
        {{ translate.all.titleUp | translate }}
      </h1>
    </div>
    <ng-container *ngIf="isLoading">
      <app-loading-skeleton
        w-100
        pb-28
        [type]="'card'"
        [count]="3"
        style="padding: 0 0 var(--size-24) 0"
      ></app-loading-skeleton>
    </ng-container>
    <div
      class="upcoming-side"
      [ngClass]="{ backgroundEb: upcoming.length < 1 }"
      *ngIf="!isLoading"
    >
      <ng-container *ngIf="upcoming.length < 1">
        <app-tc-icon [iconId]="'system_grid_stack'"></app-tc-icon>
        <p fz-16 fw-400 m-0 text-center TextStyle-L>
          {{ translate.all.textUpcoming | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="isLoggedIn">
        <div w-100 *ngFor="let section of upcoming">
          <ng-container [appDynamicSection]="section"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-select-location
  #selectLocation
  [type]="1"
  (confirmModal)="handleconfirmlocationModal($event)"
>
</app-select-location>
