import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { ScreenConfigService } from '../../../libraries/main-layout/services/screen-config.service';
import {
  ScreenConfiguration,
  ScreenPresentation,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('presentationAnimations', [
      transition(':enter', [
        style({
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(100%, -50%)',
          opacity: 0,
        }),
        animate(
          '300ms ease-out',
          style({ transform: 'translate(-50%, -50%)', opacity: 1 }),
        ),
      ]),
      transition(':leave', [
        style({
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 1,
        }),
        animate(
          '300ms ease-out',
          style({ transform: 'translate(-100%, -50%)', opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class MainLayoutComponent {
  config!: ScreenConfiguration;
  presentationClass = '';
  isVisibleModal = false;
  isMobile = false;
  private destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private screenSizeService: ScreenSizeService,
    @Optional() private screenConfigService: ScreenConfigService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.screenConfigService.config$
      .pipe(takeUntil(this.destroy$))
      .subscribe((config) => {
        if (config) {
          this.config = config;
          this.setPresentationClass(config.presentation);
          this.cd.detectChanges();
        }
      });
  }

  setPresentationClass(presentation: ScreenPresentation) {
    this.presentationClass = presentation;
  }
}
