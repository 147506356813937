<div class="header">
  <app-tc-icon
    cursor-pointer
    class="header-logo"
    [iconId]="'system_TopCarsLogo'"
    (click)="navigationService.navigateToUrl(linkPage)"
  ></app-tc-icon>

  <!-- <div
    class="content-header"
    [class.hide]="isMobile"
    [class.show]="isMobileMenuOpen"
  >
    <ng-container>
      <app-tctext
        tcMilk
        feature=""
        key="header.redirectEvents"
        *ngIf="!isMobile"
        (click)="goHome()"
      ></app-tctext>
      <i *ngIf="isMobile" tcMilk class="pi pi-car"></i>
    </ng-container>

    <div class="language-selector">
      <div (click)="toggleDropdown()">
        <app-tc-icon [iconId]="'system_globe_translate'"></app-tc-icon>
      </div>
      <div *ngIf="isDropdownOpen" class="dropdown">
        <button (click)="changeLanguage('es')">
          <app-tctext feature="" key="header.languageEs"></app-tctext>
        </button>
        <button (click)="changeLanguage('en')">
          <app-tctext feature="" key="header.languageEn"></app-tctext>
        </button>
      </div>
    </div>

    <ng-container>
      <app-tcbutton
        *ngIf="!isMobile && !isLoggedIn"
        [type]="TypesButton.small"
        [disabled]="false"
        (click)="signIn()"
      >
        <app-tctext feature="" key="header.signIn"></app-tctext>
      </app-tcbutton>
      <i *ngIf="isMobile" tcMilk class="pi pi-user"></i>
    </ng-container>

    <ng-container *ngIf="!isMobile && isLoggedIn">
      <app-tc-identity [userData]="dataUser"></app-tc-identity>
    </ng-container>
  </div> -->

  <!-- <div
    class="options-icon"
    [class.showMobile]="isMobile"
    (click)="toggleMobileMenu()"
  >
    <i tcMilk class="pi pi-bars"></i>
  </div> -->
</div>
