import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component, OnInit } from '@angular/core';
import {
  PATH_CITIES,
  PATH_CITIES_SUBSCRIBE,
  PATH_CITIES_UNSUBSCRIBE,
} from '@/app/libraries/events/constants';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { SignInScreenName } from '@/app/features/authentication/login/login.config';
import { QueryParam } from '@/app/libraries/main-layout/constants/screen-configuration';
import { CityDetailScreenTitleConfig } from '@/app/features/events/city-detail/city-detail.config';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';
import { CityHeaderSection } from './types';
import { ToastService } from '@/app/topcars-ui/toasts/toast.service';

@Component({
  selector: 'app-tc-background',
  templateUrl: './tc-background.component.html',
  styleUrls: ['./tc-background.component.scss'],
})
export class TcBackgroundComponent implements OnInit {
  data!: CityHeaderSection;
  TypesButton = TypesButton;
  isMobile = false;
  isLoggedIn = false;
  isLoading = false;
  CityDetailScreenTitleConfig = CityDetailScreenTitleConfig;
  translate = TRANSLATIONS.events;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnInit(): void {
    this.toastMessage();
  }

  toastMessage() {
    const value = this.authService.getItemStorage('subscribeCity');
    const subscribed = value ? JSON.parse(value) : undefined;

    if (subscribed) {
      const message = this.translate.city.toastMessage;
      const value = this.data.city.name;
      this.toastService.success(message, {
        interpolateParams: { value: value },
      });
      sessionStorage.removeItem('subscribeCity');
    }
  }

  httpServicesPost() {
    if (this.isLoggedIn) {
      this.isLoading = true;
      const params = {
        interested: !this.data.subscribed,
      };
      const pathUse = this.data.subscribed
        ? PATH_CITIES_UNSUBSCRIBE
        : PATH_CITIES_SUBSCRIBE;
      const path = `${PATH_CITIES}${this.data?.city?.key}${pathUse}`;
      this.httpMethodsService.postData(path, params).subscribe({
        next: (response) => {
          if (response.status === 200 || response.status === 201) {
            this.authService.setItemStorage(
              'subscribeCity',
              JSON.stringify(!this.data.subscribed),
            );
            window.location.reload();
          }
        },
        error: async () => {
          await this.errorModalService.showError({
            feature: this.CityDetailScreenTitleConfig.feature,
            key: this.CityDetailScreenTitleConfig.responseError,
          });
          this.isLoading = false;
        },
      });
    } else {
      this.signIn();
    }
  }

  signIn() {
    this.toolbarNavigationService.navigateForwardModal(SignInScreenName, {
      modal: window.btoa(QueryParam.FLOW),
    });
  }
}
