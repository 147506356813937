<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  "
>
  <app-loading-skeleton
    w-100
    [type]="'label'"
    [count]="2"
    style="padding: var(--size-24) 0 0 0"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    [type]="'form'"
    [count]="2"
    style="padding: var(--size-24) 0"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    [type]="'label'"
    [count]="1"
    style="width: 20%"
  ></app-loading-skeleton>
  <app-loading-skeleton
    w-100
    [type]="'form'"
    [count]="3"
    style="padding: var(--size-24) 0 var(--size-104) 0"
  ></app-loading-skeleton>
  <app-loading-skeleton
    [type]="'label'"
    [count]="1"
    style="
      width: calc(100% - var(--size-48));
      padding: var(--size-24) 0;
      position: absolute;
      bottom: 0;
    "
  ></app-loading-skeleton>
</div>
