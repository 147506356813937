/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @typescript-eslint/naming-convention */
import { TitleType } from '@/app/topcars-ui/title/types/title';
import { Component, computed, input } from '@angular/core';

@Component({
  selector: '[tc-title]',
  standalone: true,
  template: `<ng-content />`,
  styleUrl: './title.component.scss',
  host: {
    '[class]': 'computedClass()',
  },
})
export class TitleComponent {
  tcTitle = input<TitleType>(TitleType.GENERAL);
  desktopMode = input<boolean>(false);

  computedClass = computed(() => {
    const classes = [this.tcTitle()];

    if (this.desktopMode()) {
      if (this.tcTitle() === TitleType.SECTION) {
        classes.push(TitleType.DESKTOP_MODE);
      } else if (this.tcTitle() === TitleType.SUBTITLE_SECTION) {
        classes.push(TitleType.DESKTOP_MODE_SUB);
      }
    }

    return classes.join(' ');
  });
}
