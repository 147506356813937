import { ALL_EVENTS_ROUTES } from '../events-all/events-all-routing.module';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  QueryParam,
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import {
  AUTO,
  HIDDEN,
  EVENT_ID,
  EventsDetailScreenName,
  EventsDetailScreenTitleConfig,
} from './event-detail.config';
import { APIResponse, EventResponse, EventDetailResponse } from './types';
import { TitleType } from '@/app/topcars-ui/title/types/title';
import { forkJoin, Subscription } from 'rxjs';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import { PATH_EVENTS, PATH_INTERESTED } from '@/app/libraries/events/constants';
import { UserData } from '../../authentication/types';
import { TextService } from '@/app/libraries/core/services/text.service';
import { DateFormat } from '@/app/libraries/core/dates/date.interface';
import { DateService } from '@/app/libraries/core/dates/services/date.service';
import { NavigationService as MainLayoutNavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { APP_ROUTES } from '../../layout/main-layout/main-layout-routing.module';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { SignInScreenName } from '../../authentication/login/login.config';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  isMobile = false;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  isExpanded = false;
  TypesTitles = TitleType;
  isLoading = true;
  eventId!: string;
  eventDetail!: EventResponse;
  dataUser: UserData | null = null;
  initialDateValues!: DateFormat;
  finalDateValues!: DateFormat;
  isLoggedIn = false;
  private languageSubscription!: Subscription;
  firstRequest = true;
  translate = TRANSLATIONS.events;
  @HostListener('document:keydown.escape')
  onEscape() {
    if (this.isExpanded) {
      this.toggleExpand();
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public textService: TextService,
    private dateService: DateService,
    private authService: AuthService,
    public navigationService: MainLayoutNavigationService,
    private errorModalService: ErrorModalService,
    private screenSizeService: ScreenSizeService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
    this.setEventsDetailScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  get attendees(): (string | null)[] {
    const faces = this.eventDetail?.faces || [];
    const totalCount = this.eventDetail?.interested_count || 0;
    const missingCount = totalCount - faces.length;

    return [...faces, ...Array(missingCount).fill(null)];
  }

  ngOnInit() {
    this.getData();

    this.languageSubscription = this.authService.languageChanged$.subscribe(
      () => {
        if (!this.firstRequest) {
          this.getData();
        }
      },
    );
  }

  getData() {
    this.eventId = this.route.snapshot.paramMap.get(EVENT_ID) ?? 'null';
    const path = PATH_EVENTS + `${this.eventId}/`;
    forkJoin({
      eventDetail: this.httpMethodsService.getData(path, {}),
    }).subscribe({
      next: (response) => {
        const Response: APIResponse = response as APIResponse;
        this.eventDetail = Response?.eventDetail?.body;
        this.dataUser = {
          id: this.eventDetail.organizer?.id,
          username: this.eventDetail.organizer?.username,
          profile_picture: this.eventDetail.organizer?.profile_picture,
          is_verified: this.eventDetail.organizer?.is_verified,
        };
        this.initialDateValues = this.dateService.convertToLocalDate(
          this.eventDetail?.initial_date,
        );
        this.finalDateValues = this.dateService.convertToLocalDate(
          this.eventDetail?.final_date,
        );
        this.firstRequest = false;
        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
        this.toolbarNavigationService.navigateForward(
          [APP_ROUTES.EVENTS],
          {},
          null,
        );
      },
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      document.body.style.overflow = HIDDEN;
    } else {
      document.body.style.overflow = AUTO;
    }
  }

  httpServicesPost() {
    if (this.isLoggedIn) {
      this.isLoading = true;
      const params = {
        interested: true,
      };
      const path = `${PATH_EVENTS}${this.eventId}${PATH_INTERESTED}`;
      this.httpMethodsService.postData(path, params).subscribe({
        next: (response) => {
          const Response: EventDetailResponse = response as EventDetailResponse;
          if (response.status === 200 || response.status === 201) {
            this.eventDetail = Response?.body;
            this.isLoading = false;
          }
        },
        error: async () => {
          await this.errorModalService.showError({
            feature: this.data.feature,
            key: this.data.responseError,
          });
          this.isLoading = false;
        },
      });
    } else {
      this.signIn();
    }
  }

  signIn() {
    this.toolbarNavigationService.navigateForwardModal(SignInScreenName, {
      modal: window.btoa(QueryParam.FLOW),
    });
  }

  redirectToGoogleCalendar() {
    const eventTitle = 'Reunión importante';
    const startDate = '20240901T100000Z';
    const endDate = '20240901T110000Z';
    const details = 'Reunión para discutir el proyecto';
    const location = 'Oficina Central';

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventTitle)}
      &dates=${startDate}/${endDate}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(location)}&sf=true&output=xml`;

    window.open(googleCalendarUrl, '_blank');
  }

  openGoogleMaps(latitude: number, longitude: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  }

  return() {
    this.router.navigate([ALL_EVENTS_ROUTES.EventsUpcoming]);
  }

  setEventsDetailScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: EventsDetailScreenName,
      dataText: EventsDetailScreenTitleConfig,
      useToolbar: {
        state: false,
      },
      useFooter: !this.isMobile,
      useHeader: true,
      presentation: ScreenPresentation.NARROW,
    });
  }
}
