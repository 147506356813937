import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutModule } from './features/layout/main-layout/main-layout.module';
import { LibrariesModule } from './libraries/Libraries.module';
import { ModalsModule } from './topcars-ui/modals/modals.module';
import { TextService } from './libraries/core/services/text.service';
import { AuthInterceptor } from './libraries/authentication/interceptors/auth-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { UserProfileModule } from './features/user-profile/user-profile.module';
import { EventsModule } from './features/events/events.module';
import { DesignSystemModule } from './design-system/DesignSystem.module';
import { AnalyticsModule } from './libraries/core/analytics/analytics.module';
import { AppConstants } from './libraries/core/constants/constants.service';
import { IconsModule } from './topcars-ui/icons/icons.module';
import { ModuleTranslateLoaderService } from './libraries/core/translations/module-translate-loader.service';
import {
  TRANSLATION_PLUGIN,
  TranslationConfig,
} from './libraries/core/translations/translation-config';
import { featureFlagsProvider } from './libraries/core/feature-flags/feature-flag.provider';
import { provideToast } from './topcars-ui/toasts/provide-toast';
import { provideIcons } from './topcars-ui/icons/provide-icons';
import { provideStorageManager } from './libraries/core/storage-manager/provide-storage-manager';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MainLayoutModule,
    LibrariesModule,
    DesignSystemModule,
    ModalsModule,
    AuthenticationModule,
    UserProfileModule,
    EventsModule,
    HttpClientModule,
    ReactiveFormsModule,
    IconsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient, resources: TranslationConfig[]) =>
          new ModuleTranslateLoaderService(http, resources),
        deps: [HttpClient, TRANSLATION_PLUGIN],
      },
    }),
    AnalyticsModule.forRoot({
      gtagId: AppConstants.GtagId,
    }),
    DialogModule,
    DynamicDialogModule,
  ],
  providers: [
    TextService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideIcons(),
    featureFlagsProvider(),
    provideToast(),
    provideStorageManager(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
