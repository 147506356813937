<div #contentPages class="content-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-view w-100></app-auth-view>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <div style="width: 100%; display: flex" [ngClass]="{ noWrap: noWrap }">
      <div
        class="signInwith"
        *ngIf="signInWith === 1"
        [@slideInOut]="{
          value: '',
          params: { startPosition: direction === 'left' ? '100%' : '-100%' },
        }"
      >
        <app-tc-title
          w-100
          pt-32
          pb-12
          [feature]="data.feature ?? ''"
          [key]="data.titleEmail ?? ''"
        ></app-tc-title>
        <app-tc-title
          w-100
          pb-24
          [type]="TypesTitles.subtitleGeneral"
          [feature]="data.feature ?? ''"
          [key]="data.subtitleEmail ?? ''"
        ></app-tc-title>
      </div>

      <div
        class="signInwith"
        *ngIf="signInWith === 2"
        [@slideInOut]="{
          value: '',
          params: { endPosition: direction === 'right' ? '-100%' : '100%' },
        }"
      >
        <app-tc-title
          w-100
          pt-32
          pb-12
          [feature]="data.feature ?? ''"
          [key]="data.titlePhone ?? ''"
        ></app-tc-title>
        <app-tc-title
          w-100
          pb-24
          [type]="TypesTitles.subtitleGeneral"
          [feature]="data.feature ?? ''"
          [key]="data.subtitlePhone ?? ''"
        ></app-tc-title>
      </div>
    </div>

    <form [formGroup]="form">
      <app-tcinput
        pb-24
        icon="system_email"
        formControlName="inputEmail"
        [placeholder]="placeHolder"
        [errorMessage]="{
          feature: data.feature ?? '',
          key: data.errorEmail ?? '',
        }"
        validatorKey="email"
        [maxlength]="'50'"
        (action)="actionToDo($event)"
        *ngIf="signInWith === 1"
      ></app-tcinput>

      <app-tcinput
        pb-24
        [type]="'tel'"
        icon="system_phone"
        formControlName="phone"
        [placeholder]="placeHolderPhone"
        validatorKey="phone"
        (action)="actionToDo($event)"
        [mask]="'000 000 0000'"
        [prefix]="'+1 '"
        [maxlength]="'15'"
        [errorMessage]="{
          feature: data.feature ?? '',
          key: data.errorPhone ?? '',
        }"
        *ngIf="signInWith === 2"
      ></app-tcinput>

      <app-tcbutton
        pb-24
        [type]="TypesButton.primary"
        [disabled]="!form.valid"
        (click)="onSubmit()"
      >
        <app-tctext
          class="text-button"
          [feature]="data.feature ?? ''"
          [key]="data.continue ?? ''"
        ></app-tctext>
      </app-tcbutton>
    </form>

    <h2 fz-16 pb-24 m-0>
      <app-tctext feature="g-auth" key="login.options"></app-tctext>
    </h2>

    <app-tcbutton
      *ngIf="signInWith === 2"
      pb-12
      [type]="TypesButton.secondary"
      (click)="signInChange(1)"
    >
      <app-tctext
        class="text-button"
        feature="g-auth"
        key="phone.signEmail"
      ></app-tctext>
    </app-tcbutton>

    <app-tcbutton
      *ngIf="signInWith === 1"
      pb-12
      [type]="TypesButton.secondary"
      (click)="signInChange(2)"
    >
      <app-tctext
        class="text-button"
        feature="g-auth"
        [key]="'login.signPhone'"
      ></app-tctext>
    </app-tcbutton>

    <app-tcbutton
      pb-12
      [type]="TypesButton.secondary"
      icon="system_apple"
      [disabled]="true"
    >
      <app-tctext
        class="text-button"
        feature="g-auth"
        key="login.signApple"
      ></app-tctext>
    </app-tcbutton>

    <app-tcbutton
      pb-104
      [type]="TypesButton.secondary"
      icon="system_google"
      [disabled]="true"
    >
      <app-tctext
        class="text-button"
        feature="g-auth"
        key="login.signGoogle"
      ></app-tctext>
    </app-tcbutton>

    <p w-100 fz-10 pb-24 class="foot">
      <app-tctext feature="g-auth" key="login.signFooter"></app-tctext>
    </p>
  </ng-container>
</div>
