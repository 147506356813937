export const TRANSLATIONS = {
  authentication: {
    login: {
      signEmail: 'authentication.login.sign_email',
      continue: 'authentication.login.continue',
      signEmailSecond: 'authentication.login.sign_email_second',
      signFooter: 'authentication.login.sign_footer',
      signPlaceholderInput: 'authentication.login.sign_placeholder_input',
      signEmailButton: 'authentication.login.sign_email_button',
      options: 'authentication.login.options',
      signPhone: 'authentication.login.sign_phone',
      signApple: 'authentication.login.sign_apple',
      signGoogle: 'authentication.login.sign_google',
      errorMessage: 'authentication.login.error_message',
      errorPost: 'authentication.login.error_post',
      responseError: 'authentication.login.response_error',
      errorMessagePassword: 'authentication.login.error_message_password',
      country: 'authentication.login.country',
    },
    verification: {
      title: 'authentication.verification.title',
      message: 'authentication.verification.message',
      messagePhone: 'authentication.verification.message_phone',
      messageResend: 'authentication.verification.message_resend',
      resend: 'authentication.verification.resend',
      continue: 'authentication.verification.continue',
      requestCode: 'authentication.verification.request_code',
      maximumAttempts: 'authentication.verification.maximum_attempts',
      errorPost: 'authentication.verification.error_post',
      userNotFound: 'authentication.verification.user_not_found',
      invalidCode: 'authentication.verification.invalid_code',
      codeExpired: 'authentication.verification.code_expired',
    },
    phone: {
      title: 'authentication.phone.title',
      subtitle: 'authentication.phone.subtitle',
      placeholder: 'authentication.phone.placeholder',
      errorMessage: 'authentication.phone.error_message',
      continue: 'authentication.phone.continue',
      options: 'authentication.phone.options',
      signEmail: 'authentication.phone.sign_email',
      signApple: 'authentication.phone.sign_apple',
      signGoogle: 'authentication.phone.sign_google',
      signFooter: 'authentication.phone.sign_footer',
      errorPost: 'authentication.phone.error_post',
      responseError: 'authentication.phone.response_error',
      countrySelectorPlaceholder:
        'authentication.phone.country_selector_placeholder',
      countrySelector: 'authentication.phone.country_selector',
      countrySearch: 'authentication.phone.country_search',
      noResults: 'authentication.phone.no_results',
    },
  },
  events: {
    all: {
      title: 'events.all.title',
      titleUp: 'events.all.title_up',
      textUpcoming: 'events.all.text_upcoming',
      location: 'events.all.location',
      cities: 'events.all.cities',
      locationCity: 'events.all.location_city',
      viewAll: 'events.all.view_all',
      selectLocation: 'events.all.select_location',
      noLocation: 'events.all.no_location',
      accessLocation: 'events.all.access_location',
      giveLocation: 'events.all.give_location',
      currentLocation: 'events.all.current_location',
      eventsLocation: 'events.all.events_location',
      popularEvents: 'events.all.popular_events',
      responseError: 'events.all.response_error',
    },
    events: {
      title: 'events.events.title',
      CTA: 'events.events.CTA',
      message: 'events.events.message',
      confirmInterest: 'events.events.confirm_interest',
      removeInterest: 'events.events.remove_interest',
      details: 'events.events.details',
      peopleGoing: 'events.events.people_going',
      seeMore: 'events.events.see_more',
      location: 'events.events.location',
      subscribe: 'events.events.subscribe',
      subscribed: 'events.events.subscribed',
      RSVP: 'events.events.RSVP',
      interested: 'events.events.interested',
      responseError: 'events.events.response_error',
    },
    city: {
      title: 'events.city.title',
      subscribe: 'events.city.subscribe',
      subscribed: 'events.city.subscribed',
      toastMessage: 'events.city.toast_message',
    },
  },
  layout: {
    header: {
      redirectEvents: 'layout.header.redirect_events',
      signIn: 'layout.header.sign_in',
      titleLang: 'layout.header.title_lang',
      languageEs: 'layout.header.language_es',
      languageEn: 'layout.header.language_en',
      termsConditions: 'layout.header.terms_conditions',
      privacyPolicy: 'layout.header.privacy_policy',
      language: 'layout.header.language',
      settings: 'layout.header.settings',
      general: 'layout.header.general',
      legal: 'layout.header.legal',
      logout: 'layout.header.logout',
    },
    footer: {
      topCars: 'layout.footer.top_cars',
      termsConditions: 'layout.footer.terms_conditions',
      privacyPolicy: 'layout.footer.privacy_policy',
      carreers: 'layout.footer.Carreers',
    },
  },
  modals: {
    modalError: {
      errorModal: 'modals.modal_error.error_modal',
      confirmModal: 'modals.modal_error.confirm_modal',
      responseError: 'modals.modal_error.response_error',
      responseErrorAvatar: 'modals.modal_error.response_error_avatar',
      responseErrorAllEvents: 'modals.modal_error.response_error_all_events',
      responseErrorEventsDetail:
        'modals.modal_error.response_error_events_detail',
      userNotFound: 'modals.modal_error.user_not_found',
      userConflictEmail: 'modals.modal_error.user_conflict_email',
      userConflict: 'modals.modal_error.user_conflict',
      invalidCode: 'modals.modal_error.invalid_code',
      codeExpired: 'modals.modal_error.code_expired',
    },
  },
  userProfile: {
    addEmail: {
      title: 'userProfile.add_email.title',
      subtitle: 'userProfile.add_email.subtitle',
      addPlaceH: 'userProfile.add_email.add_place_h',
      errorMessage: 'userProfile.add_email.error_message',
      messageVal: 'userProfile.add_email.message_val',
      continue: 'userProfile.add_email.continue',
      errorPost: 'userProfile.add_email.error_post',
      responseError: 'userProfile.add_email.response_error',
      userConflict: 'userProfile.add_email.user_conflict',
    },
    username: {
      title: 'userProfile.username.title',
      subtitle: 'userProfile.username.subtitle',
      textBase: 'userProfile.username.text_base',
      textBaseUser: 'userProfile.username.text_base_user',
      continue: 'userProfile.username.continue',
      userPlaceHinput: 'userProfile.username.user_place_hinput',
      errorMessage: 'userProfile.username.error_message',
      errorPost: 'userProfile.username.error_post',
      responseError: 'userProfile.username.response_error',
      userConflict: 'userProfile.username.user_conflict',
    },
    profile: {
      title: 'userProfile.profile.title',
      subtitle: 'userProfile.profile.subtitle',
      continue: 'userProfile.profile.continue',
      userPlaceHinput: 'userProfile.profile.user_place_hinput',
      userPlaceHbirthdate: 'userProfile.profile.user_place_hbirthdate',
      takePhoto: 'userProfile.profile.take_photo',
      errorMessage: 'userProfile.profile.error_message',
      errorMessageB: 'userProfile.profile.error_message_b',
      errorPost: 'userProfile.profile.error_post',
      responseError: 'userProfile.profile.response_error',
      responseErrorAvatar: 'userProfile.profile.response_error_avatar',
      userConflict: 'userProfile.profile.user_conflict',
      import: 'userProfile.profile.import',
      remove: 'userProfile.profile.remove',
      titlePermissionsCamera: 'userProfile.profile.title_permissions_camera',
      titlePermissionsLocation:
        'userProfile.profile.title_permissions_location',
      titlePermissionsNotification:
        'userProfile.profile.title_permissions_notification',
      subtitlePermissionsCamera:
        'userProfile.profile.subtitle_permissions_camera',
      subtitlePermissionsLocation:
        'userProfile.profile.subtitle_permissions_location',
      subtitlePermissionsNotification:
        'userProfile.profile.subtitle_permissions_notification',
      adds: 'userProfile.profile.adds',
      allow: 'userProfile.profile.allow',
    },
  },
} as const;
