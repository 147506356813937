import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tc-cta',
  templateUrl: './tc-cta.component.html',
  styleUrls: ['./tc-cta.component.scss'],
})
export class TcCtaComponent {
  @Input() desktopMode = false;
  @Input() feature = '';
  @Input() key = '';
}
