<div
  class="toast"
  [class]="{
    'toast--success': toastRef.data.type === 'success',
    'toast--error': toastRef.data.type === 'error',
    'toast--warning': toastRef.data.type === 'warning',
  }"
>
  @if (toastRef.data.type !== "show") {
    <div class="toast__icon">
      @switch (toastRef.data.type) {
        @case ("success") {
          <tc-icon name="check-circle-2" size="24" />
        }
        @case ("error") {
          <tc-icon name="cross-circle" size="24" />
        }
        @case ("warning") {
          <tc-icon name="alert-triangle" size="25" />
        }
      }
    </div>
  }

  <span>{{ toastRef.data.message }}</span>
</div>
