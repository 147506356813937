import { Component } from '@angular/core';
import {
  DetailDesktopSkeletonCount,
  DetailMobileSkeletonCount,
} from '../general-events-skeleton/constants';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';

@Component({
  selector: 'app-city-detail-skeleton',
  templateUrl: './city-detail-skeleton.component.html',
  styleUrls: ['./city-detail-skeleton.component.scss'],
})
export class CityDetailSkeletonComponent {
  isMobile = false;
  MobileSkeletonCount = DetailMobileSkeletonCount;
  DesktopSkeletonCount = DetailDesktopSkeletonCount;
  constructor(private screenSizeService: ScreenSizeService) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }
}
