import { ICON_CATALOG } from '@/app/libraries/core/icon/icon-catalog';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { COUNTRIES } from '../country-selector/constants';
import { CountriesSelector } from '../country-selector/types';
import { CountriesSelectorComponent } from '../countries-selector/countries-selector.component';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { CO, ES, US } from '@/app/libraries/core/navigation/toolbar.interface';
import { Country, DataCountries } from '../../../login/types';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() valueIn!: CountriesSelector;
  @Input() icon!: string;
  @Input() iconSecondary!: string;
  @Input() placeholder!: string;
  @Input() placeholderSelector!: string;
  @Input() disabled = false;
  @Output() value = new EventEmitter<CountriesSelector>();
  iconPath = '';
  iconPathSecond = '';
  options!: DataCountries;
  selectedOption!: CountriesSelector;
  @ViewChild(CountriesSelectorComponent)
  countriesSelector!: CountriesSelectorComponent;
  lang!: string;

  constructor(private authService: AuthService) {
    this.authService.languageChanged$.subscribe((value) => {
      if (value) {
        this.lang = value;
        this.defaultCountry();
      }
    });
  }

  ngOnInit(): void {
    this.options = {
      countries: COUNTRIES,
      placeholder: this.placeholder,
    };
    this.defaultCountry();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.iconPath = ICON_CATALOG[this.icon] || '';
    this.iconPathSecond = ICON_CATALOG[this.iconSecondary] || '';
    if (changes['placeholder']) {
      this.placeholder = changes['placeholder'].currentValue;
    }
    if (changes['placeholderSelector']) {
      this.placeholderSelector = changes['placeholderSelector'].currentValue;
    }
  }

  defaultCountry() {
    const countries = this.options?.countries;
    const countryCode = this.lang === ES ? CO.toUpperCase() : US.toUpperCase();
    const countryDefault = countries?.find(
      (country: Country) => country.code === countryCode,
    );
    this.value.emit(countryDefault);
  }

  async onSelectClick(): Promise<void> {
    this.countriesSelector.openModal(this.options);
  }

  countrySelected(event: CountriesSelector) {
    if (event) {
      this.selectedOption = event;
      this.value.emit(this.selectedOption);
      this.countriesSelector.closeModal();
    }
  }
}
