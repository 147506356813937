<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="modalClosing ? 'location-modal closing' : 'location-modal'"
>
  <div class="modal-content">
    <div pb-24 w-100 style="display: flex" class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>

      <h1 m-0 pb-24 fz-14 w-100 fw-700 tcMilk text-center TextStyle>
        {{ translate.selectLocation | translate }}
      </h1>
    </div>

    <img class="imgLocation" [src]="system_location" alt="" />

    <h1 m-0 pb-12 pt-24 fz-22 w-100 fw-500 tcMilk text-center TextStyle-B>
      {{ translate.noLocation | translate }}
    </h1>

    <p m-0 fz-14 w-100 pb-24 fw-400 tcMilk text-center TextStyle-L>
      {{ translate.accessLocation | translate }}
    </p>

    <app-tcbutton
      [type]="TypesButton.PRIMARY"
      [disabled]="false"
      (click)="confirmPermissionsModal()"
    >
      {{ translate.giveLocation | translate }}
    </app-tcbutton>
  </div>
</p-dialog>
