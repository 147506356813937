import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './authentication.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from './login/login.module';
import { VerificationModule } from './verification/verification.module';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';

@NgModule({
  declarations: [AuthenticationComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
    LoginModule,
    VerificationModule,
  ],
})
export class AuthenticationModule {}
