import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidatorService } from '@/app/libraries/core/validator-interface/services/validator.service';
import { ValidatorPlugin } from '@/app/libraries/core/validator-interface/validator-plugin.interface';
import { ICON_CATALOG } from '@/app/libraries/core/icon/icon-catalog';

@Component({
  selector: 'app-tcinput',
  templateUrl: './tcinput.component.html',
  styleUrls: ['./tcinput.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TCInputComponent),
      multi: true,
    },
  ],
})
export class TCInputComponent implements OnInit, OnChanges {
  @Input() icon!: string;
  @Input() placeholder = '';
  @Input() errorMessage!: string;
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() validatorKey?: string;
  @Input() styleClass?: 'outline' | '';
  @Input() titleInput = '';
  @Input() mask = '';
  @Input() prefix = '';
  @Input() maxlength = '';
  @Input() autocomplete = '';

  @Output() action = new EventEmitter<string>();

  value = '';
  iconPath = '';
  isValid = true;
  isComplete = false;
  private validator?: ValidatorPlugin;

  constructor(private validatorService: ValidatorService) {}

  ngOnInit(): void {
    if (this.validatorKey) {
      this.validator = this.validatorService.getValidator(this.validatorKey);
    }
  }

  ngOnChanges() {
    this.iconPath = ICON_CATALOG[this.icon] || '';
  }

  onChange = (value: string) => {
    if (this.onChange) {
      this.onChange(value);
    }
  };

  onTouched = () => {
    if (this.onTouched) {
      this.onTouched();
    }
  };

  writeValue(value: string): void {
    this.value = value;

    // Ejecuta la validación si hay un validador configurado
    if (value && this.validator) {
      this.isValid = this.validator.validate(this.value);
      this.isComplete = this.isValid;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.value = target.value;

    if (this.value.trim() === '') {
      this.isValid = true;
      this.isComplete = false;
    } else if (this.validator) {
      this.isValid = this.validator.validate(this.value);
      this.isComplete = this.isValid;
    }

    this.onChange(this.value);
    this.onTouched();
  }

  cleanControl() {
    this.action.emit('clear');
    this.isValid = true;
    this.isComplete = false;
  }
}
