import { ValidatorPlugin } from '../validator-plugin.interface';

export class BirthdateValidatorPlugin implements ValidatorPlugin {
  validate(value: string): boolean {
    // format MM/DD/YYYY
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    // Validate length
    const length = value?.length;

    if (length === 10) {
      if (!datePattern.test(value)) {
        return false;
      }

      const [month, day, year] = value.split('/').map(Number);
      const date = new Date(year, month - 1, day);

      // Verify that the date is valid
      if (
        date.getDate() !== day ||
        date.getMonth() !== month - 1 ||
        date.getFullYear() !== year
      ) {
        return false;
      }

      // Verify that the date is not in the future
      const today = new Date();
      if (date > today) {
        return false;
      }

      return true;
    }

    // Progressive validation based on the length of the entered value
    if (length > 0 && length <= 2) {
      const month = Number(value);
      if (month < 1 || month > 12) {
        return false;
      }
    } else if (length > 2 && length <= 5) {
      const day = Number(value.slice(3, 5));
      if (day < 1 || day > 31) {
        return false;
      }
    } else if (length > 5 && length <= 10) {
      const year = Number(value.slice(6));
      const currentYear = new Date().getFullYear();
      if (year < 1900 || year > currentYear) {
        return false;
      }
    }

    return true;
  }
}
