<div class="section-container">
  <div class="section-item">
    <div class="section-content">
      <app-tc-title
        *ngIf="title"
        [type]="TypesTitles.section"
        [desktopMode]="!isMobile"
        [title]="data.title ?? ''"
      ></app-tc-title>
    </div>
    <app-tc-cta
      *ngIf="ctaLabel"
      [desktopMode]="!isMobile"
      [feature]="''"
      [key]="''"
    ></app-tc-cta>
  </div>
  <app-tc-title
    *ngIf="subtitle"
    [type]="TypesTitles.section"
    [desktopMode]="!isMobile"
    [feature]="''"
    [key]="''"
  ></app-tc-title>
</div>
