import { Component, EventEmitter, Output } from '@angular/core';
import { UserData } from '@/app/features/authentication/types';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { NavigationService as MainLayoutNavigationService } from '@/app/libraries/main-layout/services/navigation.service';
import { LinkRedirects } from '../../footer/constants';
import { LangScreenName } from '../language-dropdown/constants';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  userData: UserData | null = null;
  isMobile = false;
  TypesButton = TypesButton;
  LinkRedirects = LinkRedirects;
  @Output() eventLogOut = new EventEmitter<boolean>();
  translate = TRANSLATIONS.layout.header;

  constructor(
    private authService: AuthService,
    public navigationService: MainLayoutNavigationService,
    private screenSizeService: ScreenSizeService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.authService.dataUser$.subscribe((data) => {
      this.userData = data;
    });
  }

  langModal() {
    this.toolbarNavigationService.navigateForwardModal(LangScreenName, {});
  }

  logOut() {
    this.authService.logout();
    this.eventLogOut.emit(true);
  }
}
