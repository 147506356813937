<div class="otp-container">
  <form [formGroup]="otpForm" class="otp-form">
    <div class="otp-input-wrapper">
      <ng-container *ngFor="let control of otpControls; let i = index">
        <input
          appNumbersOnly
          type="text"
          [formControlName]="'digit' + i"
          maxlength="1"
          name="digit{{ i }}"
          class="otp-input"
          (keyup)="onKeyUp($event, i)"
          [ngClass]="{
            'otp-input-complete': control.value.length === 1 && control.valid,
            'otp-input-initial': control.value.length === 0,
            'otp-input-disabled': isDisabled,
          }"
        />
      </ng-container>
    </div>
  </form>

  <p
    *ngIf="
      !isDisabled &&
      (this.countdownTime.getMinutes() > 0 ||
        this.countdownTime.getSeconds() > 0)
    "
    TextStyle
    fz-14
    fw-400
    pt-12
    pb-42
    m-0
  >
    {{ translate.verification.requestCode | translate }}
    <strong>{{ countdownTime | date: "mm:ss" }}</strong>
  </p>

  <p
    *ngIf="
      !isDisabled &&
      this.countdownTime.getMinutes() === 0 &&
      this.countdownTime.getSeconds() === 0
    "
    TextStyle
    tcConcrete
    fz-14
    fw-400
    pt-12
    pb-42
    m-0
  >
    {{ translate.verification.messageResend | translate }}
    <span
      (click)="reSendCode()"
      [innerHTML]="translate.verification.resend | translate"
      style="cursor: pointer; text-decoration: underline"
    >
    </span>
  </p>

  <p
    TextStyle
    fz-14
    fw-400
    pt-12
    pb-42
    m-0
    *ngIf="isDisabled"
    style="color: var(--error)"
    [innerHTML]="translate.verification.maximumAttempts | translate"
  ></p>
</div>
