<div class="city-back">
  <div class="img-wrapper">
    <img
      [src]="data.city.imageUrl"
      class="img-background"
      alt="city-background"
    />
    <div class="gradient-overlay"></div>
  </div>
  <div class="info-text">
    <p tcConcrete fz-12 fw-500 m-0 [ngClass]="{ latest: !isMobile }">
      Latest events in
    </p>
    <p tcMilk fz-22 fw-500 m-0 [ngClass]="{ cityName: !isMobile }">
      {{ data.city.name + ", " + data.city.state }}
    </p>
    <p tcMilk fz-14 fw-400 m-0 pb-16 *ngIf="!isMobile">
      {{
        "Austin's vibrant event scene features tech showcases, startup gatherings, and lifestyle events, reflecting its status as a rising center for innovation and creativity."
      }}
    </p>
    <div style="width: 7rem" *ngIf="!isMobile">
      <app-tcbutton [type]="TypesButton.tertiaryWhite" [round]="true">
        <app-tctext feature="g-events" key="events.subscribe"></app-tctext>
      </app-tcbutton>
    </div>
  </div>
</div>
