import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { Component } from '@angular/core';
import { Section } from '../../../events-all/pages/general-events/types';

@Component({
  selector: 'app-upcoming-section',
  templateUrl: './upcoming-section.component.html',
  styleUrls: ['./upcoming-section.component.scss'],
})
export class UpcomingSectionComponent {
  data!: Section;

  constructor(public toolbarNavigationService: NavigationService) {}

  eventDetail(id: string) {
    this.toolbarNavigationService.navigateToNewTab([
      `${EVENTS_ROUTES.Events}/${id}`,
    ]);
  }

  onCtaClick() {
    console.log('CTA clicked from parent!');
  }
}
