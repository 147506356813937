import { ModalService } from '@/app/libraries/main-layout/services/modal.service';
import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  QueryParam,
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import {
  tcCitiesParams,
  tcEventsParams,
} from '@/app/libraries/interfaces/data.interface';
import { PATH_EVENTS_SDUI } from '../../../constants';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { SelectLocationComponent } from '@/app/design-system/modals/select-location/select-location.component';
import {
  AfterViewInit,
  Component,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';
import { userData } from '@/app/features/authentication/constants';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';
import {
  GeneralEventsScreenName,
  GeneralEventsScreenTitleConfig,
} from './general-events.config';
import { AddEmailScreenName } from '@/app/features/user-profile/add-email/add-email.config';
import { ProfileScreenName } from '@/app/features/user-profile/profile/profile.config';
import { UsernameScreenName } from '@/app/features/user-profile/username/username.config';
import { SduiSection } from './sdui/sdui-section.interface';

@Component({
  selector: 'app-general-events',
  templateUrl: './general-events.component.html',
  styleUrls: ['./general-events.component.scss'],
})
export class GeneralEventsComponent implements OnInit, AfterViewInit {
  paramsCities!: tcCitiesParams;
  paramsEvents!: tcEventsParams;
  isLoading = true;
  @ViewChild(SelectLocationComponent) selectLocation!: SelectLocationComponent;
  isMobile = false;
  data!: ScreenTitleConfig;
  flow = '';
  dataUser!: userData;
  cities: any;
  events: any;
  sections: SduiSection[] = [];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private modalService: ModalService,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setEventsScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.flow = params['flow'] ? window.atob(params['flow']) : '';
    });

    const dataUser = this.authService.getItemStorage('dataUser');
    this.dataUser = dataUser ? JSON.parse(dataUser) : {};
  }

  ngOnInit() {
    forkJoin({
      sdui: this.httpMethodsService.getData(PATH_EVENTS_SDUI, {}),
    }).subscribe({
      next: (response: any) => {
        this.sections = response?.sdui?.body.sections;
        console.log(this.sections);

        /*  this.paramsCities = {
          cities: response?.sdui?.body?.sections[0]?.cities,
          headerData: GeneralEventsScreenTitleConfig.cities ?? {},
        };
        this.paramsEvents = {
          events: response?.sdui?.body?.sections[1]?.events,
          headerData: GeneralEventsScreenTitleConfig.events ?? {},
        }; */
        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
      },
    });
  }

  ngAfterViewInit(): void {
    if (!this.isMobile && !this.dataUser.is_registered) {
      const queryParams = {
        flow: window.btoa(this.flow),
      };

      const hasNonNumericCharacters = /\D/.test(this.dataUser.username ?? '');
      const needsUsername = () =>
        this.dataUser.username?.length === 0 || !hasNonNumericCharacters;
      const needsProfile = () =>
        this.dataUser.full_name?.length === 0 ||
        this.dataUser.birthdate?.length === 0;
      const needsEmail = () => this.dataUser.email?.length === 0;

      if (this.flow === QueryParam.flowEmail) {
        if (needsUsername()) {
          this.toolbarNavigationService.navigateForwardModal(
            UsernameScreenName,
            queryParams,
          );
        } else if (needsProfile()) {
          this.toolbarNavigationService.navigateForwardModal(
            ProfileScreenName,
            queryParams,
          );
        }
      } else if (this.flow === QueryParam.flowPhone) {
        if (needsEmail()) {
          this.toolbarNavigationService.navigateForwardModal(
            AddEmailScreenName,
            queryParams,
          );
        } else if (needsUsername()) {
          this.toolbarNavigationService.navigateForwardModal(
            UsernameScreenName,
            queryParams,
          );
        } else if (needsProfile()) {
          this.toolbarNavigationService.navigateForwardModal(
            ProfileScreenName,
            queryParams,
          );
        }
      }
    }
  }

  openLocationModal() {
    this.selectLocation.openModal();
  }

  handleconfirmlocationModal(resp: boolean) {
    console.log(resp);
  }

  setEventsScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: GeneralEventsScreenName,
      dataText: GeneralEventsScreenTitleConfig,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.FullScreenNoMargin,
    });
  }
}
