import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const VerificationScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-auth',
  title: 'verification.title',
  subtitle: 'verification.message',
  subtitlePhone: 'verification.messagePhone',
  continue: 'verification.continue',
  errorPost: 'verification.errorPost',
  userNotFound: 'userNotFound',
  invalidCode: 'invalidCode',
  codeExpired: 'codeExpired',
};

export const VerificationScreenName = 'verification';
