export interface ScreenConfiguration {
  name?: string;
  dataText?: object;
  errorMessage?: object;
  useToolbar: ToolbarOptions;
  useFooter: boolean;
  useHeader: boolean;
  presentation: ScreenPresentation;
}

export interface ToolbarOptions {
  state: boolean;
  push?: boolean;
  pop?: boolean;
  back?: boolean;
  close?: boolean;
  closeAll?: boolean;
  routeBack?: string;
  username?: boolean;
  actions?: boolean;
  present?: boolean;
  replace?: boolean;
  title?: {
    active: boolean;
  };
}

export interface ScreenTitleConfig {
  feature?: string;
  title?: string;
  subtitle?: string;
  CTA?: string;
  titleEmail?: string;
  titlePhone?: string;
  subtitleEmail?: string;
  subtitlePhone?: string;
  placeholder?: {
    key: string;
    keySecond?: string;
    keyTertiary?: string;
    keyQuaternary?: string;
  };
  details?: string;
  peopleGoing?: string;
  location?: string;
  message?: string;
  continue?: string;
  errorEmail?: string;
  errorPhone?: string;
  errorPost?: string;
  responseError?: string;
  userNotFound?: string;
  invalidCode?: string;
  codeExpired?: string;
  errorAddEmail?: string;
  userConflict?: string;
  errorUsername?: string;
  errorFullname?: string;
  errorBirthdate?: string;
  responseErrorAvatar?: string;
  events?: ScreenTitleConfig;
  cities?: ScreenTitleConfig;
}

export enum ScreenPresentation {
  WRAP = 'wrap',
  MODAL = 'modal',
  NARROW = 'narrow',
  FULLSCREEN = 'fullscreen',
  FULLSCREEN_NOMARGIN = 'fullScreenNoMargin',
}

export enum QueryParam {
  FLOW = 'flow',
  STOP_FLOW = 'stop flow',
  FLOW_EMAIL = '1',
  FLOW_PHONE = '2',
}

export enum ScreenNames {
  SIGNIN = 'signin',
  VERIFICATION = 'verification',
  EVENTS_DETAIL = 'eventsdetail',
}

export enum DirectionNames {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}
