import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ICON_CATALOG } from '@/app/libraries/icon/icon-catalog';
import { TypesButton } from './constants';

@Component({
  selector: 'app-tcbutton',
  templateUrl: './tcbutton.component.html',
  styleUrls: ['./tcbutton.component.scss'],
})
export class TCButtonComponent implements OnInit, OnChanges {
  @Input() type = TypesButton.primary;
  @Input() customBackground = TypesButton.primary;
  @Input() icon!: string;
  @Input() disabled = false;
  @Input() round = false;
  typeButton = '';
  iconPath = '';

  ngOnInit(): void {
    this.setTypeButton(this.type, this.round);
  }

  ngOnChanges() {
    this.iconPath = ICON_CATALOG[this.icon] || '';
  }

  setTypeButton(type: TypesButton, round: boolean) {
    this.typeButton = type;
    if (round) {
      this.typeButton = this.typeButton + ' ' + TypesButton.round;
    }
  }
}
