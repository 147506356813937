import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventsUpcomingScreenName } from './events-upcoming.config';
import { EventItem, UpcomingEventsResponse } from './types';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { PATH_EVENTS_UPCOMING } from '@/app/libraries/events/constants';
import { forkJoin, Subscription } from 'rxjs';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-events-upcoming',
  templateUrl: './events-upcoming.component.html',
  styleUrls: ['./events-upcoming.component.scss'],
})
export class EventsUpcomingComponent implements OnInit, OnDestroy {
  isLoading = true;
  upcoming!: EventItem[];
  isMobile = false;
  data!: ScreenTitleConfig;
  private languageSubscription!: Subscription;
  firstRequest = true;
  translate = TRANSLATIONS.events;

  constructor(
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setUpcomingScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  ngOnInit(): void {
    this.getData();

    this.languageSubscription = this.authService.languageChanged$.subscribe(
      () => {
        if (!this.firstRequest) {
          this.getData();
        }
      },
    );
  }

  getData() {
    forkJoin({
      upcoming: this.httpMethodsService.getData(PATH_EVENTS_UPCOMING, {}),
    }).subscribe({
      next: (response) => {
        const Response: UpcomingEventsResponse =
          response as UpcomingEventsResponse;
        this.upcoming = Response?.upcoming?.body?.sections[0]?.events ?? [];
        this.firstRequest = false;
        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  setUpcomingScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: EventsUpcomingScreenName,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.FULLSCREEN_NOMARGIN,
    });
  }
}
