import { LocalStorageStrategy } from './local-storage-strategy';
import { STORAGE_STRATEGY } from './storage-manager.service';
import { StorageStrategy } from './types/storage-strategy';

export const provideStorageManager = ({
  storageStrategy = LocalStorageStrategy,
}: { storageStrategy?: new () => StorageStrategy } = {}) => ({
  provide: STORAGE_STRATEGY,
  useClass: storageStrategy,
});
