<div class="container-pages">
  <ng-container *ngIf="isLoading">
    <app-auth-flow></app-auth-flow>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <app-toolbar
      w-100
      pt-12
      *ngIf="toolbarActions?.state"
      [toolbarConfig]="toolbarActions"
    ></app-toolbar>

    <div class="content-pages">
      <app-tc-title
        w-100
        pt-24
        pb-12
        [feature]="data.feature ?? ''"
        [key]="data.title ?? ''"
      ></app-tc-title>

      <p w-100 m-0 pb-24>
        <ng-container *ngIf="flow === QueryParam.flowEmail">
          <app-tc-title
            [type]="TypesTitles.subtitleGeneral"
            [feature]="data.feature ?? ''"
            [key]="data.subtitle ?? ''"
          ></app-tc-title>
          <strong TextStyle-B fz-14 style="text-transform: lowercase">{{
            email
          }}</strong>
        </ng-container>

        <ng-container *ngIf="flow === QueryParam.flowPhone">
          <app-tc-title
            [type]="TypesTitles.subtitleGeneral"
            [feature]="data.feature ?? ''"
            [key]="data.subtitlePhone ?? ''"
          ></app-tc-title>
          <strong TextStyle-B fz-14>{{ phoneNumber }}</strong>
        </ng-container>
      </p>

      <form [formGroup]="form" (keyup.enter)="onSubmit()">
        <app-otp-input
          w-100
          pb-88
          [data]="dataOTP"
          [isDisabled]="otpDisabled"
          [cleanOTP]="cleanOTP"
          (otpComplete)="onOtpComplete($event)"
        ></app-otp-input>

        <app-tcbutton
          pb-24
          [type]="TypesButton.primary"
          [disabled]="!form.valid || !otpValid || isLoading"
          (click)="onSubmit(); $event.preventDefault()"
        >
          <i class="pi pi-spinner pi-spin" *ngIf="isLoading"></i>
          <app-tctext
            [feature]="data.feature ?? ''"
            [key]="data.continue ?? ''"
          ></app-tctext>
        </app-tcbutton>
      </form>
    </div>
  </ng-container>
</div>
