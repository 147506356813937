import { ScreenTitleConfig } from '@/app/libraries/main-layout/constants/screen-configuration';

export const EventsDetailScreenTitleConfig: ScreenTitleConfig = {
  feature: 'g-events',
  title: 'events.title',
  CTA: 'events.CTA',
  details: 'events.details',
  peopleGoing: 'events.peopleGoing',
  location: 'events.location',
  message: 'events.message',
  continue: 'events.confirmInterest',
  responseError: 'responseErrorEventsDetail',
};

export const EventsDetailScreenName = 'eventsdetail';
export const EVENT_ID = 'eventId';
export const HIDDEN = 'hidden';
export const AUTO = 'auto';
