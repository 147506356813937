import { ICON_CATALOG } from '@/app/libraries/icon/icon-catalog';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-events-hero-carousel-section',
  templateUrl: './events-hero-carousel-section.component.html',
  styleUrls: ['./events-hero-carousel-section.component.scss'],
})
export class EventsHeroCarouselSectionComponent implements OnInit, OnChanges {
  @Input() params = {};
  system_cover = '';
  data: any;
  responsiveOptions!: {
    breakpoint: string;
    numVisible: number;
    numScroll: number;
  }[];

  constructor(private cdr: ChangeDetectorRef) {
    this.system_cover = ICON_CATALOG['system_cover'] || '';
  }

  ngOnInit() {
    this.responsiveOptions = [
      {
        breakpoint: '64rem',
        numVisible: 3,
        numScroll: 1,
      },
      {
        breakpoint: '48rem',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '26.875rem',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params']) {
      this.data = changes['params'].currentValue;
      if (this.data.events) {
        this.cdr.detectChanges();
      }
    }
  }

  onCtaClick() {
    console.log('CTA clicked from parent!');
    // Aquí puedes colocar la lógica que desees ejecutar, por ejemplo, una redirección o acción
  }
}
