import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Dialog } from 'primeng/dialog';
import { ICON_CATALOG } from '@/app/libraries/core/icon/icon-catalog';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent {
  @ViewChild(Dialog) dialog!: Dialog;
  @Input() type = 0;
  @Output() confirmModal = new EventEmitter<boolean>();
  displayModal = false;
  modalClosing = false;
  iconPath = '';
  iconPathNotification = '';
  TypesButton = TypesButton;
  translate = TRANSLATIONS.userProfile.profile;

  constructor() {
    this.iconPath = ICON_CATALOG['system_compass'] || '';
    this.iconPathNotification = ICON_CATALOG['system_notification'] || '';
  }

  openModal() {
    this.displayModal = true;
    this.modalClosing = false;
  }
  confirmPermissionsModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
    this.confirmModal.emit(true);
  }
  closeModal() {
    this.modalClosing = true;
    setTimeout(() => {
      this.displayModal = false;
      this.modalClosing = false;
    }, 400);
  }
}
