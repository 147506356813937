import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpClient } from './api-http-client.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpMethodsService {
  constructor(private apiHttpClient: ApiHttpClient) {}

  getData<T>(endpoint: string, params?: object): Observable<HttpResponse<T>> {
    return this.apiHttpClient.get<T>(endpoint, params);
  }

  postData<T>(endpoint: string, data: object): Observable<HttpResponse<T>> {
    return this.apiHttpClient.post<T>(endpoint, data);
  }

  putData<T>(endpoint: string, data: object): Observable<HttpResponse<T>> {
    return this.apiHttpClient.put<T>(endpoint, data);
  }
}
