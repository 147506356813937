import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ALL_EVENTS_ROUTES } from './events-all-routing.module';
import { EVENTS_UPCOMING } from '@/app/libraries/events/constants';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-events-all',
  templateUrl: './events-all.component.html',
  styleUrls: ['./events-all.component.scss'],
})
export class EventsAllComponent implements OnInit {
  routeOption = false;
  isMobile = false;
  isLoggedIn = false;
  translate = TRANSLATIONS.events;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      if (!isMobile) {
        this.routeGeneral();
      }
    });
    this.authService.isLoggedIn$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      const currentRoute = this.route.snapshot.firstChild?.routeConfig?.path;
      this.routeOption = currentRoute === EVENTS_UPCOMING;
    });
  }

  routeGeneral() {
    this.routeOption = false;
    this.toolbarNavigationService.navigateForward(
      [ALL_EVENTS_ROUTES.GeneralEvents],
      {},
    );
  }
  routeUpcoming() {
    this.routeOption = true;
    this.toolbarNavigationService.navigateForward(
      [ALL_EVENTS_ROUTES.EventsUpcoming],
      {},
    );
  }
}
