export interface SduiSection {
  id: string;
  title: string;
  type: string;
  [key: string]: any;
}

export const EVENTS_CITIES = 'EVENTS_CITIES';
export const EVENTS_SECTION = 'EVENTS_SECTION';
export const EVENTS_UPCOMING = 'EVENTS_UPCOMING';
export const CITY_BACK_SECTION = 'CITY_HEADER';

export interface eventTime {
  initial_date: string;
  final_date: string;
}
