<div class="info-logged-in">
  <app-tc-icon
    class="identity-avatar"
    [iconId]="userData?.profile_picture ?? 'system_avatar_default'"
  ></app-tc-icon>
  <ng-container *ngIf="!isMobile">
    <p fz-16 tcMilk TextStyle-B style="line-height: 1">
      {{
        userData?.username && (userData?.username)!.length > 0
          ? "@" + userData?.username
          : "Menu"
      }}
    </p>
    <app-tc-icon
      *ngIf="userData?.is_verified"
      class="identity-verified"
      [iconId]="'system_verified'"
    ></app-tc-icon>
  </ng-container>
</div>
