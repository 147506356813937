<div class="section-container">
  <div class="section-item">
    <div class="section-content">
      <h3
        m-0
        tc-title
        *ngIf="title"
        [desktopMode]="!isMobile"
        [tcTitle]="TypesTitles.SECTION"
        [innerHTML]="data.title ?? '' | translate"
      ></h3>
    </div>
    <app-tc-cta
      *ngIf="ctaLabel"
      [desktopMode]="!isMobile"
      [title]="''"
    ></app-tc-cta>
  </div>
  <h3
    m-0
    tc-title
    *ngIf="subtitle"
    [desktopMode]="!isMobile"
    [tcTitle]="TypesTitles.SECTION"
    [innerHTML]="'' | translate"
  ></h3>
</div>
