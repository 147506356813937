import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsernameRoutingModule } from './username-routing.module';
import { UsernameComponent } from './username.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from '../../../libraries/main-layout/services/modal.service';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';

@NgModule({
  declarations: [UsernameComponent],
  imports: [
    CommonModule,
    UsernameRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    ReactiveFormsModule,
  ],
})
export class UsernameModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('username', () =>
      import('./username.component').then((m) => m.UsernameComponent),
    );
  }
}
