import { ICON_CATALOG } from '@/app/libraries/icon/icon-catalog';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tc-icon',
  templateUrl: './tc-icon.component.html',
  styleUrls: ['./tc-icon.component.scss'],
})
export class TcIconComponent implements OnChanges {
  @Input() iconId!: string;
  iconSrc = '';

  ngOnChanges() {
    this.iconSrc = ICON_CATALOG[this.iconId] || '';
  }
}
