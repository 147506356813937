import { Component, Input } from '@angular/core';
import { ICON_CATALOG } from '@/app/libraries/icon/icon-catalog';
import { Router } from '@angular/router';
import { EVENTS_ROUTES } from '@/app/features/events/events-routing.module';
import { ToolbarNavigationService } from '@/app/design-system/molecules/toolbar/toolbar-navigation.service';

@Component({
  selector: 'app-tc-event-hero-card',
  templateUrl: './tc-event-hero-card.component.html',
  styleUrls: ['./tc-event-hero-card.component.scss'],
})
export class TcEventHeroCardComponent {
  @Input() dateTime!: string;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;
  @Input() eventInfo!: string;
  @Input() upcoming = false;

  system_event_upcoming = '';

  constructor(
    private router: Router,
    private toolbarNavigationService: ToolbarNavigationService,
  ) {
    this.system_event_upcoming = ICON_CATALOG['system_event_upcoming'] || '';
  }

  redirectUpcoming() {
    this.toolbarNavigationService.navigateForward(
      [EVENTS_ROUTES.EVENTS + '/' + EVENTS_ROUTES.EVENTS_DETAIL],
      {},
      null,
    );
  }
}
