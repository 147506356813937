<div class="content-pages">
  <div class="location-bar" w-100 pb-28>
    <div class="cont" (click)="openLocationModal()">
      <app-tc-icon [iconId]="'system_pin'"></app-tc-icon>
      <app-tctext
        class="text-location"
        TextStyle
        fz-12
        fw-400
        m-0
        feature="g-events"
        key="all.location"
        style="color: var(--racing)"
      ></app-tctext>
      <app-tc-icon
        class="chev-down"
        [iconId]="'system_chevron_down'"
      ></app-tc-icon>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <app-general-events-skeleton w-100></app-general-events-skeleton>
  </ng-container>

  <ng-container>
    <div *ngFor="let section of sections">
      <ng-container [appDynamicSection]="section"></ng-container>
    </div>
    <!-- <app-cities-section w-100 [params]="paramsCities"></app-cities-section> -->
    <!-- <app-events-section w-100 [params]="paramsEvents"></app-events-section> -->
  </ng-container>
</div>

<app-select-location
  #selectLocation
  [type]="1"
  (confirmModal)="handleconfirmlocationModal($event)"
>
</app-select-location>
