import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CarouselModule } from 'primeng/carousel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { LibrariesModule } from '../libraries/Libraries.module';

import { TCButtonComponent } from './atoms/button/tcbutton.component';
import { TcCtaComponent } from './atoms/cta/tc-cta.component';
import { TcIconComponent } from './atoms/icon/tc-icon.component';
import { TCInputComponent } from './atoms/input/tcinput.component';
import { OtpInputComponent } from './atoms/otp-input/otp-input.component';
import { TcTitleComponent } from './atoms/title/tc-title.component';
import { TCTextComponent } from './atoms/text/text/tctext.component';
import { InfoCardComponent } from './molecules/info-card/info-card.component';

import { FooterComponent } from './molecules/footer/footer.component';
import { HeaderComponent } from './molecules/header/header.component';
import { ToolbarComponent } from './molecules/toolbar/toolbar.component';
import { TcHeaderComponent } from './molecules/header-section/tc-header.component';
import { TcIdentityComponent } from './molecules/identity/tc-identity.component';

import { LoadingSkeletonComponent } from './loading-skeleton/loading-skeleton.component';
import { AuthFlowComponent } from './loading-skeleton/templates/auth-flow/auth-flow.component';
import { AuthViewComponent } from './loading-skeleton/templates/auth-view/auth-view.component';
import { GeneralEventsSkeletonComponent } from './loading-skeleton/templates/general-events-skeleton/general-events-skeleton.component';

import { SelectLocationComponent } from './modals/select-location/select-location.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { EditPictureComponent } from './modals/edit-picture/edit-picture.component';
import { PermissionsComponent } from './modals/permissions/permissions.component';
import { ModalExternalUrlComponent } from './modals/modal-external-url/modal-external-url.component';
import { EventsDetailSkeletonComponent } from './loading-skeleton/templates/events-detail-skeleton/events-detail-skeleton.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    TCButtonComponent,
    TcCtaComponent,
    TcIconComponent,
    TCInputComponent,
    OtpInputComponent,
    TcTitleComponent,
    TCTextComponent,

    HeaderComponent,
    FooterComponent,
    ToolbarComponent,
    TcHeaderComponent,
    TcIdentityComponent,
    InfoCardComponent,

    LoadingSkeletonComponent,
    AuthFlowComponent,
    AuthViewComponent,
    GeneralEventsSkeletonComponent,
    EventsDetailSkeletonComponent,

    SelectLocationComponent,
    ErrorModalComponent,
    EditPictureComponent,
    PermissionsComponent,
    ModalExternalUrlComponent,
  ],
  imports: [
    CommonModule,
    LibrariesModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    CarouselModule,
    DialogModule,
    DynamicDialogModule,
  ],
  exports: [
    TCButtonComponent,
    TcCtaComponent,
    TcIconComponent,
    TCInputComponent,
    OtpInputComponent,
    TcTitleComponent,
    TCTextComponent,
    InfoCardComponent,

    HeaderComponent,
    FooterComponent,
    ToolbarComponent,
    TcHeaderComponent,
    TcIdentityComponent,

    LoadingSkeletonComponent,
    AuthFlowComponent,
    AuthViewComponent,
    GeneralEventsSkeletonComponent,
    EventsDetailSkeletonComponent,

    SelectLocationComponent,
    ErrorModalComponent,
    EditPictureComponent,
    PermissionsComponent,
    ModalExternalUrlComponent,
  ],
})
export class DesignSystemModule {}
