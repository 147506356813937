import { Component, Input, signal, computed } from '@angular/core';
import { UserData } from '@/app/features/authentication/types';

@Component({
  selector: 'app-tc-identity',
  templateUrl: './tc-identity.component.html',
  styleUrls: ['./tc-identity.component.scss'],
})
export class TcIdentityComponent {
  userDataSignal = signal<UserData | null>(null);
  isMobileSignal = signal(false);
  isDropUserSignal = signal(false);

  @Input() set userData(value: UserData | null) {
    this.userDataSignal.set(value);
  }
  get userData(): UserData | null {
    return this.userDataSignal();
  }

  @Input() set isMobile(value: boolean) {
    this.isMobileSignal.set(value);
  }
  get isMobile(): boolean {
    return this.isMobileSignal();
  }

  @Input() set isDropUser(value: boolean) {
    this.isDropUserSignal.set(value);
  }
  get isDropUser(): boolean {
    return this.isDropUserSignal();
  }

  usernameWithPrefix = computed(() => {
    const username = this.userDataSignal()?.username;
    return username && /[^\d\s]/.test(username) ? '@' + username : 'Menu';
  })();
}
