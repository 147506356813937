import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss'],
})
export class LoadingSkeletonComponent {
  @Input() type:
    | 'card'
    | 'card-slim'
    | 'card-v'
    | 'form'
    | 'form-label'
    | 'input'
    | 'circle'
    | 'label'
    | 'image'
    | 'image-alt'
    | 'avatar' = 'card';
  @Input() count = 3;
}
