<div>
  <ng-container *ngIf="isLoading">
    <app-events-detail-skeleton w-100></app-events-detail-skeleton>
  </ng-container>

  <div class="event-container" *ngIf="!isLoading">
    <div class="event-layout">
      <!-- left column (40%) -->
      <div class="left-column">
        <div class="box1">
          <!-- Image content -->
          <div
            class="image-container"
            [class.expanded]="isExpanded"
            (click)="toggleExpand()"
          >
            <img [src]="eventDetail.thumbnail" alt="event-image" />
            <div class="overlay">
              <span *ngIf="!isExpanded" class="expand-text"
                >Click to expand</span
              >
            </div>
            <button
              *ngIf="isExpanded"
              class="close-button"
              (click)="toggleExpand(); $event.stopPropagation()"
              aria-label="Close expanded image"
            >
              <app-tc-icon
                [iconId]="'system_times'"
                class="close-icon-expanded"
              ></app-tc-icon>
            </button>
          </div>
        </div>

        <!-- <div class="box4">
          <app-tcbutton [type]="TypesButton.primary">
            <app-tctext feature="g-events" key="events.confirmInterest"></app-tctext>
          </app-tcbutton>
        </div> -->

        <div class="box5" *ngIf="eventDetail.faces.length > 0">
          <!-- attendees content -->
          <ng-container>
            <h3
              w-100
              fz-16
              fw-700
              m-0
              style="color: var(--milk); display: inline"
            >
              {{ eventDetail.faces.length + " " }}
            </h3>
            <app-tc-title
              w-100
              m-0
              tcMilk
              [type]="TypesTitles.section"
              [desktopMode]="!isMobile"
              [feature]="data.feature ?? ''"
              [key]="data.peopleGoing ?? ''"
            ></app-tc-title>
          </ng-container>
          <div class="attendees" w-100>
            <app-tc-icon
              *ngFor="let face of eventDetail.faces"
              [iconId]="face"
              class="attendee-avatar"
            ></app-tc-icon>
          </div>
        </div>

        <div class="box6">
          <!-- location content -->
          <app-tc-title
            w-100
            m-0
            tcMilk
            [type]="TypesTitles.section"
            [desktopMode]="!isMobile"
            [feature]="data.feature ?? ''"
            [key]="data.location ?? ''"
          ></app-tc-title>
          <div class="event-details">
            <img
              [src]="eventDetail.address.static_image"
              class="map-image"
              (click)="
                openGoogleMaps(
                  eventDetail.address.latitude,
                  eventDetail.address.longitude
                )
              "
            />
            <app-tc-icon
              class="icon-pin"
              [iconId]="'system_pin_default'"
              (click)="
                openGoogleMaps(
                  eventDetail.address.latitude,
                  eventDetail.address.longitude
                )
              "
            ></app-tc-icon>
          </div>
        </div>
      </div>

      <!-- right column (60%) -->
      <div class="right-column">
        <div class="box2">
          <!-- header content -->
          <div class="event-header">
            <app-tc-identity
              *ngIf="eventDetail.organizer"
              [userData]="dataUser"
            ></app-tc-identity>
            <h3
              *ngIf="!eventDetail.organizer && eventDetail.external_organizer"
              TextStyle
              w-100
              fz-16
              fw-400
              m-0
              tcAsh
              class="organizer-name"
            >
              {{ eventDetail.external_organizer }}
            </h3>
            <!-- <div d-flex style="gap: 20px">
              <app-tc-icon
                fz-24
                (click)="redirectToGoogleCalendar()"
                [iconId]="'system_calendar_dates'"
              ></app-tc-icon>
              <app-tc-icon 
                fz-24
                [iconId]="'system_share_light'"
                ></app-tc-icon>
            </div> -->
          </div>
          <h3 w-100 m-0 tcMilk fw-500 fz-36 TextStyle class="event-name">
            {{ eventDetail.name }}
          </h3>
          <div
            *ngIf="eventDetail.url"
            class="url-container"
            style="margin-top: var(--size-8)"
          >
            <app-tcbutton
              [type]="TypesButton.roundSmoke"
              icon="system_link_ghost"
              [round]="true"
              tcGhost
              (click)="navigationService.navigateToUrl(eventDetail.url)"
            >
              <p
                class="url-text"
                style="
                  width: auto;
                  overflow: hidden;
                  margin-left: -0.5rem;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ eventDetail.url }}
              </p>
            </app-tcbutton>
          </div>
        </div>

        <div class="box3">
          <!-- event info content -->
          <app-tc-title
            w-100
            m-0
            tcMilk
            [type]="TypesTitles.section"
            [desktopMode]="!isMobile"
            [feature]="data.feature ?? ''"
            [key]="data.details ?? ''"
          ></app-tc-title>
          <app-info-card
            [iconId]="'system_calendar_dates'"
            [date]="
              initialDateValues.dayOfWeek +
              ', ' +
              initialDateValues.month +
              ' ' +
              initialDateValues.day +
              'th'
            "
            [time]="
              initialDateValues.localTime + ' - ' + finalDateValues.localTime
            "
          >
          </app-info-card>
          <app-info-card
            [iconId]="'system_ubication'"
            [date]="''"
            [time]="eventDetail.address.formatted_address"
          >
          </app-info-card>
        </div>

        <div class="box7">
          <!-- description component -->
          <app-tc-title
            w-100
            m-0
            tcMilk
            [type]="TypesTitles.section"
            [desktopMode]="!isMobile"
            [feature]="data.feature ?? ''"
            [key]="data.message ?? ''"
          ></app-tc-title>
          <p
            w-100
            fz-14
            m-0
            tcMilk
            class="justified-text"
            class="description-text"
            [innerHTML]="textService.highlightLinks(eventDetail.description)"
          ></p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div return-icon pt-32 class="returnIcon">
    <app-tc-icon [iconId]="'system_arrowBack'" (click)="return()"></app-tc-icon>
  </div> -->
