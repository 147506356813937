import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from './auth-service.service';
import { Router } from '@angular/router';
import { AUTH_ROUTES } from '@/app/features/authentication/authentication-routing.module';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    const authToken = this.authService.getAuthToken();
    const clonedRequest = this.addAuthHeader(req, authToken);

    return next.handle(clonedRequest).pipe();
  }

  private addAuthHeader<T>(
    req: HttpRequest<T>,
    token: string | null,
  ): HttpRequest<T> {
    if (token) {
      return req.clone({
        setHeaders: {
          Authorization: `Token ${token}`,
        },
      });
    }
    return req;
  }

  private handle401Error<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((token): token is string => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addAuthHeader(req, token))),
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((newToken: string) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(newToken);
          return next.handle(this.addAuthHeader(req, newToken));
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.error(err);

          this.router.navigate([AUTH_ROUTES.LOGIN]);
          return throwError(() => err);
        }),
      );
    }
  }
}
