<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '100%' }"
  [draggable]="false"
  [resizable]="false"
  [showHeader]="false"
  [styleClass]="modalClosing ? 'permission-modal closing' : 'permission-modal'"
>
  <div class="modal-content">
    <div pb-24 w-100 class="close">
      <app-tc-icon
        [iconId]="'system_close_modal'"
        (click)="closeModal()"
      ></app-tc-icon>
    </div>

    <img [src]="type === 2 ? iconPath : iconPathNotification" alt="" />

    <app-tctext
      TextStyle-B
      w-100
      fz-22
      fw-500
      pt-36
      pb-16
      m-0
      style="color: var(--milk)"
      feature="g-user"
      [key]="
        type === 1
          ? 'profile.titlePermissionsCamera'
          : type === 2
            ? 'profile.titlePermissionsLocation'
            : type === 3
              ? 'profile.titlePermissionsNotification'
              : ''
      "
    ></app-tctext>

    <app-tctext
      TextStyle-L
      w-100
      fz-14
      fw-400
      pb-64
      m-0
      style="color: var(--milk)"
      feature="g-user"
      [key]="
        type === 1
          ? 'profile.subtitlePermissionsCamera'
          : type === 2
            ? 'profile.subtitlePermissionsLocation'
            : type === 3
              ? 'profile.subtitlePermissionsNotification'
              : ''
      "
    ></app-tctext>

    <app-tctext
      TextStyle-L
      w-100
      fz-12
      fw-400
      pb-24
      m-0
      style="color: var(--ghost)"
      feature="g-user"
      key="profile.adds"
    ></app-tctext>

    <app-tcbutton
      [type]="TypesButton.primary"
      [disabled]="false"
      (click)="confirmPermissionsModal()"
    >
      <app-tctext feature="g-user" key="profile.allow"></app-tctext>
    </app-tcbutton>
  </div>
</p-dialog>
