import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainLayoutRoutingModule } from './main-layout-routing.module';
import { MainLayoutComponent } from './main-layout.component';
import { LibrariesModule } from '@/app/libraries/Libraries.module';
import { DesignSystemModule } from '@/app/design-system/DesignSystem.module';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATION_PLUGIN } from '@/app/libraries/core/translations/translation-config';
import { HeaderComponent } from '../ui/header/header.component';
import { FooterComponent } from '../ui/footer/footer.component';
import { ModalService } from '@/app/libraries/core/navigation/modal.service';
import { LanguageDropdownComponent } from '../ui/components/language-dropdown/language-dropdown.component';
import { UserProfileComponent } from '../ui/components/user-profile/user-profile.component';
import { UserLanguageComponent } from '../ui/components/user-language/user-language.component';
import { UserConfigComponent } from '../ui/components/user-config/user-config.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    LanguageDropdownComponent,
    UserProfileComponent,
    UserLanguageComponent,
    UserConfigComponent,
  ],
  imports: [
    CommonModule,
    MainLayoutRoutingModule,
    LibrariesModule,
    DesignSystemModule,
    DialogModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: TRANSLATION_PLUGIN,
      useValue: [
        {
          module: 'layout',
        },
      ],
      multi: true,
    },
  ],
})
export class MainLayoutModule {
  constructor(private modalService: ModalService) {
    this.modalService.registerModal('langdropdown', () =>
      import(
        '../ui/components/language-dropdown/language-dropdown.component'
      ).then((m) => m.LanguageDropdownComponent),
    );
    this.modalService.registerModal('userprofile', () =>
      import('../ui/components/user-profile/user-profile.component').then(
        (m) => m.UserProfileComponent,
      ),
    );
  }
}
