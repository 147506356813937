import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventsUpcomingScreenName } from './events-upcoming.config';
import { HttpMethodsService } from '@/app/libraries/interfaces/http-methods/services/http-methods-service.service';
import { PATH_EVENTS_UPCOMING } from '../../../constants';
import { forkJoin, Subscription } from 'rxjs';
import { ErrorModalService } from '@/app/design-system/modals/error-modal/services/error-modal.service';
import { AuthService } from '@/app/libraries/interfaces/http-methods/services/auth-service.service';

@Component({
  selector: 'app-events-upcoming',
  templateUrl: './events-upcoming.component.html',
  styleUrls: ['./events-upcoming.component.scss'],
})
export class EventsUpcomingComponent implements OnInit, OnDestroy {
  isLoading = true;
  upcoming: any;
  isMobile = false;
  data!: ScreenTitleConfig;
  private languageSubscription!: Subscription;

  constructor(
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setUpcomingScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  ngOnInit(): void {
    this.getData();

    this.languageSubscription = this.authService.languageChanged$.subscribe(
      () => {
        this.getData();
      },
    );
  }

  getData() {
    forkJoin({
      upcoming: this.httpMethodsService.getData(PATH_EVENTS_UPCOMING, {}),
    }).subscribe({
      next: (response: any) => {
        this.upcoming = response?.upcoming?.body.sections[0].events;
        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  setUpcomingScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: EventsUpcomingScreenName,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.FullScreenNoMargin,
    });
  }
}
