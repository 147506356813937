import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { DirectionNames } from '../constants/screen-configuration';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private previousUrl = '';
  private currentUrl = '';
  private direction: DirectionNames.FORWARD | DirectionNames.BACKWARD =
    DirectionNames.FORWARD;

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.currentUrl = this.router.url;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        this.direction = this.location.isCurrentPathEqualTo(this.previousUrl)
          ? DirectionNames.BACKWARD
          : DirectionNames.FORWARD;
      }
    });
  }

  getDirection(): DirectionNames.FORWARD | DirectionNames.BACKWARD {
    return this.direction;
  }

  navigateToUrl(link: string) {
    window.open(link, '_blank');
  }
}
