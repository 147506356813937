import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tc-event-card',
  templateUrl: './tc-event-card.component.html',
  styleUrls: ['./tc-event-card.component.scss'],
})
export class TcEventCardComponent {
  @Input() imageUrl!: string;
  @Input() eventTime!: string;
  @Input() eventTitle!: string;
  @Input() eventLocation!: string;
  @Input() eventCategory!: string;
  @Input() participants!: number;
}
