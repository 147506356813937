<div class="content-pages">
  <app-loading-skeleton
    w-100
    [type]="'card-v'"
    [count]="3"
    *ngIf="isLoading"
  ></app-loading-skeleton>
  <ng-container *ngIf="!isLoading && upcoming.length === 0">
    <app-tc-icon
      style="margin-left: -3rem; padding: 1.5rem 0.75rem 0.75rem"
      [iconId]="'system_grid_stack'"
    ></app-tc-icon>
    <p
      fz-16
      fw-400
      m-0
      pb-24
      text-center
      TextStyle-L
      style="margin-left: -3rem"
    >
      {{ translate.all.textUpcoming | translate }}
    </p>
  </ng-container>
  <div class="content-cards" *ngIf="!isLoading" w-100>
    <ng-container *ngFor="let section of upcoming">
      <app-tc-event-hero-card
        w-100
        [id]="section.id"
        [image]="section.imageUrl"
        [dateTime]="{
          caption: section.caption,
          initial_date: section.initialDate,
          final_date: section.finalDate,
        }"
        [eventTitle]="section.title"
        [eventLocation]="section.subtitle"
        [eventInfo]="section.label"
        [upcoming]="true"
      ></app-tc-event-hero-card>
    </ng-container>
  </div>
</div>
