export enum LinkNames {
  TopCars = 'topCars',
  TermsConditions = 'terms & conditions',
  PrivacyPolicy = 'privacy policy',
  // Carreers = 'carreers',
}
export enum LinkTexts {
  TopCars = 'footer.topCars',
  TermsConditions = 'footer.termsConditions',
  PrivacyPolicy = 'footer.privacyPolicy',
  // Carreers = 'footer.Carreers',
}
export enum LinkRedirects {
  TopCars = 'https://topcars.io/',
  TermsConditions = 'https://topcars.io/terms',
  PrivacyPolicy = 'https://topcars.io/privacy-policy',
  // Carreers = 'footer.Carreers',
}

export enum SocialNetworkNames {
  X = 'X',
  YouTube = 'youtube',
  Facebook = 'facebook',
  Instagram = 'instagram',
  GooglePlay = 'google play',
}
export enum SocialNetworkIcons {
  X = 'system_x_social_media',
  YouTube = 'system_youtube',
  Facebook = 'system_facebook',
  Instagram = 'system_instagram',
  GooglePlay = 'system_googleplay',
}
export enum SocialNetworkRedirect {
  X = 'https://x.com/topcarsio',
  YouTube = 'https://www.youtube.com/@topcarsio',
  Facebook = 'https://facebook.com/topcars.io',
  Instagram = 'https://www.instagram.com/topcars.io/',
  GooglePlay = 'https://play.google.com/store/apps/details?id=io.topcars',
}
