import { Component, OnDestroy, OnInit } from '@angular/core';
import { SduiSection } from '@/app/libraries/events/sdui/sdui-section.interface';
import { ScreenSizeService } from '@/app/libraries/main-layout/services/screen-size.service';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { ScreenConfigService } from '@/app/libraries/main-layout/services/screen-config.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import {
  CITY_ID,
  CityDetailScreenName,
  CityDetailScreenTitleConfig,
} from './city-detail.config';
import { APIResponse } from './types';
import { forkJoin, Subscription } from 'rxjs';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import {
  PATH_CITIES,
  PATH_CITIES_DETAIL,
} from '@/app/libraries/events/constants';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import { APP_ROUTES } from '../../layout/main-layout/main-layout-routing.module';

@Component({
  selector: 'app-city-detail',
  templateUrl: './city-detail.component.html',
  styleUrls: ['./city-detail.component.scss'],
})
export class CityDetailComponent implements OnInit, OnDestroy {
  isLoading = true;
  isMobile = false;
  eventId!: string;
  data!: ScreenTitleConfig;
  sections: SduiSection[] = [];
  private languageSubscription!: Subscription;
  firstRequest = true;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    private screenConfigService: ScreenConfigService,
    public toolbarNavigationService: NavigationService,
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.setCityDetailScreenConfig();
    this.screenConfigService.config$.subscribe((config) => {
      if (config) {
        this.data = { ...config?.dataText };
      }
    });
  }

  ngOnInit() {
    this.getData();

    this.languageSubscription = this.authService.languageChanged$.subscribe(
      () => {
        if (!this.firstRequest) {
          this.getData();
        }
      },
    );
  }

  getData() {
    this.eventId = this.route.snapshot.paramMap.get(CITY_ID) ?? 'null';
    const path = PATH_CITIES + `${this.eventId}` + PATH_CITIES_DETAIL;
    forkJoin({
      sdui: this.httpMethodsService.getData(path, {}),
    }).subscribe({
      next: (response) => {
        const Response: APIResponse = response as APIResponse;
        this.sections = Response?.sdui?.body.sections;
        this.firstRequest = false;
        this.isLoading = false;
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseError,
        });
        this.toolbarNavigationService.navigateForward(
          [APP_ROUTES.EVENTS],
          {},
          null,
        );
      },
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  setCityDetailScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: CityDetailScreenName,
      dataText: CityDetailScreenTitleConfig,
      useToolbar: {
        state: false,
      },
      useFooter: true,
      useHeader: true,
      presentation: ScreenPresentation.NARROW,
    });
  }
}
