import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Directive({
  selector: '[appSvgIcon]',
})
export class SvgIconDirective implements OnChanges {
  @Input() appSvgIcon!: string;
  @Input() strokeColor!: string;
  @Input() fillColor!: string;
  @Input() strokeOpacity = 1;

  constructor(
    private el: ElementRef,
    private http: HttpClient,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appSvgIcon'] && this.appSvgIcon) {
      this.loadSvg();
    } else if (
      changes['strokeColor'] ||
      changes['fillColor'] ||
      changes['strokeOpacity']
    ) {
      this.applyStylesToSvg();
    }
  }

  private loadSvg() {
    this.http
      .get(this.appSvgIcon, { responseType: 'text' })
      .subscribe((data) => {
        this.el.nativeElement.innerHTML = data;
        this.applyStylesToSvg();
      });
  }

  private applyStylesToSvg() {
    const svgElement = this.el.nativeElement.querySelector('svg');

    if (svgElement) {
      const elements = svgElement.querySelectorAll(
        'path, circle, rect, line, polygon, polyline, ellipse',
      );
      elements.forEach((el: unknown) => {
        this.renderer.setStyle(
          el,
          'stroke',
          this.strokeColor || 'currentColor',
        );
        this.renderer.setStyle(el, 'fill', this.fillColor || 'none');
        this.renderer.setStyle(
          el,
          'stroke-opacity',
          this.strokeOpacity.toString(),
        );
      });
    }
  }
}
