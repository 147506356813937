<ng-container *ngIf="isLink && linkUrl; else textTemplate">
  <a
    [href]="linkUrl"
    target="_blank"
    rel="noopener noreferrer"
    [innerHTML]="safeTextValue"
  ></a>
</ng-container>
<ng-template #textTemplate>
  <span [innerHTML]="safeTextValue"></span>
</ng-template>
