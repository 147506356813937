import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appLettersOnly]',
})
export class LettersOnlyDirective {
  // Regex for letters and spaces
  private regex = new RegExp(/^[a-zA-Z\s]+$/);

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    const inputElement = event.target as HTMLInputElement;

    // Prevent leading spaces
    if (inputElement.value.length === 0 && inputChar === ' ') {
      event.preventDefault();
    }

    // Prevent any non-letter or non-space character
    if (!this.regex.test(inputChar)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text') || '';

    // Extract only letters and spaces from pasted text
    const lettersOnly = pastedInput.replace(/[^a-zA-Z\s]/g, '');

    if (lettersOnly) {
      event.preventDefault();
      const inputElement = event.target as HTMLInputElement;

      // Insert the filtered letters into the input
      const currentValue = inputElement.value;
      const cursorPos = inputElement.selectionStart || 0;

      inputElement.value =
        currentValue.substring(0, cursorPos) +
        lettersOnly +
        currentValue.substring(cursorPos);

      // Move the cursor after pasted letters
      inputElement.setSelectionRange(
        cursorPos + lettersOnly.length,
        cursorPos + lettersOnly.length,
      );
    } else {
      event.preventDefault();
    }
  }
}
