export enum LinkNames {
  TOP_CARS = 'topCars',
  TERMS_CONDITIONS = 'terms & conditions',
  PRIVACY_POLICY = 'privacy policy',
  // CARREERS = 'carreers',
}
export enum LinkTexts {
  TOP_CARS = 'topCars',
  TERMS_CONDITIONS = 'termsConditions',
  PRIVACY_POLICY = 'privacyPolicy',
  // CARREERS = 'Carreers',
}
export enum LinkRedirects {
  TOP_CARS = 'https://topcars.io/',
  TERMS_CONDITIONS = 'https://topcars.io/terms',
  PRIVACY_POLICY = 'https://topcars.io/privacy-policy',
  // CARREERS = 'Carreers',
}

export enum SocialNetworkNames {
  X = 'X',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  GOOGLE_PLAY = 'google play',
}
export enum SocialNetworkIcons {
  X = 'system_x_social_media',
  YOUTUBE = 'system_youtube',
  FACEBOOK = 'system_facebook',
  INSTAGRAM = 'system_instagram',
  GOOGLE_PLAY = 'system_googleplay',
}
export enum SocialNetworkRedirect {
  X = 'https://x.com/topcarsio',
  YOUTUBE = 'https://www.youtube.com/@topcarsio',
  FACEBOOK = 'https://facebook.com/topcars.io',
  INSTAGRAM = 'https://www.instagram.com/topcars.io/',
  GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=io.topcars',
}
