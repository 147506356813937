import { Injectable, InjectionToken, Inject } from '@angular/core';
import { StorageStrategy } from './types/storage-strategy';

export const STORAGE_STRATEGY = new InjectionToken<StorageStrategy>(
  'STORAGE_STRATEGY',
);

@Injectable({
  providedIn: 'root',
})
export class StorageManagerService {
  private storage: StorageStrategy;

  constructor(@Inject(STORAGE_STRATEGY) storage: StorageStrategy) {
    this.storage = storage;
  }

  setStrategy(storage: StorageStrategy): void {
    this.storage = storage;
  }

  get<T>(key: string): T | null {
    return this.storage.get(key);
  }

  set<T>(key: string, value: T): void {
    return this.storage.set(key, value);
  }

  remove(key: string): void {
    this.storage.remove(key);
  }

  clear(): void {
    this.storage.clear();
  }
}
