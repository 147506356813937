import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ValidatorService } from 'src/app/libraries/core/validator-interface/services/validator.service';
import { ICON_CATALOG } from '@/app/libraries/core/icon/icon-catalog';
import { EditPictureComponent } from '@/app/features/user-profile/ui/modals/edit-picture/edit-picture.component';
import { PermissionsComponent } from '@/app/features/user-profile/ui/modals/permissions/permissions.component';
import { ScreenConfigService } from '../../../libraries/main-layout/services/screen-config.service';
import { ScreenSizeService } from '../../../libraries/main-layout/services/screen-size.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalService } from '../../../libraries/core/navigation/modal.service';
import { HttpMethodsService } from '@/app/libraries/core/http-methods/services/http-methods-service.service';
import { ErrorModalService } from '@/app/topcars-ui/modals/error-modal/error-modal.service';
import { PATH_AVATAR, PATH_USER } from '@/app/features/user-profile/constants';
import {
  ApiResponse,
  ParamsFormat,
  UserParams,
} from '@/app/features/user-profile/types';
import { NavigationService } from '@/app/libraries/core/navigation/navigation.service';
import { UserData } from '@/app/features/authentication/types';
import { AuthService } from '@/app/libraries/authentication/services/auth-service.service';
import {
  ScreenPresentation,
  ScreenTitleConfig,
  ToolbarOptions,
} from '@/app/libraries/main-layout/constants/screen-configuration';
import { TitleType } from '@/app/topcars-ui/title/types/title';
import { TypesButton } from '@/app/design-system/atoms/button/constants';
import { ProfileScreenName, ProfileScreenTitleConfig } from './profile.config';
import { UsernameScreenName } from '../username/username.config';
import { TRANSLATIONS } from '@/app/libraries/core/translations/constants/translations';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  flow = '';
  username = '';
  placeHolder = '';
  placeHolderB = '';
  currentRoute = '';
  system_image_default = '';
  system_cross_image = '';
  textUser = true;
  isMobile = false;
  isDesktop = false;
  imageUploaded = false;
  isUsernameInvalid = true;
  form!: FormGroup;
  imagePreview: string | ArrayBuffer | null = null;
  @ViewChild(EditPictureComponent) editPictureModal!: EditPictureComponent;
  @ViewChild(PermissionsComponent) permissionsModal!: PermissionsComponent;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  toolbarActions!: ToolbarOptions;
  isLoading = true;
  params!: ParamsFormat;
  TypesTitles = TitleType;
  TypesButton = TypesButton;
  data!: ScreenTitleConfig;
  dataUser: UserData | null = null;
  translate = TRANSLATIONS.userProfile;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private modalService: ModalService,
    private validatorService: ValidatorService,
    private screenSizeService: ScreenSizeService,
    private errorModalService: ErrorModalService,
    private httpMethodsService: HttpMethodsService,
    @Optional() private dialogRef: DynamicDialogRef,
    @Optional() private config: DynamicDialogConfig,
    private screenConfigService: ScreenConfigService,
    private toolbarNavigationService: NavigationService,
  ) {
    this.setProfileScreenConfig();
    setTimeout(() => {
      this.screenConfigService.config$.subscribe((config) => {
        if (config) {
          this.data = { ...config?.dataText };
          this.toolbarActions = config.useToolbar;
          this.cdr.detectChanges();
        }
      });
    }, 0);
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.detectDevice();
    this.form = this.fb.group({
      inputImage: [null, [Validators.required]],
      inputFullname: [null, [Validators.required, this.getValidator('name')]],
      inputBirthdate: [
        '',
        [Validators.required, this.getValidator('birthdate')],
      ],
    });

    this.inputImage?.valueChanges.subscribe((value) => {
      this.imageUploaded = !!value;
    });

    this.system_image_default = ICON_CATALOG['system_image_default'] || '';
    this.dataUser = this.authService.getDataUser();
  }

  get inputImage(): AbstractControl | null {
    return this.form.get('inputImage');
  }
  get inputFullname(): AbstractControl | null {
    return this.form.get('inputFullname');
  }
  get inputBirthdate(): AbstractControl | null {
    return this.form.get('inputBirthdate');
  }

  ngOnInit(): void {
    this.params = this.config?.data?.params;
    this.flow = this.params['flow'] ? window.atob(this.params['flow']) : '';
    if (this.params['username']) {
      this.username = this.params['username']
        ? window.atob(this.params['username'])
        : '';
    } else {
      this.username = this.dataUser?.username ? this.dataUser?.username : '';
    }

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  private getValidator(validatorKey: string): ValidatorFn {
    const validator = this.validatorService.getValidator(validatorKey);
    return (control: AbstractControl) => {
      const isValid = validator?.validate(control.value);
      return isValid ? null : { invalid: true };
    };
  }

  detectDevice(): void {
    const userAgent = navigator.userAgent || navigator.vendor;
    this.isMobile = /android|iphone|ipad|iPod/i.test(userAgent.toLowerCase());
    this.isDesktop = !this.isMobile;
  }

  openImageUploadModal() {
    this.editPictureModal.openModal();
  }

  handleTakePhoto() {
    this.openPermissionsModal();
  }

  openPermissionsModal() {
    this.permissionsModal.openModal();
  }

  handleConfirmPermissionsModal(value: boolean) {
    if (value) {
      if (this.isMobile) {
        this.openFileInput('camera');
      } else {
        this.openDesktopCamera();
      }
    }
  }
  openDesktopCamera() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Create a container for the video and the button
        const cameraContainer = document.createElement('div');
        cameraContainer.style.position = 'fixed';
        cameraContainer.style.top = '50%';
        cameraContainer.style.left = '50%';
        cameraContainer.style.transform = 'translate(-50%, -50%)';
        cameraContainer.style.zIndex = '9999';
        cameraContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        cameraContainer.style.padding = '20px';
        cameraContainer.style.borderRadius = '10px';

        // Create the video and set the stream
        const video = document.createElement('video');
        video.srcObject = stream;
        video.autoplay = true;
        video.style.width = '100%';
        video.style.borderRadius = '0.625rem';

        // Create the container for the button
        const buttonContainer = document.createElement('div');
        buttonContainer.style.display = 'flex';
        buttonContainer.style.justifyContent = 'center';
        buttonContainer.style.marginTop = '0.625rem';

        // Create the button to take the photo
        const takePictureButton = document.createElement('button');
        takePictureButton.id = 'takePicture';
        takePictureButton.style.width = '3.75rem';
        takePictureButton.style.height = '3.75rem';
        takePictureButton.style.border = '0.25rem solid white';
        takePictureButton.style.borderRadius = '50%';
        takePictureButton.style.backgroundColor = 'transparent';
        takePictureButton.style.position = 'relative';
        takePictureButton.style.cursor = 'pointer';
        takePictureButton.style.transition = 'transform 0.2s';
        takePictureButton.style.boxShadow =
          '0 0.25rem 0.5rem rgba(0, 0, 0, 0.2)';

        const square = document.createElement('div');
        square.style.width = '1.875rem';
        square.style.height = '1.875rem';
        square.style.backgroundColor = 'red';
        square.style.position = 'absolute';
        square.style.top = '50%';
        square.style.left = '50%';
        square.style.transform = 'translate(-50%, -50%)';

        takePictureButton.onmousedown = () => {
          takePictureButton.style.transform = 'scale(0.95)';
        };
        takePictureButton.onmouseup = () => {
          takePictureButton.style.transform = 'scale(1)';
        };

        takePictureButton.onclick = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context?.drawImage(video, 0, 0);
          const imageData = canvas.toDataURL('image/png');

          this.handleImageFile(
            this.dataURLtoFile(imageData, 'captured-image.png'),
          );

          stream.getTracks().forEach((track) => track.stop());
          document.body.removeChild(cameraContainer);
        };

        takePictureButton.appendChild(square);

        buttonContainer.appendChild(takePictureButton);
        cameraContainer.appendChild(video);
        cameraContainer.appendChild(buttonContainer);
        document.body.appendChild(cameraContainer);
      })
      .catch((err) => {
        console.error('Error al acceder a la cámara: ', err);
      });
  }

  dataURLtoFile(dataUrl: string, filename: string): File {
    const arr = dataUrl?.split(',');
    const match = arr[0].match(/:(.*?);/);
    if (!match) {
      throw new Error('Invalid data URL format: MIME type not found');
    }
    const mime = match[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  handleImportFromGallery() {
    this.openFileInput('gallery');
  }

  openFileInput(source: 'camera' | 'gallery') {
    if (this.fileInput) {
      this.fileInput.nativeElement.accept = 'image/*';
      if (source === 'camera' && this.isMobile) {
        this.fileInput.nativeElement.setAttribute('capture', 'environment');
      } else {
        this.fileInput.nativeElement.removeAttribute('capture');
      }
      this.fileInput.nativeElement.click();
    }
  }

  handleRemoveCurrentPicture() {
    this.inputImage?.setValue(null);
    this.imagePreview = null;
    this.imageUploaded = false;
    this.editPictureModal.closeModal();
  }

  onFileChange(event: Event): void {
    event.preventDefault();
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.handleImageFile(file);
    }
  }

  handleImageFile(file: File): void {
    this.form.patchValue({ inputImage: file });
    this.form.get('inputImage')?.updateValueAndValidity();
    this.previewImage(file);
    this.imageUploaded = true;
    this.editPictureModal.closeModal();
  }

  previewImage(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  handlecloseModal() {
    // No need to handle video stream here as we're not using it
  }

  actionToDo(action: string, control: string) {
    if (action == 'clear') {
      if (control == 'name') {
        this.inputFullname?.setValue('');
        this.inputFullname?.updateValueAndValidity();
      } else {
        this.inputBirthdate?.setValue('');
        this.inputBirthdate?.updateValueAndValidity();
      }
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const params: UserParams = {
        username: this.username,
        full_name: this.inputFullname?.value,
        birthdate: this.inputBirthdate?.value,
      };
      this.httpServicesPost(params);
    }
  }
  httpServicesPost(params: object) {
    this.isLoading = true;
    const formData = new FormData();
    formData.append(
      'profile_picture',
      this.inputImage?.value,
      this.inputImage?.value.name,
    );
    /* formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    }); */
    const path = PATH_USER + `${this.dataUser?.id}` + PATH_AVATAR;
    this.httpMethodsService.putData(path, formData).subscribe({
      next: (response) => {
        const Response: ApiResponse = response as ApiResponse;
        if (Response.status === 200 || Response.status === 201) {
          this.authService.updateDataUser({
            profile_picture: Response.body.profile_picture,
          });
          this.httpMethodsService
            .patchData(PATH_USER + `${this.dataUser?.id}/`, params)
            .subscribe({
              next: (responseData) => {
                const ResponseUser: ApiResponse = responseData as ApiResponse;
                if (
                  ResponseUser.status === 200 ||
                  ResponseUser.status === 201
                ) {
                  this.authService.updateDataUser({
                    full_name: ResponseUser.body.full_name,
                    birthdate: ResponseUser.body.birthdate,
                  });
                  this.isLoading = false;
                  this.modalService.closeAllModals(true);
                }
              },
              error: async (error) => {
                await this.errorModalService.showError(
                  error.status === 409
                    ? {
                        feature: this.data.feature,
                        key: this.data.userConflict,
                      }
                    : {
                        feature: this.data.feature,
                        key: this.data.responseError,
                      },
                );
                this.isLoading = false;
              },
            });
        }
      },
      error: async () => {
        await this.errorModalService.showError({
          feature: this.data.feature,
          key: this.data.responseErrorAvatar,
        });
        this.isLoading = false;
      },
    });
  }

  return() {
    const queryParams = {
      flow: window.btoa(this.flow),
    };
    this.modalService.closeActiveModal(true);
    this.toolbarNavigationService.navigateForwardModal(
      UsernameScreenName,
      queryParams,
    );
  }

  formatDate(birthdate: string): string {
    const [month, day, year] = birthdate.split('/');
    return `${year}-${month}-${day}`;
  }

  setProfileScreenConfig() {
    this.screenConfigService.setScreenConfiguration({
      name: ProfileScreenName,
      dataText: ProfileScreenTitleConfig,
      useToolbar: {
        state: true,
        closeAll: true,
      },
      useHeader: true,
      useFooter: true,
      presentation: ScreenPresentation.MODAL,
    });
  }
}
